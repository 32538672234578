<template>
  <v-container fluid class="pt-8 pt-lg-10 mx-0 px-0">
    <!-- <v-btn-toggle
      tile
      color="primary"
      v-model="tabs"
      mandatory
      dense
      class="ms-n3"
      background-color="#E5E5E5"
    >
      <v-btn
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleInward"
        elevation="1"
        class="font-weight-bold text-body-2 text-capitalize"
        >Inward</v-btn
      >
      <v-btn
        color="white"
        elevation="1"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleOutward"
        class="font-weight-bold text-body-2 text-capitalize ms-6"
        >Outward</v-btn
      >
      <v-btn
        elevation="1"
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
        @click="toggleIngredient"
        class="font-weight-bold text-body-2 text-capitalize ms-6"
        >Current</v-btn
      >
    </v-btn-toggle>
    <Inward v-show="showInward" />
    <Outward v-show="showOutward" />
    <IngrediantStock v-show="showIngredient" /> -->
    <v-tabs
      center-active
      v-model="tabs"
      background-color="bg"
      slider-size="44"
      slider-color="secondary"
    >
      <v-tab v-if="hasRole(AdminUserPermissions.STOCK_INWARD_VIEW)"
        class="
          font-weight-medium
          text-caption text-sm-body-2
          black--text
          text-capitalize
          white
          elevation-2
          mb-1
          rounded-lg
        "
        :key="0"
        :class="{'font-weight-bold text_white--text': tabs === 0 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Inward</label
        ></v-tab
      >
      <v-tab v-if="hasRole(AdminUserPermissions.STOCK_OUTWARD_VIEW)"
        class="
          font-weight-medium
          text-caption text-sm-body-2
          black--text
          text-capitalize
          white
          elevation-2
          mb-1
          ms-4
          rounded-lg
        "
        :class="{ 'font-weight-bold text_white--text': tabs === 1 }"
        :key="1"
        ><label style="cursor: pointer" class="custom_slider"
          >Outward</label
        ></v-tab
      >
      <v-tab v-if="hasRole(AdminUserPermissions.STOCK_CURRENT_VIEW)"
        class="
          font-weight-medium
          text-caption text-sm-body-2
          black--text
          text-capitalize
          white
          elevation-2
          mb-1
          ms-4
          rounded-lg
        "
        :class="{ 'font-weight-bold text_white--text': tabs === 2 }"
        :key="2"
        ><label style="cursor: pointer" class="custom_slider"
          >Current</label
        ></v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item v-if="hasRole(AdminUserPermissions.STOCK_INWARD_VIEW)" :key="0">
        <Inward  />
      </v-tab-item>
      <v-tab-item v-if="hasRole(AdminUserPermissions.STOCK_OUTWARD_VIEW)" :key="1">
        <Outward />
      </v-tab-item>
      <v-tab-item v-if="hasRole(AdminUserPermissions.STOCK_CURRENT_VIEW)" :key="2">
        <IngrediantStock />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Inward from "./Inward.vue";
import Outward from "./Outward.vue";
import IngrediantStock from "./IngrediantStock.vue";
export default {
  components: {
    Inward,
    Outward,
    IngrediantStock,
  },
  data: () => ({
    tabs: "",
    showInward: true,
    showOutward: false,
    showIngredient: false,
  }),
  methods: {
    toggleInward() {
      this.showInward = true;
      this.showOutward = false;
      this.showIngredient = false;
    },
    toggleOutward() {
      this.showInward = false;
      this.showOutward = true;
      this.showIngredient = false;
    },
    toggleIngredient() {
      this.showInward = false;
      this.showOutward = false;
      this.showIngredient = true;
    },
  },
  created(){
   
     if (!this.hasRole(this.AdminUserPermissions.STOCK_VIEW)) {
      this.$router.push({
        name: "Error404",
      });}
  }
};
</script>
<style >
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  color: #ffe500 !important;
}
.custom_slider {
  z-index: 1 !important;
}

.v-tabs-slider{
border-radius: 7px;
margin-top: -3px;
}

</style>