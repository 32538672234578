<template>
  <v-container>
    <v-card
      rounded="lg"
      outlined
      max-height="400px"
      max-width="500px"
      class="mx-auto my-16 pa-10 pa-sm-14"
      color="secondary"
      elevation="4"
    >
      <v-layout justify-center>
        Downloading Invoice
      </v-layout>
    </v-card>
  </v-container>
</template>
<script>
import { printInvoice } from "../methods/a4_invoice";
var QRCode = require("qrcode");
import moment from "moment";
export default {
  layout: "empty",
  methods: {},

  data: () => ({
    loading: true,
    invoice: ""
  }),

  created() {
    let id = this.$route.params.id;

    let variables = {
      id
    };
    this.$apollo
      .query({
        query: require("../api/getInvoiceById.graphql"),
        variables
      })
      .then(data => {
        if (data.data) {
          this.invoice = data.data.getInvoiceTransactionById;
          this.loading = false;
          let self = this;
          let qrCodeString = `Supplier Name : ${this.invoice.companyInfo.name}
          \nVAT Number : ${this.invoice.companyInfo.vat}
          \nCustomer Name : ${this.invoice.customerInfo.firstnameEn} ${
            this.invoice.customerInfo.lastnameEn
          }
          \nInvoice Date : ${moment(
            new Date(parseInt(this.invoice.createdAt))
          ).format("DD MMM YYYY")}
          \nCreated Datetime : ${moment(
            new Date(parseInt(this.invoice.createdAt))
          ).format("DD MMM YYYY, h:mm:ss A")}
          \nTotal VAT : ${this.invoice.tax.toFixed(2)}
          \nTotal Amount Due : ${this.invoice.total.toFixed(2)}

          `;
          QRCode.toDataURL(qrCodeString, function(err, url) {
            console.log(err);
            self.invoice.qrCode = url;
            printInvoice(self.invoice, "invoice");
          });
        }
      });
  }
};
</script>
