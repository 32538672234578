<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onEditClick"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 black--text">Edit Choice</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form class="pa-3" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Choice Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    label="Choice Name"
                    v-model="choice.choiceNameEn"
                    :rules="[requiredValidator('choice name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-text-field
                    label="Choice Name Arabic"
                    v-model="choice.choiceNameAr"
                    :rules="[requiredValidator('choice name arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2 class="pe-sm-2">
                  <h4 class="text-body-2 black--text">
                    Available Number Of Days
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    label="Available Number Of Days"
                    @keypress="isNumber"
                    v-model="choice.availableNumOfDays"
                    :rules="[requiredValidator('available number of days')]"
                    type="number"
                    min="0"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 md2>
                  <h4 class="text-body-2 black--text">Available Days</h4>
                </v-flex>
                <v-flex xs12 md8>
                  <v-row>
                    <v-flex xs6 sm4 md3 lg3>
                      <v-checkbox
                        label="All"
                        :disabled="showVariantSection"
                        value="All"
                        @click="toggleDays"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      xs6
                      sm4
                      md3
                      lg3
                      v-for="(day, index) in listOfDays"
                      :key="index"
                    >
                      <v-checkbox
                        :label="day.day"
                        :disabled="showVariantSection"
                        v-model="choice.availableDays"
                        :value="day"
                      ></v-checkbox>
                    </v-flex>
                  </v-row>
                </v-flex>
              </v-layout>

              <v-layout row class="mt-8" justify-end>
                <v-btn
                  class="black--text text-capitalize"
                  color="secondary"
                  :width="$vuetify.breakpoint.smAndUp ? 160 : 130"
                  @click="onShowVariant"
                  v-show="!showVariantSection"
                  >Add Variant</v-btn
                >
              </v-layout>

              <div v-show="showVariantSection">
                <v-divider> </v-divider>

                <v-layout>
                  <v-flex xs8 md10>
                    <h3 class="black--text mt-8">Variants</h3>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      class="
                        white--text
                        text-capitalize
                        mt-5
                        text-caption text-sm-body-2
                      "
                      color="primary"
                      :width="$vuetify.breakpoint.smAndUp ? 160 : 130"
                      @click="onHideVariant"
                      v-show="showVariantSection"
                      >Edit Week Days</v-btn
                    >
                  </v-flex>
                </v-layout>
                <v-form class="pa-3" v-model="frmvarient" ref="frmvarient">
                  <v-layout wrap>
                    <v-flex xs12 sm5 class="text-body-2 black--text">
                      Name English
                      <v-text-field
                        label="Variant Name English"
                        v-model="variantNameEn"
                        :rules="[requiredValidator('variant name english')]"
                        solo
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm5 class="text-body-2 black--text ml-sm-5">
                      Name Arabic
                      <v-text-field
                        label="Variant Name Arabic"
                        v-model="variantNameAr"
                        :rules="[requiredValidator('variant name arabic')]"
                        solo
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-tabs
                    class="mt-4 mt-sm-12 ms-n13 ms-md-0"
                    v-model="tab"
                    center-active
                    slider-color="black"
                  >
                    <v-tab
                      v-for="day in choice.availableDays"
                      :key="day.day"
                      :value="day.day"
                      ><label
                        class="
                          text-caption text-sm-body-2
                          black--text
                          text-capitalize
                        "
                        >{{ day.day }}</label
                      ></v-tab
                    >
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(day, index) in choice.availableDays"
                      :key="index"
                    >
                      <v-card color="pop_bg pt-4">
                        <v-flex
                          xs8
                          sm5
                          class="text-body-2 black--text ms-5"
                          v-for="(item, index) in day.mealCount"
                          :key="item._id"
                        >
                          {{ item.nameEn }}
                          <span v-show="false">{{ index }}</span>
                          <v-row class="mt-3">
                            <v-text-field
                              :label="item.nameEn + '(No.)'"
                              solo
                              type="number"
                              @keypress="isNumber"
                              v-model="item.count"
                              min="0"
                              dense
                            ></v-text-field>

                            <v-btn
                              class="
                                white--text
                                text-capitalize
                                ms-sm-5
                                mb-4
                              "
                              color="primary"
                              :width="$vuetify.breakpoint.smAndUp ? 130 : 130"
                              @click="applyToAll(item)"
                              >Apply to All</v-btn
                            >
                          </v-row>
                        </v-flex>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>

                  <v-divider></v-divider>

                  <v-flex xs12 sm5 class="text-body-2 black--text mt-3">
                    Price
                    <v-text-field
                      label="Price(amt)"
                      v-model="price"
                      @keypress="isFloatNumber"
                      :rules="[requiredValidator('price')]"
                      solo
                      type="number"
                      min="0"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm5 class="text-body-2 black--text mt-3">
                    Strikethrough Price
                    <v-text-field
                      label="Strikethrough Price(amt)"
                      v-model="strikeThroughPrice"
                      @keypress="isFloatNumber"
                      solo
                      :error-messages="strikeThroughPriceError"
                      type="number"
                      min="0"
                      dense
                    ></v-text-field>
                  </v-flex> -->
                  <v-spacer></v-spacer>
                  <v-flex xs8 md2 class="mr-14">
                    <v-btn
                      class="black--text text-capitalize"
                      color="secondary"
                      width="120"
                      @click="addMealChoice"
                      :disabled="!frmvarient"
                      >Add</v-btn
                    >
                  </v-flex>
                </v-form>
                <v-divider class="mt-5"></v-divider>
                <PackageMenuChoicesTable
                  class="mt-2"
                  :allCategories="categoryList"
                  :choices="choice.mealChoice"
                />

                <v-layout row class="mt-8">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    class="black--text text-capitalize"
                    :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                    @click="updateChoice"
                    :disabled="!valid"
                    >Update</v-btn
                  >
                </v-layout>
              </div>
            </v-form>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
              snackText
            }}</v-snackbar>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator, positive } from "../../core/methods/validations";
import PackageMenuChoicesTable from "../components/PackageMenuChoicesTable.vue";

export default {
  props: {
    allCategories: {
      default: []
    },
    choiceInfo: {
      required: true
    }
  },
  components: {
    // "app-add-image": Add0Image,
    PackageMenuChoicesTable
  },
  data() {
    return {
      snack: false,
      tab: "",
      showVariantSection: true,
      snackColor: "",
      snackText: "",
      requiredValidator: requiredValidator,
      positive: positive,
      validMealCount: false,
      valid: false,
      listOfDays: [],
      isDaysToggled: false,
      dialog: false,
      choice: [],
      varientMealChoice: {},
      categoryList: [],
      price: "",
      strikeThroughPrice: "",
      strikeThroughPriceError: null,
      variantNameEn: "",
      variantNameAr: "",
      frmvarient: false
    };
  },

  // watch: {
  //   choiceInfo: {
  //     handler() {
  //       this.choice.availableDays = [];
  //       debugger;
  //       this.choice.availableDayss = new Array();
  //       this.choice.availableDayss.push(this.listOfDays[0]);
  //     },
  //     deep: true,
  //   },
  // },
  // created() {
  //   if (this.allCategories) this.resetVal();
  // },

  created() {
    let sunday = {
      day: "Sunday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let monday = {
      day: "Monday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let tuesday = {
      day: "Tuesday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let wednesday = {
      day: "Wednesday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let thursday = {
      day: "Thursday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let friday = {
      day: "Friday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };
    let saturday = {
      day: "Saturday",
      mealCount: JSON.parse(JSON.stringify(this.allCategories))
    };

    this.listOfDays.push(sunday);
    this.listOfDays.push(monday);
    this.listOfDays.push(tuesday);
    this.listOfDays.push(wednesday);
    this.listOfDays.push(thursday);
    this.listOfDays.push(friday);
    this.listOfDays.push(saturday);
  },
  watch: {
    strikeThroughPrice: {
      handler() {
        this.strikeThroughPriceValidation();
      }
    },
    price: {
      handler() {
        this.strikeThroughPriceValidation();
      }
    }
  },
  methods: {
    strikeThroughPriceValidation() {
      this.frmvarient = false;
      if (this.price && this.strikeThroughPrice) {
        if (parseInt(this.price) >= parseInt(this.strikeThroughPrice)) {
          this.strikeThroughPriceError =
            "Strike through Price should be greater than Price";
        } else {
          this.frmvarient = true;
          this.strikeThroughPriceError = null;
        }
      } else {
        this.strikeThroughPriceError = null;
      }
    },
    applyToAll(category) {
      this.choice.availableDays.forEach(day => {
        day.mealCount.forEach(element => {
          if (element._id == category._id) {
            element.count = category.count;
          }
        });
      });
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Meal count applied to all days.";
    },
    onShowDialog() {
      this.showVariantSection = false;
      this.choice.mealChoice = new Array();
    },
    onShowVariant() {
      if (this.choice.availableDays[0] == null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add available days";
        return;
      } else {
        this.showVariantSection = true;
        this.variantNameAr = "";
        this.variantNameEn = "";
      }
    },
    onHideVariant() {
      this.showVariantSection = false;
      this.choice.mealChoice = new Array();
    },
    addMealChoice() {
      let mealData = JSON.parse(JSON.stringify(this.choice.availableDays));

      this.choice.mealChoice.push({
        price: this.price,
        strikeThroughPrice: this.strikeThroughPrice,
        dayChoices: mealData,
        // extra added the variant name
        variantNameEn: this.variantNameEn,
        variantNameAr: this.variantNameAr
      });

      // this.varientMealChoice.mealCount.forEach((element) => {
      //   element.count = element.count == "" ? 0 : element.count;
      //   element.count = parseFloat(element.count);
      //   let category = this.allCategories.find(
      //     (x) => x._id == element.category
      //   );
      //   element.categoryNameEn = category.nameEn;
      // });
      // this.choice.mealChoice.push(
      //   JSON.parse(JSON.stringify(this.varientMealChoice))
      // );
      this.resetVal();
    },
    onEditClick() {
      this.choice = JSON.parse(JSON.stringify(this.choiceInfo));

      // debugger;
      let availableDayEdit = new Array();
      this.choice.availableDays.forEach(element => {
        let day = this.listOfDays.find(x => x.day == element);
        if (!day) {
          day = this.listOfDays.find(x => x.day == element.day);
        }
        availableDayEdit.push(day);
      });
      this.choice.availableDays = availableDayEdit;
    },
    resetVal() {
      this.varientMealChoice = {
        price: "",
        strikeThroughPrice: "",
        variantNameEn: "",
        variantNameAr: "",
        mealCount: []
      };
      let mealChoiceArr = [];
      this.categoryList = this.allCategories.filter(x => x.active);
      this.categoryList.forEach(category => {
        mealChoiceArr.push({
          category: category._id,
          count: ""
        });
      });
      this.varientMealChoice.mealCount = mealChoiceArr;
      if (this.$refs.frmvarient) {
        this.$refs.frmvarient.reset();
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toggleDays() {
      if (!this.isDaysToggled) {
        this.isDaysToggled = true;
        this.choice.availableDays = this.listOfDays;
      } else {
        this.choice.availableDays = [];
        this.isDaysToggled = false;
      }
    },
    updateChoice() {
      if (this.choice.availableDays[0] == null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add available days";
        return;
      }
      this.choiceInfo.quantity = this.choice.quantity;
      this.choiceInfo.choiceNameEn = this.choice.choiceNameEn;
      this.choiceInfo.choiceNameAr = this.choice.choiceNameAr;
      this.choiceInfo.price = this.choice.price;
      this.choiceInfo.availableDays = this.choice.availableDays;
      this.choiceInfo.availableNumOfDays = this.choice.availableNumOfDays;
      this.choiceInfo.mealChoice = this.choice.mealChoice;
      this.isDaysToggled = false;
      this.close();
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
