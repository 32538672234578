import { jsPDF } from "jspdf";
import moment from "moment";
//import { TIME_ZONE } from "../../utils/utils";

const defaultFontSize = 9;
const lineHeight = Math.floor(defaultFontSize / 2);
let currentY = 2 * lineHeight;
const maxCharPerLine = 40;
const pageWidth = 58;
const xCenter = Math.floor(pageWidth / 2);
const pageHeight = 38;
const offset = 1;

let doc = new jsPDF("l", "mm", [pageHeight, pageWidth], true);

const print_meal_sticker = mealStickers => {
  initialiseDoc();
  if (mealStickers != null) {
    mealStickers.forEach(mealSticker => {
      printTitle(mealSticker);
      printBody(mealSticker);
      gotoNextPage();
    });
  }
  saveDoc();
};
const initialiseDoc = () => {
  doc = new jsPDF("l", "mm", [pageHeight, pageWidth], true);
  doc.setFontSize(defaultFontSize);
  doc.setFont("times", "bold");
};
const printTitle = meal => {
  // console.log("meal", meal);
  const dislikes = meal.dislikes;
  let title = meal.itemName ?? "";
  //   if (dislikes != null) titleWithDislikes = titleWithDislikes + "  " + dislikes;
  // setAdaptiveFontSizeBasedOnNumberOfLines(titleWithDislikes, 2);

  const splitTitle = doc.splitTextToSize(title, maxCharPerLine);
  let requiredNumberOfLines = splitTitle.length;

  // doc.setTextColor("#FFFFFF");
  // doc.setFillColor("#000000");
  // doc.rect(offset, offset, pageWidth - offset * 2, currentY + (requiredNumberOfLines + 1) * lineHeight - lineHeight / 2, "F");
  doc.text(splitTitle, xCenter, currentY, "center");
  gotoNextLine(requiredNumberOfLines);

  const splitDislikes = doc.splitTextToSize(dislikes, maxCharPerLine);
  requiredNumberOfLines = splitDislikes.length;

  doc.text(splitDislikes, xCenter, currentY, "center");
  gotoNextLine(requiredNumberOfLines);
  resetFontSize();
  doc.text(
    // "#" +
    //   (meal.orderNumber ?? "") +
    "  P" + (meal.protein ?? "") + "/C" + (meal.carbs ?? ""),
    xCenter,
    currentY,
    "center"
  );
  gotoNextLine(2);
};
const printBody = meal => {
  doc.rect(offset, offset, pageWidth - offset * 2, pageHeight - offset * 2);
  doc.setTextColor("#000000");
  let xoffset = 30;
  if (meal.categoryName != null) xoffset = Math.ceil(meal.categoryName.length);
  doc.addImage(
    "img/meal.png",
    "png",
    xCenter - xoffset - 4,
    currentY - 3,
    defaultFontSize / 3,
    defaultFontSize / 3
  );
  doc.text(meal.categoryName, xCenter, currentY, "center");
  doc.addImage("img/logo.png", "png", pageWidth - 16, pageHeight - 12, 14, 10);
  gotoNextLine(1);
  // doc.text(meal.customerName, xCenter, currentY, 'center');
  // gotoNextLine(1);
  // doc.setFontSize(defaultFontSize - 1);
  doc.text("" + getDate(meal.prd), xCenter, currentY, "center");
  doc.setFontSize(defaultFontSize);
};
const getDate = date => {
  let converted = moment(date);
  return converted.format("MMM DD YYYY");
};
const resetFontSize = () => {
  doc.setFontSize(defaultFontSize);
};
const gotoNextLine = numberOfLines => {
  if (numberOfLines == null) numberOfLines = 1;
  currentY += numberOfLines * lineHeight;
};
const gotoNextPage = () => {
  doc.addPage();
  currentY = 2 * lineHeight;
};
const saveDoc = () => {
  doc.save("MealStickers.pdf");
};
export { print_meal_sticker };
