  <template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-5" color="pop_bg">
        <v-row>
          <v-card-title class="primary--text text-h6 mt-n2 ms-2"
            >Edit Category</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <h3 class="ms-sm-8 black--text text-body-1 font-weight-bold">
              Enter Meal Category Details
            </h3>
            <v-form
              class="pa-sm-8"
              @submit="updateCategory()"
              v-model="valid"
              ref="form"
            >
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text mt-4 mt-sm-0">
                    Category Name
                  </h5>
                </v-flex>
                <v-flex xs10 sm6 md4>
                  <v-text-field
                    label="Category Name"
                    v-model="category.nameEn"
                    :rules="[requiredValidator('category name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2 class="pe-md-2">
                  <h5 class="text-body-2 black--text">
                    Category Name (Arabic)
                  </h5>
                </v-flex>
                <v-flex xs10 sm6 md4>
                  <v-text-field
                    v-model="category.nameAr"
                    label="Category Name Arabic"
                    :rules="[requiredValidator('category name in arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs6 sm3 md2 class="me-md-3">
                  <h5 class="text-body-2 black--text">Currently Active</h5>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group
                    v-model="category.active"
                    :mandatory="false"
                    row
                  >
                    <v-radio label="Yes" color="secondary" :value="true"></v-radio>
                    <v-radio label="No" color="secondary" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Show Order</h5>
                </v-flex>
                <v-flex xs10 sm6 md4 class="mt-3 mt-md-0">
                  <v-text-field
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                    type="number"
                    v-model="category.showOrder"
                    min="0"
                    label="Show Order"
                    @keypress="isNumber"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-4 mb-n6 mb-sm-n12">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                  color="secondary"
                  class="text-capitalize"
                  @click="updateCategory()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >SAVE</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  props: {
    categoryInfo: {
      required: true,
    },
  },
  created() {
    this.category = JSON.parse(JSON.stringify(this.categoryInfo));
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      category: [],
      show_dialog_box: false,
    };
  },
  methods: {
    onShowDialog() {
      this.category = JSON.parse(JSON.stringify(this.categoryInfo));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateCategory() {
      //   console.log(this.category);
      this.categoryInfo.nameEn = this.category.nameEn;
      this.categoryInfo.nameAr = this.category.nameAr;
      this.categoryInfo.active = this.category.active;
      this.categoryInfo.showOrder = this.category.showOrder;

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updateCategory.graphql"),
          variables: {
            id: this.category._id,
            nameEn: this.category.nameEn,
            nameAr: this.category.nameAr,
            showOrder: this.category.showOrder,
            active: this.category.active,
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.show_dialog_box = false;
          },
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    },
  },
};
</script>