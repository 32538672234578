<template>
  <v-container fluid class="px-0 mx-0">
     <v-layout class="mt-5" justify-end >
      <ExportStockData :headers="headers"/> 
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-10 ms-2"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template #item.nameEn="{ item }">{{ item.nameEn }}</template>
      <template #item.nameAr="{ item }">{{ item.nameAr }}</template>
      <template #item.id="{ item }">{{ item.itemId }}</template>
      <template #item.brandName="{ item }">{{ item.brand }}</template>
      <template #item.unit="{ item }">{{ item.quantityUnit }}</template>

      <template v-slot:item.actions="{ item }">
        <v-row>
          <app-master-update-form
            v-if="hasRole(AdminUserPermissions.MASTER_DATA_EDIT)"
            :ingredientInfo="item"
          ></app-master-update-form>
          <StockMasterDelete
            v-if="hasRole(AdminUserPermissions.MASTER_DATA_DELETE)"
            :ingredient="item"
          />
        </v-row>
        <!-- <v-icon small class="me-2" @click="editItem()">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem()">mdi-delete</v-icon>-->
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import StockMasterUpdateForm from "../components/StockMasterUpdateForm";
import StockMasterDelete from "../components/StockMasterDelete";
import ExportStockData from "../components/export/ExportStockData.vue";
export default {
  components: {
    "app-master-update-form": StockMasterUpdateForm,
    StockMasterDelete,
    ExportStockData 
  },
  props: {
    searchKey: {
      type: String,
    },
  },

  data: () => ({
    dialog: false,
    loading: true,
    options: {
      itemsPerPage: 25,
      page: 1,
    },
    variables: {
      limit: 25,
      skip: 0,
    },
    headers: [
      { text: "Item ID", value: "id", width: 100 },
      { text: "Item Name (English)", value: "nameEn" },
      { text: "Item Name (Arabic)", value: "nameAr" },
      { text: "Brand Name", value: "brandName" },
      { text: "Unit", value: "unit" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    stockList: [],
    totalItemsCount: 25,
  }),
  computed: {
    itemsWithSno() {
      return this.stockList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  apollo: {
    getAllIngredients() {
      return {
        query: require("../api/getAllIngredients.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.stockList = data.getAllIngredients.ingredients;
          this.totalItemsCount = data.getAllIngredients.totalCount;
          // console.log(data.getAllIngredients);
        },
      };
    },
  },
  watch: {
    searchKey: {
      handler() {
        this.$apollo.queries.getAllIngredients.refetch({
          limit: 25,
          skip: 0,
          searchKey: this.searchKey,
        });
      },
    },
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        this.$apollo.queries.getAllIngredients.refetch({
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 500,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage,
        });
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    editItem() {
      this.dialog = true;
    },

    deleteItem() {},

    close() {
      this.dialog = false;
    },
  },
};
</script>

