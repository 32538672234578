<template>
  <div>
    <v-btn
      @click="showDialog"
      color="secondary"
      dark
      class="ma-2 pa-3 text-capitalize "
    >
      Audit
    </v-btn>
    <v-dialog v-model="show_dialog_box1" max-width="900px">
      <v-card class="pa-sm-3">
        <!-- {{ auditList }} -->
        <v-card-title class="justify-center">
          <span class="primary--text text-h6"> Audit trail </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show_dialog_box1 = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="auditList"
              class="elevation-1 mt-4"
              :items-per-page="50"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 50, 100, 500] }"
            >
              <template v-slot:item.updatedBy="{ item }">
                <span v-if="item.updatedBy != null">
                  {{
                    item.updatedBy.firstnameEn[0].toUpperCase() +
                    item.updatedBy.firstnameEn.substring(1)
                  }}
                  {{
                    item.updatedBy.lastnameEn[0].toUpperCase() +
                    item.updatedBy.lastnameEn.substring(1)
                  }}
                </span>
              </template>
              <template v-slot:item.updatedTime="{ item }">
                {{
                  getDate(new Date(parseInt(item.updatedTime)))
                    | moment("MMM DD YYYY hh:mm:A")
                }}
              </template>
              <template v-slot:item.history="{ item }">
                <CustomerAuditDetails :auditLog="item" />
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar> -->
  </div>
</template>
<script>
import CustomerAuditDetails from "./CustomerAuditDetails.vue";
import { tz } from "moment-timezone";
import moment from "moment";
import { TIME_ZONE } from "../../../utils/utils";
export default {
  components: { CustomerAuditDetails },
  props: {
    customerId: {
      required: true,
    },
  },

  methods: {
    getAllAuditReport() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/selectByCustomer.graphql"),
          variables: {
            customer: this.customerId,
          },
        })
        .refetch({ customer: this.customerId })
        .then((data) => {
          this.loading = false;
          this.auditList = data.data.selectByCustomer;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    showDialog() {
      this.getAllAuditReport();
      this.show_dialog_box1 = true;
    },
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },
  },
  data() {
    return {
      valid: false,
      show_dialog_box1: false,
      auditList: [],
      btnloading: false,
      loading: true,
      tz: tz,
      text: "",
      snackbar: false,
      headers: [
        { text: "Action", value: "action", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Updated Time", value: "updatedTime", sortable: false },
        { text: "Updated By", value: "updatedBy", sortable: false },
        { text: "History", value: "history", sortable: false },
        // { text: "", value: "actions", sortable: false },
      ],
    };
  },
};
</script>