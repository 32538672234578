<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1100px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3 pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Edit Purchase </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-5">
            <v-form v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md2 lg1>
                  <h5 class="black--text text-body-2 mt-2">Invoice No.</h5>
                </v-flex>
                <v-flex xs12 sm6 md3 lg2>
                  <v-text-field
                    v-model="purchase.invoiceNumber"
                    label="Invoice No."
                    solo
                    dense
                    :rules="[requiredValidator('invoice number')]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md2 class="ms-md-16">
                  <h5 class="black--text text-body-2 mt-2">Purchase Date</h5>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="ms-lg-n14">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Date"
                        solo
                        :rules="[requiredValidator('purchase date')]"
                        append-icon="fa-calendar-alt black--text"
                        v-bind="attrs"
                        readonly
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="date"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs4 sm4 md2 lg1 class="ms-lg-16">
                  <h5 class="black--text text-body-2 mt-2">Total Price:</h5>
                </v-flex>
                <v-flex xs6 sm6 md2 lg2 class="mt-2">
                  <h4 class="black--text text-body-2 font-weight-bold">
                    {{ totalPurchasePrice() }} SR
                  </h4>
                  <!-- <v-text-field
            v-model="purchasePrice"
            label="Total Price"
            outlined
            disabled
            dense
            type="number"
          ></v-text-field> -->
                </v-flex>
              </v-layout>
              <!-- {{ date }} -->
              <v-form ref="form2" v-model="valid2" class="mt-16 mt-lg-0">
                <v-layout class="mt-3 mb-3" wrap>
                  <v-flex xs12 sm5 md3 lg2>
                    <v-autocomplete
                      label="Item ID"
                      v-model="newPurchaseDetails.ingredient"
                      solo
                      dense
                      attach
                      :rules="[requiredValidator('item id')]"
                      :items="allIngredients"
                      item-text="itemId"
                      item-value="_id"
                      clearable
                      deletable-chips
                      :search-input.sync="ingredientsIdSearchKey"
                      :loading="itemsLoading"
                      return-object
                    >
                      <template slot="no-data">
                        <v-flex xs12 md12 class="mt-2 mb-2">
                          <h5 class="font-weight-medium ml-4">
                            Search ingredient id...
                          </h5>
                        </v-flex>
                      </template>
                      <template slot="item" slot-scope="data">{{
                        data.item.itemId
                      }}</template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm5 md3 lg2 class="ms-sm-4">
                    <v-autocomplete
                      v-model="newPurchaseDetails.ingredient"
                      :items="allIngredients"
                      item-text="nameEn"
                      item-value="_id"
                      solo
                      chips
                      dense
                      attach
                      small-chips
                      :rules="[requiredValidator('item name')]"
                      label="Item Name"
                      clearable
                      deletable-chips
                      :search-input.sync="ingredientsNameSearchKey"
                      :loading="itemsLoading"
                      return-object
                    >
                      <template slot="no-data">
                        <v-flex xs12 md12 class="mt-2 mb-2">
                          <h5 class="font-weight-medium ml-4">
                            Search ingredient name...
                          </h5>
                        </v-flex>
                      </template>
                      <template slot="item" slot-scope="data"
                        >{{ data.item.nameEn }},
                        {{ data.item.nameAr }}</template
                      >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm5 md3 lg2 class="ms-md-4">
                    <v-text-field
                      solo
                      :rules="[requiredValidator('supplier')]"
                      dense
                      v-model="newPurchaseDetails.description"
                      label="Supplier"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3 lg1 class="ms-sm-4 ms-md-0 ms-lg-4">
                    <v-text-field
                      label="Qty"
                      v-model="newPurchaseDetails.quantity"
                      :rules="[requiredValidator('quantity')]"
                      solo
                      dense
                      min="0"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3 lg2 class="ms-md-4">
                    <v-text-field
                      v-model="newPurchaseDetails.unitPrice"
                      label="Unit Price"
                      :rules="[requiredValidator('unit price')]"
                      solo
                      min="0"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md2 class="ms-sm-4 mb-5 mb-sm-0">
                    <v-btn
                      color="primary"
                      class="text-capitalize"
                      width="150"
                      :disabled="!valid2"
                      @click="addDetail()"
                      >Add</v-btn
                    >
                  </v-flex>
                </v-layout>
                <v-snackbar
                  v-model="snack"
                  :timeout="3000"
                  :color="snackColor"
                  >{{ snackText }}</v-snackbar
                >
              </v-form>
              <!-- {{ purchase }} -->
              <PurchaseDetailTable
                :purchaseDetails="purchase.purchaseDetails"
              />
              <v-layout class="text-center" wrap>
                <v-flex xs12 class="mt-4">
                  <v-btn
                    color="secondary"
                    class="text-capitalize black--text"
                    width="200"
                    :disabled="!valid"
                    :loading="btnloading"
                    @click="updatePurchase()"
                    >Save</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-form>

            <v-snackbar
              v-model="snackbar"
              timeout="3000"
              right
              top
              color="success"
              >{{ text }}</v-snackbar
            >
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import PurchaseDetailTable from "../components/PurchaseDetailTable.vue";
import moment from "moment";
export default {
  components: { PurchaseDetailTable },
  props: {
    purchase: {
      default: true,
    },
  },
  created() {
    if (this.purchase.purchaseDate) {
      let date = new Date(parseInt(this.purchase.purchaseDate));
      this.date = moment(date).format("YYYY-MM-DD");
    }
    this.resetNewPurchase();
  },
  data() {
    return {
      show_dialog_box: false,
      menu: false,
      date: "",
      newPurchaseDetails: "",
      valid: true,
      errors: [],
      valid2: true,
      snack: false,
      snackColor: "",
      snackText: "",
      snackbar: false,
      text: "",
      requiredValidator: requiredValidator,
      allIngredients: [],
      awaitingSearchId: false,
      awaitingSearchName: false,
      itemsLoading: false,
      btnloading: false,
      ingredientsNameSearchKey: "",
      ingredientsIdSearchKey: "",
    };
  },
  watch: {
    ingredientsIdSearchKey: function (val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      if (!this.awaitingSearchId) {
        setTimeout(() => {
          this.awaitingSearchId = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchId = true;
    },
    ingredientsNameSearchKey: function (val) {
      if (val == null || val == undefined) return;
      if (val.length < 3) return;
      if (!this.awaitingSearchName) {
        setTimeout(() => {
          this.awaitingSearchName = false;
          this.itemsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              this.allIngredients = data.data.getAllIngredients.ingredients;
              this.itemsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchName = true;
    },
  },
  methods: {
    totalPurchasePrice() {
      let price = 0;
      if (this.purchase.purchaseDetails) {
        this.purchase.purchaseDetails.map(function (elt) {
          price = price + parseInt(elt.totalPrice);
        });
      }
      this.purchase.purchasePrice = price;
      return this.purchase.purchasePrice;
    },
    addDetail() {
      this.newPurchaseDetails.totalPrice = JSON.stringify(
        this.newPurchaseDetails.unitPrice * this.newPurchaseDetails.quantity
      );
      this.purchase.purchaseDetails.push(this.newPurchaseDetails);
      this.purchase.purchasePrice =
        this.purchase.purchasePrice +
        parseInt(this.newPurchaseDetails.totalPrice);
      this.resetNewPurchase();
      this.$refs.form2.reset();
    },
    updatePurchase() {
      if (this.purchase.purchaseDetails[0] == null) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add item details";
        return;
      }

      let purchaseDetails = new Array();
      this.purchase.purchaseDetails.forEach((element) => {
        let purchase = {
          ingredient: element.ingredient._id
            ? element.ingredient._id
            : element.ingredient,
          unitPrice: parseFloat(element.unitPrice),
          quantity: parseFloat(element.quantity) * 1000,
          totalPrice: parseFloat(element.totalPrice),
          discount: parseFloat(element.discount),
          netPrice: parseFloat(element.netPrice),
          description: parseFloat(element.description),
        };

        if (element.ingredient.quantityUnit == "PIECE") {
          purchase.quantity = parseFloat(element.quantity);
        }

        purchaseDetails.push(purchase);
      });
      // let purchaseDetails = JSON.parse(
      //   JSON.stringify(this.purchase.purchaseDetails)
      // );
      // purchaseDetails.map(function (element) {
      //   let ingredientId = element.ingredient._id;
      //   element.ingredient = ingredientId;
      //   if (element.__typename) delete element.__typename;
      // });
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("@/stock/api/updatePurchase.graphql"),
          variables: {
            id: this.purchase._id,
            invoiceNumber: this.purchase.invoiceNumber,
            purchaseDate: this.date,
            purchasePrice: parseFloat(this.purchase.purchasePrice),
            purchaseDetails: purchaseDetails,
          },
          update: () => {
            // this.$refs.form2.reset();
            // this.$refs.form.reset();
            // this.purchaseDetails = [];
            this.$root.$emit("updatePurchase");
            this.btnloading = false;
            this.show_dialog_box = false;
          },
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    resetNewPurchase() {
      this.newPurchaseDetails = {
        ingredient: "",
        unitPrice: "",
        quantity: "",
        totalPrice: "",
        discount: "",
        netPrice: "",
        description: "",
      };
    },
    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    },
  },
};
</script>
