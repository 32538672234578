<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Driver</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form @submit="updateDriver()" v-model="valid" ref="form" class="pa-sm-8 mt-n8">
          <v-card-text>
            <v-container>
              <v-layout wrap class="mt-5 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">First Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="First Name"
                    v-model="driver.user.firstnameEn"
                    :rules="[requiredValidator('First Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Last Name"
                    v-model="driver.user.lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Phone</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    :counter="10"
                    placeholder="Phone"
                    @keypress="isNumber"
                    v-model="driver.user.phone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 9),
                      maxLengthValidator('phone', 10),
                      this.doesPhoneExist != true || 'phone already exists.',
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Password"
                    v-model="password"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs8 md7 offset-md-3>
                  <v-checkbox
                    label="Is Active"
                    v-model="driver.isActive"
                    color="secondary"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">
                    Max. Referral Amount(SR)
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Max. Referral Amount"
                    v-model="maxReferralAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('max. referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n5 mt-n6">
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="updateDriver"
              :disabled="!valid"
              class="text-capitalize"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
          text
        }}</v-snackbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator,
} from "../../core/methods/validations";
export default {
  data() {
    return {
      show1: false,
      requiredValidator: requiredValidator,
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      btnloading: false,
      percentage: 0,
      maxReferralAmount: 0,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      phone: "",
      password: "",
      snackbar: false,
      text: "",
      valid: true,
      doesPhoneExist: false,
      driver: [],
    };
  },
  props: {
    driverInfo: {
      required: true,
    },
  },
  created() {
    this.driver = JSON.parse(JSON.stringify(this.driverInfo));
    if (this.driver.referralPercent) {
      this.percentage = parseFloat(this.driver.referralPercent.percentage);
      this.maxReferralAmount = parseFloat(this.driver.referralPercent.maxReferralAmount);
    }
  },
  methods: {
    onShowDialog() {
      this.driver = JSON.parse(JSON.stringify(this.driverInfo));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateDriver() {
      this.btnloading = true;

      const user = {
        firstnameEn: this.driver.user.firstnameEn,
        lastnameEn: this.driver.user.lastnameEn,
        password: this.password,
        phone: this.driver.user.phone,
      };
      this.driverInfo.firstnameEn = this.driver.firstnameEn;
      this.driverInfo.lastnameEn = this.driver.lastnameEn;
      this.driverInfo.phone = this.driver.phone;
      this.driverInfo.permission = this.driver.permission;
      this.driverInfo.isActive = this.driver.isActive;
      this.driverInfo.referralPercent = {
        percentage: this.percentage,
        maxReferralAmount: this.maxReferralAmount,
      };
      this.$apollo
        .mutate({
          mutation: require("../api/updateDriver.graphql"),
          variables: {
            driverId: this.driver._id,
            userId: this.driver.user._id,
            isActive: this.driver.isActive,
            userInput: user,
            percentage: parseFloat(this.percentage),
            maxReferralAmount: parseFloat(this.maxReferralAmount),
          },
        })
        .then(() => {
          this.text = "Driver details updated";
          this.snackbar = true;
          this.btnloading = false;
          this.$root.$emit("refetchDriver");
          this.dialog = false;
        })
        .catch((error) => {
          this.btnloading = false;
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            }
            console.error(error.graphQLErrors);
          }
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
