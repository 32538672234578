<template>
  <v-container>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="font-weight-bold text-capitalize" color="secondary">
          <v-icon class="me-1" small>mdi-plus</v-icon> Pickup Address
        </v-btn>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Pickup Address</span>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="show_dialog_box = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createFaq()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Location</h4>
                </v-flex>
                <v-flex xs12 sm8 md12 lg8>

                  <google-map :addressCoordinates="addressCoordinates" @selectedAddress="getSelectedAddress" />
                  <VueGooglePlaces :api-key="GOOGLE_MAP_API_KEY" class="subheading" v-model="searchPlace"
                    @placechanged="getAddressData">
                    <v-text-field solo dense @change="changePlace" prepend-inner-icon="mdi-map-marker"
                      placeholder="Location" :rules="[requiredValidator('location')]" v-model="map_location">
                    </v-text-field>
                  </VueGooglePlaces>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn color="secondary" class="mb-2 mb-sm-4 text-capitalize" :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="updateTax" :loading="btnloading" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import { GOOGLE_MAP_API_KEY } from "../../utils/utils";
import { VueGooglePlaces } from "vue-google-places";
import * as VueGoogleMaps from "vue2-google-maps";
import GoogleMap from "../../customer/components/GoogleMap.vue";

import Vue from "vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: "places" // necessary for places input
  }
});
export default {
  components: { VueGooglePlaces, GoogleMap },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations) {
            let pickupAddress = data.getAppConfigurations.pickupAddress;
            if (pickupAddress.length) {
             
              this.map_location = pickupAddress[0].addressLine1;
              this.addressCoordinates = {
                lat: parseFloat(pickupAddress[0].latitude),
                lng: parseFloat(pickupAddress[0].longitude)
              };
            }
          }
        }
      };
    }
  },
  methods: {
    changePlace() { },
    getAddressData(data) {
      this.map_location = data.formatted_address;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.addressCoordinates = {
        lat: data.latitude,
        lng: data.longitude
      };
    },
    getSelectedAddress(selectedAddress) {
      const address = selectedAddress.display_name; //results[0].formatted_address;
      this.latitude = selectedAddress.lat; //results[0].geometry.location.lat;
      this.longitude = selectedAddress.lon; //results[0].geometry.location.lng;
      this.map_location = address;
    },
    updateTax() {
      this.btnloading = true;

      let address = {
        latitude: parseFloat(this.latitude),
        longitude: parseFloat(this.longitude),
        addressLine1: this.map_location
      };
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            pickupAddress: address
          }
        })
        .then(() => {
          this.show_dialog_box = false;
          this.btnloading = false;
          this.snackbar = true;
          this.text = "Address Updated";
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    }
  },
  data: () => ({
    loading: true,
    valid: false,
    requiredValidator,
    snackbar: false,
    text: "",
    btnloading: false,
    show_dialog_box: false,
    taxPercent: "",
    taxPercentService: "",
    supplierName: "",
    vatNumber: "",
    totalItemsCount: 25,
    GOOGLE_MAP_API_KEY: GOOGLE_MAP_API_KEY,
    searchPlace: "",
    map_location: "",
    latitude: 0,
    longitude: 0,
    addressCoordinates: null,
    addressLine1: "",
    addressLine2: ""
  }),

  created() { }
};
</script>
