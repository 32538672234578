<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <h2 class="primary--text text-h5 font-weight-bold">Referrals</h2>

    <v-layout wrap>
      <v-flex xs6 sm4 md3 class="mt-4">
        <v-select
          :items="userRoles"
          item-value="value"
          item-text="item"
          label=""
          v-model="userType"
          @change="getAllUsers()"
          dense
          solo
        ></v-select>
      </v-flex>
      <v-flex sm8 md9 class="mt-4 px-0 mx-0">
        <v-layout justify-end>
          <ExportReferralPage
            class="mt-n3 mr-5"
            :headers="headers"
            :userType="userType"
          />

          <ReferralSettings
            v-if="hasRole(AdminUserPermissions.REFERRAL_SETTINGS)"
          />
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- {{ usersList }} -->
    <v-data-table
      :mobile-breakpoint="0"
      id="myTable"
      :headers="headers"
      :items="usersList"
      :loading="loading"
      item-key="slNo"
      class="elevation-1 mt-4 mt-sm-6"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.slNo="{ item }">{{ item.slNo }}</template>
      <template v-slot:item.name="{ item }">
        <!-- {{ item }} -->
        {{ item.firstnameEn[0].toUpperCase() + item.firstnameEn.substring(1) }}
        {{ item.lastnameEn[0].toUpperCase() + item.lastnameEn.substring(1) }}
      </template>
      <template v-slot:item.referralCode="{ item }">
        {{
          parseInt(item.userId)
            .toString(16)
            .toUpperCase()
        }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row>
          <ReferralTransations class="ms-4" :user="item" isUserTable="true" />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
// import ExportPromocodeReport from "@/reports/components/ExportPromocodeReport.vue";
import ReferralTransations from "@/referral-transactions/components/ReferralTransations.vue";
import ReferralSettings from "@/referral-transactions/components/ReferralSettings";
import ExportReferralPage from "@/referral-transactions/components/ExportReferralPage";

export default {
  components: {
    ReferralTransations,
    ReferralSettings,
    ExportReferralPage
    // ExportPromocodeReport,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.REFERRAL_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.getAllUsers();
  },
  watch: {
    options: {
      handler() {
        this.getAllUsers();
        this.tableIndex =
          this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage;
      }
    },
    userType: {
      handler() {
        this.tableIndex = 0;
        this.options.itemsPerPage = 25;
        this.options.page = 1;
      }
    }
  },
  methods: {
    getAllUsers() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getAllUsers.graphql"),
          variables: {
            // searchKey: "",
            conditions: JSON.stringify(this.userType),
            limit: 25,
            skip: 0
          }
        })
        .refetch({
          conditions: JSON.stringify(this.userType),
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.loading = false;
          if (data.data.getAllUsers) {
            this.usersList = data.data.getAllUsers.users;
            this.totalItemsCount = data.data.getAllUsers.totalCount;
            this.usersList = this.usersList.map((d, index) => ({
              ...d,
              slNo: index + this.tableIndex + 1
            }));
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      usersList: [],
      loading: true,
      totalItemsCount: 25,
      btnloading: true,
      tableIndex: 0,
      userType: {},
      headers: [
        { text: "Sl.No", value: "slNo", width: 50, sortable: false },
        {
          text: "Name",
          value: "name",
          width: 150,
          sortable: false
        },
        { text: "Type", value: "role", width: 150, sortable: false },
        { text: "Phone", value: "phone", width: 150, sortable: false },
        {
          text: "Referral Code",
          value: "referralCode",
          width: 100,
          sortable: false
        },
        { text: "Action", value: "actions", sortable: false, width: 60 }
      ],
      userRoles: [
        {
          item: "All",
          value: {}
        },
        {
          item: "Customer",
          value: { role: "CUSTOMER" }
        },
        // {
        //   item: "Dietitian",
        //   value: { role: "DIETITIAN" },
        // },
        // {
        //   item: "Driver",
        //   value: { role: "DRIVER" },
        // },
        {
          item: "Manager",
          value: { role: "MANAGER" }
        }
        // {
        //   item: "Celebrity",
        //   value: { role: "CELEBRITY" },
        // },
      ],
      options: {
        itemsPerPage: 25,
        page: 1
      },
      variables: {
        skip: 0,
        limit: 25,
        userRole: null
      }
    };
  }
};
</script>
