<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <a href="#" v-on="on"> {{ padLeft(delivery.order_id) }}</a>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text">Delivery Details</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-5" v-if="delivery.customer">
            <v-layout row wrap>
              <v-flex md2>
                Customer
              </v-flex>
              <v-flex md v-if="delivery.customer.user">
                {{ delivery.customer.user.firstnameEn }}
                {{ delivery.customer.user.lastnameEn }}
              </v-flex>
              <v-flex justify-end>
                <v-chip color="error" v-if="delivery.pickupEnabled">
                  Pickup
                </v-chip>

                <v-chip color="orange" v-else>
                  Delivery
                </v-chip>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mt-3">
              <v-flex md2>
                Customer Id
              </v-flex>
              <v-flex md>
                {{ delivery.customer.customerId }}
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mt-3">
              <v-flex md2>
                Phone
              </v-flex>
              <v-flex md v-if="delivery.customer.user">
                {{ delivery.customer.user.phone }}
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-3">
              <v-flex xs8 sm2 md2 lg2>
                Delivery Status
              </v-flex>
              <v-flex xs8 sm3 md2 lg3>
                <v-select
                  :items="deliveryStatuses"
                  v-model="delivery.delivery_status"
                  item-value="value"
                  item-text="text"
                  filled
                  label="Delivery Status"
                  solo
                  dense
                  attach
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-flex md4 xs1 v-for="item in delivery.menu" :key="item._id">
                <v-layout row class="mt-8">
                  <v-flex xs1 md8>
                    <strong>
                      {{ item.category.nameEn }}
                    </strong>
                    <ul>
                      <div v-for="menu in item.menu" :key="menu._id">
                        <li>
                          {{ menu.nameEn }}
                        </li>
                      </div>
                    </ul>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-n8 justify-end">
          <v-btn
            color="secondary"
            class="mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="updateStatus"
            :loading="btnloading"
            :disabled="!delivery.pickupEnabled"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="310">
      <v-card>
        <v-card-title class="headline">Confirm</v-card-title>
        <v-card-text
          >Are you sure want update the delivery status ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success " text @click="confirmPaymentDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="error "
            :loading="btnloading"
            text
            @click="confirmPaid()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    delivery: {
      default: true
    }
  },
  data() {
    return {
      show_dialog_box: false,
      deliveryStatuses: [
        { text: "Pending", value: "PENDING" },
        { text: "Delivered", value: "DELIVERED" },
        { text: "Not Delivered", value: "NOT_DELIVERED" }
      ],
      deliveryStatus: "",
      confirmDialog: false,
      btnloading: false
    };
  },
  methods: {
    updateStatus() {
      this.confirmDialog = true;
    },
    confirmPaid() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateDeliveryStatus.graphql"),
          variables: {
            id: this.delivery._id,
            delivery_status: this.delivery.delivery_status
          }
        })
        .then(() => {
          this.$root.$emit("updateDeliveryReport");

          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          this.$root.$emit("updateDeliveryReport");
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.close();
        });
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    close() {
      this.show_dialog_box = false;
      this.confirmDialog = false;
    }
  }
};
</script>
