<template>
  <v-container fluid class="px-0">
    <v-form @submit="updatePolicy()" v-model="valid" ref="form" class="mt-4">
      <!-- <v-layout justify-end class="mt-sm-n10" wrap>
        <h4 class="primary--text font-weight-light">Require User to Agree</h4>
        <v-switch class="mt-n1 ms-2"></v-switch>
      </v-layout> -->
      <v-layout wrap>
        <v-flex xs12 sm8 md7>
          <v-textarea
            solo
            name="input-7-4"
            label="Terms(English)"
            v-model="text_en"
            :rules="[requiredValidator('Terms')]"
            :disabled="!isEditEn"
          ></v-textarea>
        </v-flex>
        <v-flex class="ms-sm-16 ms-md-14 ms-lg-10 mt-n16 mt-sm-0">
          <v-btn
            color="white"
            :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
            @click="editEn"
            class="mt-14 primary--text text-capitalize font-weight-bold"
          >
            Edit
          </v-btn>
          <v-spacer></v-spacer>
        </v-flex>
      </v-layout>
      <v-layout class="mt-8 mt-sm-0" wrap>
        <v-flex xs12 sm8 md7>
          <v-textarea
            solo
            name="input-7-4"
            label="Terms(Arabic)"
            v-model="text_ar"
            :rules="[requiredValidator('Terms')]"
            :disabled="!isEditAr"
          ></v-textarea>
        </v-flex>
        <v-flex class="ms-sm-16 ms-md-14 ms-lg-10 mt-n16 mt-sm-0">
          <v-btn
            color="white"
            :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
            @click="editAr"
            class="mt-14 primary--text font-weight-bold text-capitalize"
          >
            Edit
          </v-btn>
          <v-spacer></v-spacer>
        </v-flex>
      </v-layout>
      <v-flex>
        <v-row>
          <v-flex xs12 sm12 md10 lg9 class="pe-2 mt-6">
            <v-row justify="end">
              <v-btn
                color="secondary"
                width="150"
                @click="updatePolicy"
                :loading="btnloading"
                :disabled="!valid"
                class="mt-4  font-weight-bold text-capitalize"
              >
                Save
              </v-btn>
            </v-row>
          </v-flex>
        </v-row>
      </v-flex>
    </v-form>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>
<script>
//import UserImage from "../components/UserImage";
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {
    //   "app-user-image": UserImage
  },

  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      dialog: false,
      showDialog: false,
      text_en: "",
      snackbar: false,
      text: "",
      valid: true,
      isEditEn: false,
      isEditAr: false,
      policyId: "",
    };
  },
  apollo: {
    getPolicy() {
      return {
        query: require("../api/getPolicy.graphql"),
        result({ data, loading }) {
          this.text_en = data.getPolicy.text_en;
          this.text_ar = data.getPolicy.text_ar;
          this.policyId = data.getPolicy._id;
          this.loading = loading;
        },
      };
    },
  },
  methods: {
    editEn() {
      this.isEditEn = true;
    },
    editAr() {
      this.isEditAr = true;
    },
    updatePolicy() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updatePolicy.graphql"),
          variables: {
            id: this.policyId,
            text_en: this.text_en,
            text_ar: this.text_ar,
          },
        })
        .then(() => {
          this.text = "Policy updated";
          this.isEditEn = false;
          this.isEditAr = false;
          this.snackbar = true;
          this.btnloading = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.btnloading = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
