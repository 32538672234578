<template>
  <div class=" mx-auto mx-sm-0 ms-sm-3   mt-6">
    <v-sheet
      elevation="1"
      height="64"
      :width="
        $vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.sm ? 600 : 340
      "
    >
      <v-toolbar flat>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          class="text-caption text-sm-body-2"
        >
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text_white--text"
              color="solid"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet
      :height="
        $vuetify.breakpoint.mdAndUp ? 500 : $vuetify.breakpoint.sm ? 400 : 300
      "
      :width="
        $vuetify.breakpoint.mdAndUp ? 800 : $vuetify.breakpoint.sm ? 600 : 340
      "
    >
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      >
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    appoinments: {
      required: true
    }
  },

  data: () => ({
    focus: "",
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party"
    ]
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  watch: {
    appoinments: {
      handler() {
        this.updateRange();
      },
      deep: true
    }
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      const events = [];

      this.appoinments.forEach(element => {
        if (element.appointmentTime) { 
          {
            let appointmentTime = `${moment(new Date(parseInt(element.appointmentDate))).format("YYYY-MM-DD")} ${element.appointmentTime }`; 
            const allDay = this.rnd(0, 3) === 0;
            if (element.customer) {
              events.push({
                name: `${element.customer.user.firstnameEn} ${element.customer.user.lastnameEn}`,
                start: appointmentTime,
                end: appointmentTime,
                color: "green",
                timed: !allDay
              });
            }
          }
        }
      });
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  }
};
</script>
