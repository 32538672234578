<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3 white--text text-capitalize btn_fnt"
        color="secondary" 
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="stockExport"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="stockExportList"
        class="elevation-1 mt-6"
      >
        <template #[`item.nameEn`]="{ item }">{{ item.nameEn }}</template>
        <template #[`item.nameAr`]="{ item }">{{ item.nameAr }}</template>
        <template #[`item.id`]="{ item }">{{ item.itemId }}</template>
        <template #[`item.brandName`]="{ item }">{{ item.brand }}</template>
        <template #[`item.unit`]="{ item }">{{ item.quantityUnit }}</template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    }
  },
  data() {
    return {
      stockExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(x => x.value != "actions");
  },

  methods: {
    getAllIngredients() {
      this.$apollo
        .query({
          query: require("../../api/getAllIngredients.graphql"),
          variables: this.variables
        })
        .then(data => {
          this.stockExportList = data.data.getAllIngredients.ingredients;

          setTimeout(() => {
            this.loading = false;
            Utils.htmlToExcel("stockExport", "Stock Master", false);
          }, 100);
        });
    },

    exportExcel() {
      this.loading = true;
      this.getAllIngredients();
    }
  }
};
</script>
