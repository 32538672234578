<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="secondary"
          class=" text-capitalize font-weight-bold"
        >
          Splash Screen Content
        </v-btn>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text"> Splash Screen Content</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          v-if="splashScreenContent"
          class="pa-sm-8 mt-n6"
          @submit="updateConfigurations()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <!-- {{ splashScreenContent }} -->
              <v-layout wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Page One</h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content"
                    v-model="splashScreenContent.page_one.contentEn"
                    :rules="[requiredValidator('content')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content Arabic"
                    v-model="splashScreenContent.page_one.contentAr"
                    :rules="[requiredValidator('content in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Page Two</h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content"
                    v-model="splashScreenContent.page_two.contentEn"
                    :rules="[requiredValidator('content')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content Arabic"
                    v-model="splashScreenContent.page_two.contentAr"
                    :rules="[requiredValidator('content in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <!-- :rules="[requiredValidator('message ')]" -->
              <!-- :rules="[requiredValidator('message in arabic')]" -->
              <v-layout class="mt-3" wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Page Three</h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md5 class="mt-5 mt-md-0">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content"
                    v-model="splashScreenContent.page_three.contentEn"
                    :rules="[requiredValidator('content')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md5 class="ms-md-16">
                  <v-textarea
                    outlined
                    rows="3"
                    name="input-7-4"
                    label="Content Arabic"
                    v-model="splashScreenContent.page_three.contentAr"
                    :rules="[requiredValidator('content in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n14">
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-center>
              <v-btn
                color="solid"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="updateConfigurations"
                :disabled="!valid"
                class="text_white--text"
                >Save</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="color">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      color="error"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      addedArea: [],
      areaList: [],
      block: "",
      area: "",
      color: "success",
      snackbar: false,
      text: "",
      valid: true,
      splashScreenContent: "",
      whatsappNumber: "",
      whatsappMessage: "",
    };
  },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.splashScreenContent =
            data.getAppConfigurations.splashScreenContent;
          this.whatsappNumber = data.getAppConfigurations.whatsappNumber;
          this.whatsappMessage = data.getAppConfigurations.whatsappMessage;
        },
      };
    },
  },
  methods: {
    updateConfigurations() {
      if (this.splashScreenContent.__typename)
        delete this.splashScreenContent.__typename;
      if (this.splashScreenContent.page_one.__typename)
        delete this.splashScreenContent.page_one.__typename;
      if (this.splashScreenContent.page_two.__typename)
        delete this.splashScreenContent.page_two.__typename;
      if (this.splashScreenContent.page_three.__typename)
        delete this.splashScreenContent.page_three.__typename;
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            splashScreenContent: this.splashScreenContent,
            whatsappNumber: this.getAppConfigurations.whatsappNumber,
            whatsappMessage: this.getAppConfigurations.whatsappMessage,
          },
          update: () => {
            try {
              // this.$root.$emit("updateBlock");
              this.color = "success";
              this.text = "Splash Screen Content Updated";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.btnloading = false;
            this.dialog = false;
          },
        })
        .catch((error) => {
          this.btnloading = false;
          this.text = error;
          this.color = "error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>