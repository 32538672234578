<template>
  <div>
    <UpdateTax v-if="hasRole(AdminUserPermissions.TAX_SETTINGS)"></UpdateTax>
    <AccountTable v-if="hasRole(AdminUserPermissions.PAYMENT_VIEW)" />
  </div>
</template>
<script>
import UpdateTax from "../components/UpdateTax.vue";
import AccountTable from "../components/AccountTable.vue";
export default {
  components: { UpdateTax, AccountTable },
  methods: {},
  data: () => ({
    loading: true,
    totalItemsCount: 25,
    variables: {
      limit: 25,
      skip: 0
    }
  }),

  created() {
    if (!this.hasRole(this.AdminUserPermissions.PAYMENT_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
