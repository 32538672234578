<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-progress-linear v-if="loading" color="primary"></v-progress-linear>
    <div v-if="customer.user">
      <v-row>
        <v-col cols="4" md="1">
          <v-avatar :width="80" :height="80">
            <v-img v-if="customer.user.photo != null" :src="imageUrl + customer.user.photo"></v-img>
            <v-img v-else
              :src="customer.user.gender === 'FEMALE' ? require('../../assets/userFemale.jpg') : require('../../assets/user.jpg')"
              contain :position="!$vuetify.rtl ? 'left' : 'right'" />
          </v-avatar>
        </v-col>
        <v-col cols="6" md="4" align-self="center" class="ms-sm-4">
          <v-row>
            <h2 class="primary--text mb-n2">
              {{ capitalizeFirstLetter(customer.user.firstnameEn) }}
              {{ capitalizeFirstLetter(customer.user.lastnameEn) }}
              <a v-if="customer.user.photo != null" :href="imageUrl + customer.user.photo" download>
                <v-icon>mdi-cloud-download</v-icon>
              </a>
            </h2>

            <h2 class="ml-5">
              {{ customer.user.firstnameAr }} {{ customer.user.lastnameAr }}
            </h2>
          </v-row>
          <v-row class="mt-4">
            <h4>#{{ padLeft(customer.customerId, 4) }}</h4>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" align-self="center" v-if="customer.referredByUser">
          <v-row class="ms-16 ms-sm-0">
            <h3 class="text-body-1 black--text">
              Referenced By:
              {{ capitalizeFirstLetter(customer.referredByUser.firstnameEn) }}
              {{ capitalizeFirstLetter(customer.referredByUser.lastnameEn) }}
            </h3>
          </v-row>
          <v-row class="ms-16 ms-sm-0">
            <h3 class="text-body-1 black--text">
              Referral Code:
              <span v-if="customer.referredByUser.userId">{{
                  parseInt(customer.referredByUser.userId)
                    .toString(16)
                    .toUpperCase()
              }}</span>
            </h3>
          </v-row>
          <v-row></v-row>
        </v-col>
      </v-row>
      <v-row class="mt-8 mt-md-0 pe-1 ms-1">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <SendNotification v-if="hasRole(AdminUserPermissions.PUSH_NOTIFICATION_SEND)" />
        <ResetPassword v-if="hasRole(AdminUserPermissions.CUSTOMER_EDIT)" />
        <AuditTrial :customerId="customer._id" />
        <app-customer-form :customerInfo="customer"></app-customer-form>
        <ReferralTransations :user="customer.user" isUserTable="false" />
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <CustomerAddress :address="customer.addressDetails" />
        </v-col>
        <!-- <v-divider
          vertical
          class="mx-md-4"
          v-show="$vuetify.breakpoint.smAndUp"
        ></v-divider> -->
        <!-- <v-divider class="mx-6" v-show="$vuetify.breakpoint.xs"></v-divider> -->
        <v-col cols="12" sm="6" md="6">
          <PersonalInfo :customer="customer" />
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-layout wrap class="mt-8 mb-6">
        <v-tabs v-model="tabs" :mobile-breakpoint="0" background-color="bg" slider-size="3">
          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Sales Order</v-tab>

          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_DISLIKE_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Dislikes</v-tab>
          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_ALLERGIES_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Allergies</v-tab>

          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_MEAL_RATING_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Meal Rating</v-tab>
          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_DIETITIAN_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Dietitian</v-tab>
          <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_DELIVERY_SCHEDULE_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Delivery Schedule</v-tab>
        </v-tabs>
      </v-layout>
      <v-layout class="mt-n6 mb-6">
        <v-divider></v-divider>
      </v-layout>

      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_VIEW)">
          <!-- {{ customer.user }} -->

          <CustomerSalesorderTable v-if="!subscribedPackageId" :salesOrder="customer.subscribed_packages"
            :customer_id="customer._id" :customer="customer" :loading="loading" />

          <div v-else>
            <PackageDetails v-if="selectedPackage" :selectedPackage="selectedPackage" :customer="customer" />
            <v-layout wrap class=" mb-6">
              <v-tabs v-model="packageTab" :mobile-breakpoint="0" background-color="bg" slider-size="3">
                <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_PAUSE_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Pause Days</v-tab>

                <v-tab v-if="hasRole(AdminUserPermissions.CUSTOMER_MEAL_VIEW)" class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            ">Meals Selection</v-tab>
              </v-tabs>
            </v-layout>
            <v-tabs-items v-model="packageTab" class="mb-6 mb-sm-0" v-if="subscribedPackageId">
              <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_PAUSE_VIEW)">
                <CustomerPausedaysTable v-if="selectedPackage" :selectedPackage="selectedPackage" />
              </v-tab-item>

              <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_MEAL_VIEW)">
                <CustomerMealselectionTable v-if="selectedPackage" :selectedPackage="selectedPackage" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-tab-item>

        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_DISLIKE_VIEW)">
          <!-- {{ customer.dislikes }} -->
          <CustomerDislikeTable :dislikedList="customer.dislikes" />
        </v-tab-item>
        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_ALLERGIES_VIEW)">
          <!-- {{ customer }} -->
          <CustomerAllergiesTable :allergyList="customer.allergies" />
        </v-tab-item>

        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_MEAL_RATING_VIEW)">
          <MealRatingList :subscribedPackages="customer.subscribed_packages" />
        </v-tab-item>
        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_DIETITIAN_VIEW)">
          <CustomerDietitianAppointmentList :customer_id="customer._id" />
        </v-tab-item>
        <v-tab-item v-if="hasRole(AdminUserPermissions.CUSTOMER_DELIVERY_SCHEDULE_VIEW)">
          <DeliveryScheduleTable />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import CustomerUpdateForm from "../components/CustomerUpdateForm";
import ResetPassword from "../components/ResetPassword";
import CustomerAddress from "../components/CustomerAddress.vue";
import CustomerSalesorderTable from "../components/salesOrder/CustomerSalesorderTable.vue";
import PersonalInfo from "../components/CustomerPersonalInfo";
import CustomerPausedaysTable from "../components/pause/CustomerPausedaysTable.vue";
import CustomerMealselectionTable from "../components/mealselection/CustomerMealselectionTable.vue";
import CustomerDislikeTable from "../components/dislike/CustomerDislikeTable.vue";
import CustomerAllergiesTable from "../components/allergies/CustomerAllergiesTable.vue";
import MealRatingList from "../components/meal-rating/MealRatingList.vue";
import CustomerDietitianAppointmentList from "../components/customerDietitian/CustomerDietitianAppointmentList.vue";
import DeliveryScheduleTable from "../components/deliverySchedule/deliveryScheduleTable.vue";
import SendNotification from "../components/SendNotification";
import AuditTrial from "../components/audit-trail/CustomerAuditTrial.vue";
import PackageDetails from "../components/PackageDetails.vue";
import ReferralTransations from "@/referral-transactions/components/ReferralTransations.vue";

export default {
  components: {
    "app-customer-form": CustomerUpdateForm,
    ReferralTransations,
    CustomerAllergiesTable,
    CustomerAddress,
    CustomerPausedaysTable,
    CustomerMealselectionTable,
    PersonalInfo,
    CustomerSalesorderTable,
    CustomerDislikeTable,
    MealRatingList,
    CustomerDietitianAppointmentList,
    DeliveryScheduleTable,
    ResetPassword,
    SendNotification,
    AuditTrial,
    PackageDetails
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CUSTOMER_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
    this.subscribedPackageId = "";
    this.selectedPackage = null;
    if (this.$route.query.packageId) {
      this.subscribedPackageId = this.$route.query.packageId;
      if (this.customer.subscribed_packages)
        this.selectedPackage = this.customer.subscribed_packages.find(
          x => x._id == this.subscribedPackageId
        );
    }
    this.getCustomerDetails();
  },
  mounted() {
    this.$root.$on("updateGetCustomerById", () => {
      this.getCustomerDetails();
      // console.log(this.customer);
    });
  },
  watch: {
    "$route.query.customerId": {
      handler() {
        this.getCustomerDetails();
      }
    },
    "$route.query.packageId": {
      handler(newVal) {
        this.subscribedPackageId = "";
        if (newVal) {
          this.subscribedPackageId = newVal;
          if (this.customer.subscribed_packages)
            this.selectedPackage = this.customer.subscribed_packages.find(
              x => x._id == this.subscribedPackageId
            );
        }
      }
    }
  },
  methods: {
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    getCustomerDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getCustomerById.graphql"),
          variables: {
            id: this.$route.query.customerId
          }
        })
        .refetch({
          variables: {
            id: this.$route.query.customerId
          }
        })
        .then(data => {
          this.loading = false;

          if (data.data.getCustomerById) {
            this.customer = data.data.getCustomerById;
            if (this.subscribedPackageId)
              this.selectedPackage = this.customer.subscribed_packages.find(
                x => x._id == this.subscribedPackageId
              );
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      isDisable: true,
      imageUrl: process.env.VUE_APP_BASE_URL,
      customer: this.$route.query.customerId,
      subscribedPackageId: "",
      snackbar: false,
      text: "",
      tabs: null,
      packageTab: null,
      btnloading: false,
      loading: true,
      selectedPackage: null
    };
  }
};
</script>
