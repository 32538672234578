<template>
  <div>
    <v-data-table :mobile-breakpoint="0" :headers="headers" :items="ingredientList" :items-per-page="5">
      <template v-slot:[`item.ingredient`]="props">
        <v-text-field v-model="props.item.ingredient.nameEn" label="Edit" single-line counter autofocus></v-text-field>
      </template>

      <template v-slot:[`item.quantity`]="props">
        <v-edit-dialog :return-value.sync="props.item.quantity" large @save="save" @cancel="cancel" @open="open"
          @close="close">
          <div>{{ parseFloat(props.item.quantity).toFixed(3) }}</div>
          <template v-slot:input>
            <div class="mt-4 title">Update Quantity</div>
          </template>
          <template v-slot:[`input`]>
            <v-text-field v-model="props.item.quantity" label="Edit" single-line counter autofocus type="number">
            </v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.quantity="{ item }">
        {{ parseFloat(item.quantity).toFixed(3) }}
      </template>
      <template v-slot:item.quantityAfterPreperation="{ item }">
        <p v-if="item.quantityAfterPreperation === null">0</p>
        <p v-else>{{ parseFloat(item.quantityAfterPreperation).toFixed(3) }}</p>
      </template>

      <template v-slot:item.price="{ item }">
        {{
            parseFloat(item.quantity * item.ingredient.quantityPrice).toFixed(3)
        }}
      </template>

      <template v-slot:[`item.ingredient.quantityUnit`]="props">
        <div>{{ props.item.ingredient.quantityUnit }}</div>
      </template>
      <template v-slot:[`item.actions`]="props">
        <v-row>
          <UpdateMealIngredient @update="updateIngredient" :ingredient="props.item" />
          <v-icon color="red" @click="deleteItem(props.item.ingredient._id)">
            mdi-delete
          </v-icon>
        </v-row>
      </template>

      <template v-slot:item.meanInfo="{ item }">
        Fat:{{
            (
              item.ingredient.meanInfo.fat * item.quantityAfterPreperation
            ).toFixed(2)
        }}<br />
        Protein:{{
            (
              item.ingredient.meanInfo.protein * item.quantityAfterPreperation
            ).toFixed(2)
        }}
        <br />
        Carbs :{{
            (
              item.ingredient.meanInfo.carbs * item.quantityAfterPreperation
            ).toFixed(2)
        }}
        <br />
        Calories:{{
            (
              item.ingredient.meanInfo.calories * item.quantityAfterPreperation
            ).toFixed(2)
        }}
      </template>

      <template v-slot:item.dislikable="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.isDislikable" color="primary">mdi-checkbox-marked</v-icon>
        <v-icon v-show="!item.isDislikable" color="grey">mdi-checkbox-blank-outline</v-icon>
      </template>
      <template slot="body.append">
        <tr v-if="totalFat || totalProtein || totalCarbs || totalCalories">
          <th></th>
          <th></th>
          <th>Total</th>
          <th></th>
          <th>
            Total Fat: {{ totalFat.toFixed(2) }} <br />
            Total Protein: {{ totalProtein.toFixed(2) }}<br />
            Total Carbs: {{ totalCarbs.toFixed(2) }} <br />
            Total Calories: {{ totalCalories.toFixed(2) }} <br />
          </th>
          <th>
            Total Nutrients: <br />
            {{ totalNutrientsValue.toFixed(2) }}
          </th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import UpdateMealIngredient from "./UpdateMealIngredient.vue";
export default {
  components: { UpdateMealIngredient },
  props: {
    ingredientList: {
      default: function () {
        return null;
      },
    },
  },
  emits: ['newFat', 'newProtein', 'newCarbs', 'newCalories'],
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      totalFat: "",
      totalProtein: "",
      totalCarbs: "",
      totalCalories: "",
      totalNutrientsValue: "",

      headers: [
        {
          text: "Item ID",
          value: "ingredient.itemId",
        },
        {
          text: "Ingredient",
          value: "ingredient.nameEn",
        },
        { text: "Quantity Before Preparation", value: "quantity" },
        {
          text: "Quantity After Preparation",
          value: "quantityAfterPreperation",
        },
        { text: "Nutrients", value: "meanInfo", width: 200 },
        { text: "Unit", value: "ingredient.quantityUnit", width: 150 },
        { text: "Price", value: "price", sortable: false },
        { text: "Dislikable", value: "dislikable", width: 200 },
        { text: "Actions", value: "actions", sortable: false, width: 70 },
      ],
    };
  },

  methods: {
    updateIngredient() {
      console.log("reached");
      // console.log(data)

      this.totalNutrient();
    },
    totalNutrient() {
      let data = this.ingredientList;
      this.totalFat = data
        .map((x) => x.quantityAfterPreperation * x.ingredient.meanInfo.fat)
        .reduce((total, value) => total + value, 0);
      this.totalProtein = data
        .map((x) => x.quantityAfterPreperation * x.ingredient.meanInfo.protein)
        .reduce((total, value) => total + value, 0);
      this.totalCarbs = data
        .map((x) => x.quantityAfterPreperation * x.ingredient.meanInfo.carbs)
        .reduce((total, value) => total + value, 0);
      this.totalCalories = data
        .map((x) => x.quantityAfterPreperation * x.ingredient.meanInfo.calories)
        .reduce((total, value) => total + value, 0);
      this.totalNutrientsValue =
        this.totalFat +
        this.totalProtein +
        this.totalCarbs +
        this.totalCalories;
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    deleteItem(ingredientId) {
      const index = this.ingredientList.findIndex(
        (item) => item.ingredient._id === ingredientId
      );
      this.ingredientList.splice(index, 1);
      // console.log(index);
    },
    close() {
      console.log("Dialog closed");
    },
  },
  updated() {
    this.totalNutrient();
  },
  mounted() {
    console.log("mounted");
    this.totalNutrient();
  },


  computed: {
    quantityAfter() {
      const data = this.ingredientList.quantityAfterPreperation;
      return data;
    },
  },
  watch: {
    totalFat(newVal) {
      this.$emit("newFat", newVal)
    },
    totalProtein(newVal) {
      this.$emit("newProtein", newVal)
    },
    totalCarbs(newVal) {
      this.$emit("newCarbs", newVal)
    },
    totalCalories(newVal) {
      this.$emit("newCalories", newVal)
    },
    deep: true,
    immediate: true
  }
};
</script>
