var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"white--text text-capitalize",attrs:{"width":"150","color":"secondary","loading":_vm.loading},on:{"click":_vm.exportExcel}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v("fa-file-export")]),_vm._v("Export ")],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false ),expression:"false "}]},[_c('v-data-table',{staticClass:"elevation-1 mt-6",attrs:{"id":"printMe","hide-default-footer":"","headers":_vm.head,"items-per-page":-1,"items":_vm.customerList},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: '' },
            query: { customerId: '' }
          }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")])]}},{key:"item.startDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.subscribed_packages.start_date)),"DD MMM YYYY")))]}},{key:"item.shift",fn:function(ref){
          var item = ref.item;
return [(!item.addressDetails.shift || item.addressDetails.shift == '')?_c('span',[_vm._v("MORNING")]):_c('span',[_vm._v(_vm._s(item.addressDetails.shift))])]}},{key:"item.endDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.subscribed_packages.end_date)),"DD MMM YYYY")))]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }