<template>
  <v-container>
    <v-dialog v-model="statusDialogBox" persistent max-width="700px">
      <!-- <template v-slot:activator=false"{ on }">
      <v-btn v-on="on" small class="mx-2 my-1" outlined color="red"
        >Confirm Deactivation</v-btn
      >
    </template> -->
      <v-card class="pa-6">
        <v-overlay v-if="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-text class="subtitle-1 mb-0 mt-6 text-center"
          >Do you really want to deactivate meal?</v-card-text
        >

        <div v-if="customersUsingMeal[0]">
          <v-card-text class="subtitle-1 mb-0 mt-n6 text-center"
            >Customers using this meal</v-card-text
          >

          <v-data-table
            :mobile-breakpoint="0"
            :headers="headers"
            :items="customersUsingMeal"
            :items-per-page="5"
          >
            <template v-slot:item.customerName="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'CustomerDetails',
                  query: { customerId: item.customer._id }
                }"
                class="mx-auto"
              >
                <span v-if="item.customer">
                  <span v-if="item.customer.user">
                    {{
                      item.customer.user.firstnameEn[0].toUpperCase() +
                        item.customer.user.firstnameEn.substring(1)
                    }}
                    {{
                      item.customer.user.lastnameEn[0].toUpperCase() +
                        item.customer.user.lastnameEn.substring(1)
                    }}
                  </span>
                </span>
              </router-link>
            </template>
            <template v-slot:item.date="{ item }">
              {{
                new Date(parseInt(item.selected_menu.date))
                  | moment("DD MMM YYYY")
              }}</template
            >
            <template v-slot:item.phone="{ item }">
              <span v-if="item.customer">
                <span v-if="item.customer.user">
                  {{ item.customer.user.phone }}
                </span>
              </span>
            </template>
          </v-data-table>
        </div>
        <v-card-actions justify="center">
          <v-radio-group v-model="disableStatus">
            <v-radio
              :disabled="disableSelection"
              label="Disable for future selection only?"
              value="DISABLE"
            ></v-radio>
            <v-radio
              :disabled="disableSelection"
              value="DISABLE_WITH_REMOVE"
              label="Remove all selected meals from customer list for this date?"
            ></v-radio>
          </v-radio-group>
        </v-card-actions>

        <v-spacer></v-spacer>

        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 success" depressed @click="close">No</v-btn>
            <v-btn
              color=" mx-2 primary"
              depressed
              :loading="button_loading"
              @click="
                button_loading = true;
                confirmChange();
              "
              >Yes</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  props: {
    menuItem: {
      required: true
    },
    loading: {
      required: true
    },
    customersUsingMeal: {
      required: true
    },
    statusDialogBox: {
      required: true
    }
  },

  watch: {
    customersUsingMeal: {
      handler() {
        this.disableSelection = true;
        if (this.customersUsingMeal) {
          if (this.customersUsingMeal.length > 0) {
            this.disableSelection = false;
            this.disableStatus = "DISABLE";
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      valid: true,
      disableSelection: true,
      disableStatus: "DISABLE",
      button_loading: false,
      error: null,
      headers: [
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false
        },
        // {
        //   text: "Package",
        //   value: "subscribed_packages.package.titleEn",
        //   width: 150,
        //   sortable: false,
        // },
        {
          text: "Phone",
          value: "phone",
          width: 110,
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          width: 110,
          sortable: false
        }
        // { text: "Shift", value: "shift", width: 100, sortable: false },
        // {
        //   text: "Area",
        //   value: "addressDetails.area.nameEn",
        //   width: 150,
        //   sortable: false,
        // },
        // {
        //   text: "Block",
        //   value: "addressDetails.block.block",
        //   width: 100,
        //   sortable: false,
        // },
      ]
    };
  },
  methods: {
    confirmChange() {
      this.button_loading = false;
      this.error = null;
      this.menuItem.active = false;
      this.$emit(
        "updateStatusDialogBox",
        this.menuItem,
        true,
        this.disableStatus
      );
    },
    close() {
      this.error = null;
      this.$emit(
        "updateStatusDialogBox",
        this.menuItem,
        false,
        this.disableStatus
      );
    }
  }
};
</script>
