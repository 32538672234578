<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout>
      <h2 class="primary--text text-h5 font-weight-bold mt-3">Requests</h2>
    </v-layout>
<v-layout justify-end >
      <ExportDietitianAppointment :headers="headers"/> 
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-8"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :mobile-breakpoint="0"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.name="{ item }">
        <span v-if="item.customer">
        <router-link v-if="item.customer.user"
          :to="{
            name: 'CustomerDetails',
            params: { customerId: item.customer._id },
            query: { customerId: item.customer._id }
          }"
          class="mx-auto"
        >
            {{
              item.customer.user.firstnameEn[0].toUpperCase() +
              item.customer.user.firstnameEn.substring(1)
            }}
            {{
              item.customer.user.lastnameEn[0].toUpperCase() +
              item.customer.user.lastnameEn.substring(1)
            }}
            </router-link>

        </span>
      </template>
      <template v-slot:item.dietitian="{ item }">
        <span v-if="item.dietitian">
          <span v-if="item.dietitian.user">
            {{
              item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
            }}
            {{
              item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
            }}
          </span>
        </span>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.appointmentTime="{ item }">
        <span v-if="item.appointmentDate">
          {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY") }}
          {{ item.appointmentTime }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <UpdateDietitianAppointment
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT)"
            :dietitianappointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
          <deleteDietitianAppointment
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE)"
            :dietitianappointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
        </v-row>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 class="primary--text text-h5 font-weight-bold">Calendar View</h2>
    </v-layout>
    <v-row class="mt-2">
      <BookingCalender v-if="loading == false" :appoinments="customerList" />
    </v-row>
  </v-container>
</template>

<script>
import deleteDietitianAppointment from "./deleteDietitianAppointment.vue";
import UpdateDietitianAppointment from "./updateDietitianAppointment.vue";
import BookingCalender from "./BookingCalender.vue";
import ExportDietitianAppointment from "./ExportCustomerDietitanAppt.vue";
export default {
  components: {
    deleteDietitianAppointment,
    UpdateDietitianAppointment,
    BookingCalender,
    ExportDietitianAppointment 
  },
  mounted() {
    this.$root.$on("updateDietitianAppointment", () => {
      this.loading = true;
      this.$apollo.queries.getAllDietitianAppts.refetch({
        variables: this.variables,
      });
    });
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getAllActiveDietitianAppoinments)
          this.$apollo.queries.getAllActiveDietitianAppoinments.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 500,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage,
          });
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
          width: 50,
        },
        { text: "Customer Name", value: "name", width: 160, sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          width: 100,
          sortable: false,
        },
        {
          text: "Dietitian Name",
          value: "dietitian",
          width: 160,
          sortable: false,
        },
        { text: "Date", value: "createdDate", width: 120, sortable: false },
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          sortable: false,
          width: 160,
        },
        {
          text: "Payment Status",
          value: "payment_status",
          width: 120,
          sortable: false,
        },
        { text: "Action", value: "actions", width: 70, sortable: false },
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      variables: {
        limit: 0,
        skip: 0,
      },
      loading: true,
    };
  },
  apollo: {
    getAllDietitianAppts() {
      return {
        query: require("../../api/getAllActiveDietitianAppoinments.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.customerList = [];
          this.totalItemsCount = 0;
          if (data.getAllActiveDietitianAppoinments) {
            this.customerList =
              data.getAllActiveDietitianAppoinments.dietitianAppointments;
            this.totalItemsCount =
              data.getAllActiveDietitianAppoinments.totalCount;
          }
        },
      };
    },
  },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {},
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIETITIAN_APPOINTMENT_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
};
</script>

<style scoped></style>
