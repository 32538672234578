<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-layout justify-end wrap class="mt-sm-12">
      <v-flex xs12 sm6 lg2 class="mt-8 mt-sm-0">
        <h2 class="primary--text">Customer Log</h2>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-6"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.name="{ item }">
        <span v-if="item.customer != null">
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </span>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.height="{ item }">
        <span v-if="item.height">
          {{ parseFloat(item.height).toFixed(3) }}
        </span>
      </template>
      <template v-slot:item.weight="{ item }">
        <span v-if="item.weight">
          {{ parseFloat(item.weight).toFixed(3) }}
        </span>
      </template>
      <template v-slot:item.fat="{ item }">
        <span v-if="item.fat">
          {{ parseFloat(item.fat).toFixed(3) }}
        </span>
      </template>
      <template v-slot:item.muscle="{ item }">
        <span v-if="item.muscle">
          {{ parseFloat(item.muscle).toFixed(3) }}
        </span>
      </template>
      <template v-slot:item.targetCalories="{ item }">
        <span v-if="item.targetCalories">
          {{ parseFloat(item.targetCalories).toFixed(3) }}
        </span>
      </template>

      <template v-slot:item.appointmentDate="{ item }">
        <span v-if="item.appointmentDate">
          {{
            new Date(parseInt(item.appointmentDate))
              | moment("MMM DD YYYY hh:mm:A")
          }}
        </span>
      </template>

      <template v-slot:item.workoutTime="{ item }">
        <span v-if="item.workoutTime">
          {{ new Date(parseInt(item.workoutTime)) | moment("hh:mm:A") }}
        </span>
      </template>

      <template v-slot:item.sleepingTime="{ item }">
        <span v-if="item.sleepingTime">
          {{ new Date(parseInt(item.sleepingTime)) | moment("hh:mm:A") }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <UpdateAppointment :appointment="item" />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import UpdateAppointment from "../components/updateAppointment.vue";
export default {
  components: {
    UpdateAppointment,
  },
  mounted() {
    this.$root.$on("updateAppointment", () => {
      this.loading = true;
      this.$apollo.queries.getAllClosedAppoinments.refetch({
        variables: this.variables,
      });
    });
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          width: 50,
          sortable: false,
        },
        {
          text: "Appointment Date and Time",
          value: "appointmentDate",
          width: 200,
          sortable: false,
        },
        { text: "Customer Name", value: "name", width: 150 },
        { text: "Contact Number", value: "customer.user.phone", width: 120 },
        { text: "Date", value: "createdDate", width: 120 },
        { text: "Height", value: "height", width: 90 },
        { text: "Weight", value: "weight", width: 90 },
        { text: "Fat(%)", value: "fat", width: 90 },
        { text: "Muscle(%)", value: "muscle", width: 110 },
        { text: "Workout Time", value: "workoutTime", width: 100 },
        { text: "Sleeping Time", value: "sleepingTime" },
        { text: "Target Calories", value: "targetCalories", width: 100 },
        { text: "Remark", value: "remark", width: 300 },
        { text: "Action", value: "actions", sortable: false },
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 0,
      options: {
        itemsPerPage: 0,
        page: 1,
      },
      variables: {
        limit: 0,
        skip: 0,
      },
      loading: true,
    };
  },
  apollo: {
    getAllClosedAppoinments() {
      return {
        query: require("../api/getAllClosedAppoinments.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.customerList = data.getAllClosedAppoinments.appointments;
          this.totalItemsCount = data.getAllClosedAppoinments.totalCount;
        },
      };
    },
  },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {},
};
</script>

<style scoped></style>
