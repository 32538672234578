<template>
  <!-- //ref: https://www.telerik.com/blogs/real-time-geofencing-location-monitoring-using-socketio-vue -->
  <section>
    <v-container>
      <div class="pa-3">
        <v-row align="end">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-if="paths.length <= 0"
            @click="addPath()"
            >Add Area</v-btn
          >
          <v-btn
            depressed
            color="error"
            v-if="paths.length > 0"
            @click="removePath()"
            >Remove Area</v-btn
          >
          <v-btn
            class="ml-2"
            dark
            color="primary"
            depressed
            @click="onLocateClick"
          >
            <v-icon dark>mdi-map-marker</v-icon>
          </v-btn>
        </v-row>
      </div>
      <gmap-map
        :center="center"
        :zoom="12"
        style="width: 100%; height: 380px"
        ref="map"
      >
        <gmap-polygon
          v-if="paths.length > 0"
          :paths="paths"
          :editable="true"
          @paths_changed="updateEdited($event)"
          @rightclick="handleClickForDelete"
          ref="polygon"
        ></gmap-polygon>
      </gmap-map>
    </v-container>
  </section>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCfmLa-BRLoYmupAvEtTopYQPB-VVJeRrk",
    libraries: "geometry" // This is required when working with polygons,
  }
});

export default Vue.extend({
  name: "GeoFencing",
  components: {},
  props: {
    geoFenceCordinates: {
      type: Array,
      required: false,
      twoWay: true
    }
  },
  watch: {
    geoFenceCordinates: {
      handler() {
        this.paths = this.geoFenceCordinates;
      },
      deep: true
    }
  },
  data() {
    return {
      center: { lat: 1.38, lng: 103.8 },
      edited: null,
      paths: [],
      mvcPaths: null,
      errorMessage: null
    };
  },
  mounted() {
    if (this.geoFenceCordinates && this.geoFenceCordinates.length > 0) {
      const coordinate = this.geoFenceCordinates[0][0];
      this.center = {
        lat: coordinate.lat,
        lng: coordinate.lng
      };
    } else {
      this.geolocate();
    }
  },
  created() {
    this.paths = this.geoFenceCordinates;
    this.geolocate();
  },

  computed: {
    polygonPaths: function() {
      if (!this.mvcPaths) return null;

      const paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        const path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          const point = this.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      return paths;
    }
  },
  methods: {
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.markers = [
          {
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          }
        ];
      });
    },

    closeLoop(path) {
      return path.concat(path.slice(0, 1));
    },

    onLocateClick() {
      this.geolocate();
    },

    //this methode is provideing updated coordinates
    getUpdatedpolygonPaths: function() {
      if (!this.mvcPaths) return null;

      const paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        const path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          const point = this.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      return paths;
    },

    updateCenter: function(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
    },
    updateEdited: function(mvcPaths) {
      this.mvcPaths = mvcPaths;
      this.$emit("input", this.getUpdatedpolygonPaths());
      // console.log("update", this.getUpdatedpolygonPaths());
    },
    addPath: function() {
      // obtain the bounds, so we can guess how big the polygon should be
      const bounds = this.$refs.map.$mapObject.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const center = bounds.getCenter();
      // const degree = this.paths.length + 1;
      const degree = 4;
      const f = Math.pow(0.66, degree);

      // Draw a rectangle. Use f to control the size of the rectangle.
      // i.e., every time we add a path, we reduce the size of the rectangle
      const path = [
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat()
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat()
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat()
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat()
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat()
        }
      ];

      this.paths.push(path);
      this.$emit("input", [path]);
    },
    removePath: function() {
      this.paths = [];
      this.$emit("input", []);
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    }
  }
});
</script>
