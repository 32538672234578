<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-layout justify-end wrap class="mt-3">
      <v-flex xs12 sm3 lg2>
        <h2 class="primary--text">Requests</h2>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1 mt-4"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.name="{ item }">
        <span v-if="item.customer != null">
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </span>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.appointmentTime="{ item }">
        <span v-if="item.appointmentTime">
          {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY") }}
          {{ item.appointmentTime }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <updateAppointment
            :appointment="item"
            :limit="variables.limit"
            :skip="variables.skip"
          />
        </v-row>
      </template>
    </v-data-table>

    <v-layout class="mt-10">
      <h2 class="primary--text text-h5 font-weight-bold">Calendar View</h2>
    </v-layout>
    <v-row class="mt-6 ms-1">
      <BookingCalender v-if="loading == false" :appoinments="customerList" />
    </v-row>

    <app-closedAppointments-table
      :customerList="customerList"
      :totalItemsCount="totalItemsCount"
      :loading="loading"
    ></app-closedAppointments-table>
  </v-container>
</template>

<script>
import closedAppointments from "../components/closedAppointments.vue";
import updateAppointment from "../components/updateAppointment.vue";
import BookingCalender from "../components/BookingCalender.vue";
export default {
  components: {
    "app-closedAppointments-table": closedAppointments,
    updateAppointment,
    BookingCalender,
  },
  mounted() {
    this.$root.$on("updateAppointment", () => {
      this.loading = true;
      this.$apollo.queries.getAllActiveAppoinmentsByUserID.refetch({
        variables: this.variables,
      });
    });
  },
  created() {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
        },
        { text: "Customer Name", value: "name", sortable: false },
        {
          text: "Contact Number",
          value: "customer.user.phone",
          sortable: false,
        },
        { text: "Date", value: "createdDate", sortable: false },
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          sortable: false,
        },
        {
          text: "Payment Status",
          value: "payment_status",
          width: 120,
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 0,
        page: 1,
      },
      variables: {
        limit: 0,
        skip: 0,
        id: "",
      },
      loading: true,
    };
  },
  apollo: {
    getAllActiveAppoinmentsByUserID() {
      this.initateUserId();
      return {
        query: require("../api/getAllActiveAppoinmentsByUserID.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          console.log(data.getAllActiveAppoinmentsByUserID.appointments[0]);
          console.log("data.getAllActiveAppoinmentsByUserID");
          this.loading = loading;
          this.customerList = [];
          this.totalItemsCount = 0;
          if (data.getAllActiveAppoinmentsByUserID) {
            this.customerList =
              data.getAllActiveAppoinmentsByUserID.appointments;
            this.totalItemsCount =
              data.getAllActiveAppoinmentsByUserID.totalCount;
          }
        },
      };
    },
  },
  computed: {
    itemsWithSno() {
      if (this.customerList == null) return [];
      return this.customerList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  methods: {
    initateUserId() {
      let user = JSON.parse(localStorage.DONE_USER);
      this.variables.id = user._id;
      // console.log(this.variables);
    },
  },
};
</script>

<style scoped></style>
