<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          class=" text-capitalize"
          v-on="on"
          height="40px"
          color="secondary"
        >
          <v-icon class=" me-1" small>mdi-plus</v-icon> Add Customer
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="justify-center">
          <!-- <span class="primary--text" v-show="isDisable">Customer Details</span> -->
          <span class="primary--text">Add Customer</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-6"></v-divider>
        <!-- {{ addressDetails }} -->
        <v-card-text>
          <v-container class="mt-8">
            <v-form @submit="createCustomer()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n4 mt-sm-2">
                  <h4 class="text-body-2 black--text">First Name(English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    :rules="[requiredValidator('first name')]"
                    solo
                    dense
                    v-model="firstnameEn"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Last Name (English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    :rules="[requiredValidator('last name')]"
                    solo
                    dense
                    v-model="lastnameEn"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">First Name(Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    :rules="[requiredValidator('first name(arabic)')]"
                    solo
                    dense
                    v-model="firstnameAr"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Last Name (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    solo
                    :rules="[requiredValidator('last name(arabic)')]"
                    dense
                    v-model="lastnameAr"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Contact Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    class="inputPhone"
                    solo
                    dense
                    :counter="10"
                    type="number"
                    v-model="phone"
                    @keypress="isNumber"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 10),
                      maxLengthValidator('phone', 10),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Email</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field
                    solo
                    dense
                    v-model="email"
                    @click="doesEmailExist = false"
                    :rules="[
                      this.doesEmailExist != true || 'email already exists.'
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    solo
                    :rules="[
                      requiredValidator('password'),
                      minLengthValidator('password', 8)
                    ]"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    dense
                    v-model="password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Gender</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="genderList"
                    :rules="[requiredValidator('gender')]"
                    v-model="gender"
                    item-text="text"
                    item-value="value"
                    dense
                    @change="genderChange()"
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Martial Status</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="martialStatusList"
                    v-model="martialStatus"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
                <v-flex
                  v-if="gender == 'FEMALE'"
                  xs12
                  sm4
                  md3
                  lg2
                  ms-lg-6
                  class="mt-n2 mt-sm-2"
                >
                  <h4 class="text-body-2 black--text">Pregnant</h4>
                </v-flex>
                <v-flex v-if="gender == 'FEMALE'" xs12 sm8 md9 lg4>
                  <v-select
                    :items="pregnantList"
                    :rules="[requiredValidator('Pregnant')]"
                    v-model="pregnant"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Date of birth</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        :value="formatDate"
                        dense
                        :rules="[requiredValidator('date of birth')]"
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :max="new Date() | moment('YYYY-MM-DD')"
                      v-model="dateofbirth"
                      @input="menu2 = false"
                      color="secondary"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">How Found About App</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="UserSourceList"
                    :rules="[requiredValidator('user source')]"
                    v-model="UserSource"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs0 sm4 md3 lg7> </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-if="UserSource === 'OTHER'"
                    solo
                    dense
                    v-model="text"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Contact Method</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    :items="methodList"
                    solo
                    dense
                    attach
                    item-text="method"
                    item-value="_id"
                    v-model="method"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ms-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Customer Goal</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select
                    :items="customerGoalList"
                    :rules="[requiredValidator('customer goal')]"
                    v-model="customerGoal"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field solo dense v-model="referredBy"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Comment</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-textarea
                    rows="1"
                    v-model="customerComment"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2"> </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-flex xs12 sm8 md4 lg10 class="pe-lg-12">
                    <v-checkbox
                      label="Enable Pickup"
                      v-model="pickupEnabled"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    xs12
                    sm8
                    md6
                    lg10
                    class="pe-lg-12"
                    v-if="pickupEnabled"
                  >
                    <span class="error--text">
                      No driver will be assigned while generating report, check
                      delivery schedule (priority is for delivery schedule)
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
              <!-- {{ new Date() | moment("YYYY-MM-DD") }} -->
              <v-layout wrap>
                <v-flex xs12 class="mt-n2 mt-sm-2 mb-2 mb-sm-8">
                  <h4 class="text-h6 black--text font-weight-bold">Address</h4>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Location</h4>
                </v-flex>
                <v-flex xs12 sm8 md12 lg8>
                  <google-map
                    :addressCoordinates="addressCoordinates"
                    @selectedAddress="getSelectedAddress"
                  />
                  <VueGooglePlaces
                    :api-key="GOOGLE_MAP_API_KEY"
                    class="subheading"
                    v-model="searchPlace"
                    @placechanged="getAddressData"
                  >
                    <v-text-field
                      solo
                      dense
                      @change="changePlace"
                      prepend-inner-icon="mdi-map-marker"
                      placeholder="Location"
                      :rules="[requiredValidator('location')]"
                      v-model="address.map_location"
                    >
                    </v-text-field>
                  </VueGooglePlaces>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Area</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    type="area"
                    id="area"
                    :items="allAreas"
                    :rules="noDelivery"
                    v-model="address.area"
                    item-text="nameEn"
                    item-value="_id"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Address Type</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-select
                    :items="addressTypeList"
                    :rules="[requiredValidator('address type')]"
                    v-model="address.addressType"
                    dense
                    attach
                    solo
                  >
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Shift</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select
                    v-model="address.shift"
                    :items="allShifts"
                    solo
                    dense
                    attach
                  ></v-select>
                  <!-- <v-text-field
                    v-model="address.shift"
                    solo
                    :rules="[requiredValidator('shift')]"
                    dense
                  ></v-text-field> -->
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">District</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.district"
                    solo
                    :rules="[requiredValidator('district')]"
                    dense
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Street</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    v-model="address.street"
                    maxlength="30"
                    solo
                    :rules="[requiredValidator('street')]"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">House Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.house_number"
                    maxlength="8"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">Floor</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field
                    v-model="address.floor"
                    maxlength="5"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Flat</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4 class="ms-lg-6">
                  <v-text-field
                    v-model="address.flat"
                    solo
                    maxlength="5"
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2>
                  <h4 class="black--text text-body-2">Comments</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-textarea
                    rows="1"
                    v-model="address.comments"
                    solo
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <!-- <v-text-field
                    label="Map Location"
                    v-model="address.map_location"
                    outlined
                    dense
                  ></v-text-field> -->

              <!-- {{ address }} -->
              <v-layout justify-end class="mt-5">
                <v-btn
                  color="secondary"
                  class="primary--text text-capitalize"
                  :width="$vuetify.breakpoint.xs ? 90 : 120"
                  @click="createCustomer()"
                  :loading="btnloading"
                  :disabled="!valid"
                  >Save</v-btn
                >
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  requiredValidator,
  emailValidator,
  minLengthValidator,
  maxLengthValidator
} from "../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT, GOOGLE_MAP_API_KEY } from "../../utils/utils";
import { VueGooglePlaces } from "vue-google-places";
import * as VueGoogleMaps from "vue2-google-maps";
import GoogleMap from "./GoogleMap.vue";
//import { ValidationProvider } from "vee-validate";
import Vue from "vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: "places" // necessary for places input
  }
});

export default {
  components: { VueGooglePlaces, GoogleMap },
  data() {
    return {
      noDelivery: [v => !!v || "No delivery in this place"],
      show1: false,
      UserSource: "",
      GOOGLE_MAP_API_KEY: GOOGLE_MAP_API_KEY,
      searchPlace: "",
      // isDisable: true,
      genderList: [
        { text: "Male", value: "MALE" },
        { text: "Female", value: "FEMALE" }
      ],
      UserSourceList: [
        { text: "Internet Search Engine", value: "INTERNET" },
        { text: "Social Media", value: "SOCIAL_MEDIA" },
        { text: "Through a friend or referal", value: "REFERRAL" },
        { text: "Other", value: "OTHER" }
      ],
      methodList: [],
      method: "",
      shift: "",
      allShifts: ["MORNING", "EVENING"],
      customerGoalList: [
        { text: "Lose Weight", value: "LOSE_WEIGHT" },
        { text: "Maintain Weight", value: "MAINTAIN_WEIGHT" },
        { text: "Gain Weight", value: "GAIN_WEIGHT" }
      ],
      customerComment: "",
      customerGoal: "",
      referredBy: "",
      menu2: false,
      firstnameEn: "",
      lastnameEn: "",
      firstnameAr: "",
      lastnameAr: "",
      phone: "",
      email: "",
      dateofbirth: "",
      gender: "",
      howFoundAboutApp: "",
      password: "",
      martialStatus: "",
      pregnant: "",
      addressTypeList: ["Office", "Home", "Others"],
      martialStatusList: [
        { text: "Married", value: "MARRIED" },
        { text: "Unmarried", value: "UNMARRIED" },
        { text: "Divorced", value: "DIVORCED" }
      ],
      pregnantList: [
        "No",
        "First Trimester",
        "Second Trimester",
        "Third Trimester"
      ],

      address: {
        area: null,
        shift: "",
        street: "",
        house_number: "",
        map_location: "",
        district: "",
        floor: "",
        flat: "",
        latitude: null,
        longitude: null
      },
      blocksByArea: [],
      allAreas: [],
      areasLoading: false,
      areaSearchKey: "",
      awaitingAreasSearch: false,
      valid: true,
      minLengthValidator: minLengthValidator,
      emailValidator: emailValidator,
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      errors: [],
      show_dialog_box: false,
      snackbar: false,
      snackbarColor: "",
      doesPhoneExist: false,
      doesEmailExist: false,
      text: "",
      btnloading: false,
      blockEnable: false,
      latitude: 0,
      longitude: 0,
      addressCoordinates: null,
      addressLine1: "",
      pickupEnabled: false
    };
  },
  created() {
    this.getAllContactMethods();
  },

  computed: {
    formatDate() {
      return this.dateofbirth
        ? moment(this.dateofbirth).format(DATE_FORMAT)
        : "";
    }
  },
  methods: {
    changePlace() {
      this.allAreas = [];
      this.address.area = null;
    },
    getSelectedAddress(selectedAddress) {
      this.allAreas = [];
      const address = selectedAddress.display_name; //results[0].formatted_address;
      this.latitude = selectedAddress.lat; //results[0].geometry.location.lat;
      this.longitude = selectedAddress.lon; //results[0].geometry.location.lng;
      this.address.map_location = address;

      this.getArea();
    },
    getAddressData(data) {
      this.address.map_location = data.formatted_address;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.addressCoordinates = {
        lat: data.latitude,
        lng: data.longitude
      };
      this.getArea();
    },

    genderChange() {
      if (this.gender == "FEMALE") {
        console.log(this.gender);
        let data = { text: "Widowed", value: "WIDOWED" };
        this.martialStatusList.push(data);
      } else {
        this.martialStatusList = this.martialStatusList.slice(0, 3);
        console.log(this.martialStatusList);
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close() {
      // this.isDisable = true;
      this.isNewAddress = false;
      this.show_dialog_box = false;
    },
    getArea() {
      if (this.addressCoordinates.lat) {
        this.$apollo
          .query({
            query: require("@/customer/api/queries/isLocationInServingArea.graphql"),
            variables: {
              latitude: parseFloat(this.latitude),
              longitude: parseFloat(this.longitude)
            }
          })
          .then(data => {
            if (data.data.isLocationInsideServingArea) {
              this.address.area = data.data.isLocationInsideServingArea._id;
              this.allAreas.push(data.data.isLocationInsideServingArea);
            }
          })
          .catch(e => {
            console.log(e.graphQLErrors);
            this.address.area = "";
          });
      }
    },

    getAllContactMethods() {
      this.$apollo
        .query({
          query: require("@/contact-method/api/getAllContactMethods.graphql"),
          variables: {
            limit: 25,
            skip: 0
          }
        })
        .then(data => {
          this.methodList = data.data.getAllContactMethods.methods;
        });
    },
    getBlocksByArea(areaId) {
      if (areaId) {
        this.$apollo
          .query({
            query: require("@/customer/api/getBlocksByArea.graphql"),
            variables: {
              id: areaId
            }
          })
          .then(data => {
            this.blocksByArea = data.data.getBlocksByArea.blocks;
            this.blockEnable = true;
          });
      }
    },
    getShiftsByArea() {
      if (this.address.area && this.address.block) {
        this.$apollo
          .query({
            query: require("@/customer/api/getShiftsByArea.graphql"),
            variables: {
              area: this.address.area,
              block: this.address.block
            }
          })
          .then(data => {
            this.allShifts = data.data.getShiftsByArea.shifts;
          });
      } else {
        this.allShifts = [];
      }
    },
    createCustomer() {
      // console.log(this.customer);
      this.btnloading = true;
      let userInput = {
        firstnameEn: this.firstnameEn,
        lastnameEn: this.lastnameEn,
        firstnameAr: this.firstnameAr,
        lastnameAr: this.lastnameAr,
        email: this.email,
        phone: this.phone,
        gender: this.gender,
        dob: this.dateofbirth,
        password: this.password
      };

      let deliveryInput = [];
      let howFoundAboutApp = {
        userSource: this.UserSource,
        text: this.text
      };
      let martialStatus = this.martialStatus;
      let pregnantStatus = null;
      if (this.gender == "FEMALE") {
        pregnantStatus = this.pregnant;
      }
      this.address.latitude = parseFloat(this.latitude);
      this.address.longitude = parseFloat(this.longitude);
      deliveryInput.push(this.address);
      this.$apollo
        .mutate({
          mutation: require("../api/adminCustomerSignUp.graphql"),
          variables: {
            input: userInput,
            deliveryInput: deliveryInput,
            howFoundAboutApp: howFoundAboutApp,
            contactMethod: this.method,
            referredBy: this.referredBy,
            comment: this.customerComment,
            customerGoal: this.customerGoal,
            martialStatus,
            pregnantStatus,
            pickupEnabled: this.pickupEnabled
          }
        })
        .then(() => {
          this.$root.$emit("updateCustomers");
          this.close();
          this.$refs.form.reset();
          this.text = "Customer Added";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.btnloading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: EMAIL_ALREADY_EXIST") {
              this.doesEmailExist = true;
            } else if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
              this.btnloading = false;
            }
            window.scrollTo(0, 0);
            this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });

            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.isNewAddress = false;
        });
    }
  }
};
</script>

<style>
.inputPhone input[type=""] {
  -moz-appearance: textfield;
}

.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
