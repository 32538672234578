import { jsPDF } from "jspdf";
import moment from "moment";
import { TIME_ZONE, ARABIC_BOLD_FONT } from "../../utils/utils";

const defaultFontSize = 8;
const padding = 2;
const lineHeight = Math.floor(defaultFontSize / 2);
let currentY = lineHeight * 2;
const maxCharPerLine = 40;
const pageWidth = 100;
const pageHeight = 80;

let doc = new jsPDF("l", "mm", [pageWidth, pageHeight], true);
const print_delivery_sticker = (orders, date) => {
  initialiseDoc();
  if (orders != null) {
    orders.forEach(order => {
      printOrderHeader(order, date);
      printCustomerDetails(order);
      printSelectedMenu(order.menu);
      printFooter();
      gotoNextPage();
    });
  }
  saveDoc(date);
};

const initialiseDoc = () => {
  doc = new jsPDF("l", "mm", [pageWidth, pageHeight], true);
  doc.setFontSize(defaultFontSize);

  doc.addFileToVFS("Amiri Bold-normal.ttf", ARABIC_BOLD_FONT);
  doc.addFont("Amiri Bold-normal.ttf", "Amiri Bold", "normal");
};
const printOrderHeader = (order, date) => {
  const startXCol2 = Math.floor(pageWidth / 4);
  const startXCol3 = Math.floor((pageWidth * 2) / 3);

  //boxes
  doc.rect(padding, padding, pageWidth - 2 * padding, pageHeight - 4 * padding);
  doc.rect(padding, padding, pageWidth - 2 * padding, currentY);
  doc.rect(startXCol2 - 2, padding, startXCol3 - startXCol2, currentY);

  // first column
  doc.setFont("times", "bold");
  doc.setFontSize(defaultFontSize + 3);
  doc.text(
    "Q#" + padLeft(order.order_id.toString(), 4),
    padding + 2,
    currentY - 2
  );
  doc.setFont("times", "bold");
  doc.setFontSize(defaultFontSize - 1);
  doc.text(date, padding + 2, currentY + 1);
  doc.setFontSize(defaultFontSize);

  // second column

  doc.setFontSize(defaultFontSize - 2);
  doc.setFont("times", "bold");
  doc.text(order.package_code, startXCol2, currentY - 3);
  doc.text("START ", startXCol2, currentY - 1);
  doc.text("END", startXCol2, currentY + 1);

  doc.setFont("times", "bold");
  doc.text(
    getDate(order.subscribedPackage.start_date),
    startXCol2 + 8,
    currentY - 1
  );
  doc.text(
    getDate(order.subscribedPackage.end_date),
    startXCol2 + 8,
    currentY + 1
  );
  doc.setFontSize(defaultFontSize);

  // third column
  doc.rect(startXCol2 - 2, padding, startXCol3 - startXCol2, currentY);
  doc.text(order.shift + " Delivery", startXCol3, currentY - 2);
  doc.setFont("times", "bold");
  doc.text("Zone :", startXCol3, currentY + 1);
  doc.text(order.zone.zoneName ?? "", startXCol3 + 8, currentY + 1);
  doc.setFont("times", "bold");

  gotoNextLine();
};

const printCustomerDetails = order => {
  const startXcol2 = Math.floor((pageWidth + padding) / 2);
  //boxes
  doc.rect(
    padding,
    padding + currentY - lineHeight,
    pageWidth - 2 * padding,
    3 * lineHeight
  );
  doc.rect(
    padding,
    padding + currentY - lineHeight,
    startXcol2 - 2,
    3 * lineHeight
  );

  // col 1
  let thisCustomer = order.customer;
  let userName = "";
  if (thisCustomer.user != null) {
    const fName = thisCustomer.user.firstnameEn ?? "";
    const lName = thisCustomer.user.lastnameEn ?? "";
    userName = fName + " " + lName;
  }
  doc.setFont("times", "bold");
  doc.setFont("Amiri Bold", "normal");
  doc.text(capitalizeFirstLetter(userName), padding + 2, currentY + 2);
  gotoNextLine();
  doc.setFont("times", "bold");
  doc.text("ID : " + thisCustomer.customerId ?? "", padding + 2, currentY + 1);
  gotoNextLine();
  doc.text("Mobile : " + thisCustomer.user.phone ?? "", padding + 2, currentY);
  gotoPreviousLine(2);

  // col 2
  doc.setFont("times", "bold");
  const address = order.address;
  let addressline1 = address.area.nameEn ?? "";
  if (address.block && address.block.block != null) {
    addressline1 = addressline1 + "(" + address.block.block + ")";
  }
  doc.text(addressline1, startXcol2 + 2, currentY + 2);
  gotoNextLine();
  let addressline2 = "";

  if (address.street != null) {
    if (address.street.length > 0) {
      addressline2 += "St:" + address.street + " ";
    }
  }
  doc.setFont("Amiri Bold", "normal");
  doc.text(addressline2, startXcol2 + 2, currentY + 1);
  gotoNextLine();
  let addressline3 = "";
  if (address.house_number != null) {
    if (address.house_number.length > 0) {
      addressline3 += "H : " + address.house_number + " ";
    }
  }
  if (address.building_number != null) {
    if (address.building_number.length > 0) {
      addressline3 += "Floor :" + address.building_number + " ";
    }
  }
  if (address.apartment_number != null) {
    if (address.apartment_number.length > 0) {
      addressline3 += "Apartment :" + address.apartment_number + " ";
    }
  }
  doc.text(addressline3, startXcol2 + 2, currentY);
  doc.setFont("times", "bold");
  gotoNextLine();
};

const getDate = date => {
  let converted = moment(date).tz(TIME_ZONE);
  return converted.format("MMM DD YYYY");
};
const printSelectedMenu = menu => {
  if (menu == null) return;
  incrementYByPixels(2);
  let lastPrintedYinCol1 = currentY;
  let lastPrintedYinCol2 = currentY;

  const filteredMenu = getFilteredMenu(menu);
  for (var i = 0; i < filteredMenu.length; i++) {
    let xStart = padding + 6;

    if (i % 2 == 0) {
      // column1StartYRef = maxPrintedYinBothColumns; //currentY;
      currentY = lastPrintedYinCol1;
      //column2
    } else {
      currentY = lastPrintedYinCol2; //column1StartYRef;
      xStart = Math.floor(pageWidth / 2) + padding + 3;
    }
    doc.setFont("times", "bold");
    doc.text(filteredMenu[i].category?.nameEn ?? "-", xStart - 4, currentY);
    doc.setFont("times", "bold");

    const menuItem = filteredMenu[i];
    if (menuItem != null) {
      menuItem.menu.forEach(aMenuItem => {
        const menuNameWithDislikes = getNameWithDislikes(aMenuItem);
        const splitMenuItems = doc.splitTextToSize(
          menuNameWithDislikes,
          maxCharPerLine
        );
        //  const splitMenuItems = doc.splitTextToSize(aMenuItem.nameEn ?? "", maxCharPerLine);
        const requiredNumberOfLines = splitMenuItems.length;
        gotoNextLine();

        const isAutoSelected = aMenuItem.isAutoSelected;
        doc.addImage(
          isAutoSelected
            ? "img/forefinger-pointing-right.png"
            : "img/forefinger-pointing-up.png",
          "png",
          xStart - 4,
          currentY - 2,
          defaultFontSize / 3,
          defaultFontSize / 3
        );

        doc.text(splitMenuItems, xStart, currentY);
        gotoNextLine(requiredNumberOfLines - 1);
      });
    }
    //column1
    if (i % 2 == 0) {
      lastPrintedYinCol1 = currentY + 2 * lineHeight;
      //column2
    } else {
      lastPrintedYinCol2 = currentY + 2 * lineHeight;
    }
  }
};
const printFooter = () => {
  doc.text("www.planetzero.com", padding + 4, pageHeight - padding - 1);
  const xValue = Math.floor(pageWidth / 2) - 10;
  doc.addImage("img/globe.png", "png", padding, pageHeight - 5, 3, 3);
  doc.addImage("img/call.png", "png", xValue, pageHeight - 5, 3, 3);
  doc.text("+911 11111111", xValue + 4, pageHeight - padding - 1);

  doc.addImage(
    "img/logo.png",
    "png",
    pageWidth - 17,
    pageHeight - 16.5,
    14,
    10
  );
};
const getFilteredMenu = menu => {
  let filteredMenu = [];
  menu.forEach(m => {
    if (m.menu != null) {
      if (m.menu.length > 0) filteredMenu.push(m);
    }
  });
  return filteredMenu;
};

const getNameWithDislikes = item => {
  let dislikedIngrediants = item.dislikedIngrediants.map(d => "No " + d.nameEn);
  let nameWithDislike = item.nameEn.trim();
  if (dislikedIngrediants.length > 0)
    nameWithDislike =
      nameWithDislike + " (" + dislikedIngrediants.join(", ") + ")";
  return nameWithDislike;
};

const padLeft = (number, length) => {
  var stringValue = "" + number;
  while (stringValue.length < length) {
    stringValue = "0" + stringValue;
  }
  return stringValue;
};
const gotoNextLine = numberOfLines => {
  if (numberOfLines == null) numberOfLines = 1;
  currentY += numberOfLines * lineHeight;
};
const gotoPreviousLine = numberOfLines => {
  if (numberOfLines == null) numberOfLines = 1;
  currentY -= numberOfLines * lineHeight;
};
const incrementYByPixels = pixels => {
  currentY += pixels;
  if (currentY > pageHeight) gotoNextPage();
};
const gotoNextPage = () => {
  doc.addPage();
  currentY = lineHeight * 2;
};
const saveDoc = date => {
  doc.save(date + " Delivery Sticker.pdf");
};
const capitalizeFirstLetter = string => {
  // return string.charAt(0).toUpperCase() + string.slice(1);
  return string.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export { print_delivery_sticker };
