<template>
  <div>
    <v-row>
      <v-col cols="12" style="content-visibility: auto">
        <gmap-map
          :center="center"
          :zoom="15"
          style="width: 100%; height:   200px"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
            @dragend="updateCoordinates"
          ></gmap-marker>
        </gmap-map>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import axios from "axios";
export default {
  name: "GoogleMap",
  props: {
    addressCoordinates: {
      type: Object,
      required: false,
      twoWay: true,
    },
  },
  components: {},
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      places: [],

      coordinates: [],
      currentPlace: null,
    };
  },

  mounted() {
    this.geolocate();
  },

  watch: {
    markers: {
      handler: function (markers) {
        console.log(markers);
      },
      deep: true,
    },
    addressCoordinates: {
      handler: function (newVal) {
        this.addressCoordinates = newVal;

        this.updateMarkerPosition();
      },
      deep: true,
    },
  },

  methods: {
    updateMarkerPosition() {
      if (this.addressCoordinates) {
        const markers = [
          {
            position: {
              lat: this.addressCoordinates.lat,
              lng: this.addressCoordinates.lng,
            },
          },
        ];

        const center = {
          lat: this.addressCoordinates.lat,
          lng: this.addressCoordinates.lng,
        };
        this.markers = markers;
        this.places = this.currentPlace;
        this.center = center;
        this.currentPlace = null;
      }
    },
    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.reverseGeocode(this.coordinates);
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers = marker;
        this.places = this.currentPlace;
        this.center = marker;
        this.currentPlace = null;
      }
    },

    reverseGeocode(coordinates) {
      axios
        .get(
          //`https://maps.google.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${StaticVariables.GOOGLE_MAP_API_KEY}`
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinates.lat}&lon=${coordinates.lng}`
        )
        .then((response) => {
          // debugger;
         // console.log(response.data);
          this.$emit("selectedAddress", response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.markers = [
          {
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
        ];
      });
    },
  },
};
</script>