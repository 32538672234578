
<template>
  <v-container v-if="address">
    <v-row v-if="type == 'area'">
      <span v-if="address.area">{{ address.area.nameEn }}</span>
    </v-row>
    <v-row v-if="type == 'district'">
      <span v-if="address.district">{{ address.district}}</span>
      <span v-if="address.street">, {{ address.street }}</span>
    </v-row>
    <v-row v-if="type == 'building'">
      <span v-if="address.house_number">{{ address.house_number }}</span>
      <span v-if="address.floor">, {{ address.floor }}</span>
      <span v-if="address.flat">, {{ address.flat }}</span>
    </v-row>
    <v-row></v-row>
    <v-row></v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    address: {
      required: true
    },
    type: {
      required: true
    }
  },

  data() {
    return {};
  }
};
</script>