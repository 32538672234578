<template>
  <v-container fluid class="px-0">
    <v-row>
      <h3 class="primary--text mt-6 ms-3 mb-4">Ingredients</h3>
    </v-row>

    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="8" sm="6" lg="4">
        <v-text-field
          placeholder="Name.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              color="black"
              v-if="search.length > 0"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon color="black" v-if="search.length == 0" @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <app-master-form
          v-if="hasRole(AdminUserPermissions.MASTER_DATA_ADD)"
        ></app-master-form>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        lg="6"
        class="mt-n6 mt-sm-0 mb-4 mb-sm-0 text-lg-end pe-lg-5"
      >
        <AuditTrialLog class="mt-sm-n6 ms-md-n16" auditType="INGREDIENTS_LOG" />
      </v-col>
    </v-row>
    <app-master-table
      class="mt-n14 ms-n2"
      :searchKey="searchKey"
    ></app-master-table>
  </v-container>
</template>

<script>
import StockMasterTable from "../components/StockMasterTable.vue";
import StockMasterForm from "../components/StockMasterForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";

export default {
  components: {
    "app-master-table": StockMasterTable,
    "app-master-form": StockMasterForm,
    AuditTrialLog
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.searchKey = "";
    },
    runSearch() {
      this.searchKey = this.search;
    }
  },
  data: () => ({
    search: "",
    searchKey: ""
  }),
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MASTER_DATA_VIEW)) {
      if (
        this.hasRole(this.AdminUserPermissions.STOCK_VIEW) ||
        this.AdminUserPermissions.PURCHASE_VIEW ||
        this.AdminUserPermissions.ADJUST_STOCK_VIEW
      ) {
        this.$router.push("/stock");
      } else
        this.$router.push({
          name: "Error404"
        });
    }
  }
};
</script>
