<template>
  <v-btn
    :disabled="disabled"
    @click="printPDF"
    color="secondary"
    class="primary--text text-capitalize"
    :small="$vuetify.breakpoint.xs"
    :width="$vuetify.breakpoint.xs ? 100 : 150"
  >
    <v-icon
      :small="$vuetify.breakpoint.xs"
      :size="$vuetify.breakpoint.smAndUp ? 20 : ''"
      class="me-1"
      >mdi-file-document</v-icon
    >Download
  </v-btn>
</template>
<script>
import { jsPDF } from "jspdf";
import moment from "moment";
import { tz } from "moment-timezone";
import { print_delivery_sticker } from "../methods/print_delivery_sticker";
export default {
  props: {
    pendingList: { require: true },
    disabled: { require: true },
    date: { require: true },
  },
  methods: {
    getDate(date) {
      let converted = moment(date).tz("Asia/Bahrain");
      return converted;
    },
    printPDF() {
      print_delivery_sticker(this.pendingList, this.date);
    },
    print() {
      let doc = new jsPDF("l", "mm", [100, 80]);

      doc.html(this.$refs.printHtml, {
        callback: function (doc) {
          var pageCount = doc.internal.getNumberOfPages();
          doc.deletePage(pageCount);
          doc.save("Delivery");
        },
        x: 1,
        y: 1,
      });
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
  },
  data() {
    return {
      tz: tz,
    };
  },
};
</script>
<style scoped>
.box {
  width: 26mm;
  font-size: 2.5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  margin: auto;
  margin-bottom: 2px;
  height: 20.64mm;
}
.border {
  margin: 0 auto;
  height: 19.4mm;
  width: 25.5mm;
  font-size: 2.5px;
  border-color: rgba(2, 2, 2, 0.664);
  border-style: solid;
  border-width: 0.3pt;
}
.main-table {
  padding: 1px;
}
table {
  font-size: 2.5px;
}
.footer-table {
  margin-bottom: 0.5px;
}
.bold {
  font-weight: 700;
}
.light {
  font-weight: 500;
}
.footer {
  padding: 0 1px;
  display: inline-flex;
}
.itemslist {
  padding: 0 1px;
  font-size: 2.9px;
  width: 50%;
  display: inline-block;
  vertical-align: text-top;
}
.noitemslist {
  display: none;
}
.text-align-just {
  text-align-last: justify;
}
.text-align-right {
  text-align-last: right;
}
.img-mar {
  margin-top: 1.1px;
}
hr {
  border-top: 0.01px solid black;
}
.menu-item {
  display: flow-root;
}
.module {
  width: 100%;
  overflow: hidden;
}
.module span {
  margin: 0;
}

.line-clamp {
  display: -webkit-box;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.pad-left-1 {
  padding-left: 1px;
}
.menu-divin {
  display: inline-flex;
}
</style>