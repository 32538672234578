<template>
  <div>
    <v-dialog
      v-model="show_dialog_box"
      max-width="1100px"
      @click:outside="outSide"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog" class="ms-2"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="ma-0 pa-3 pa-sm-6" color="pop_bg">
        <v-card-text>
          <v-row>
            <h3 class="primary--text text-h5 text-sm-h6 ms-2 mb-4">
              Edit Meal Details
            </h3>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider class="mt-2"></v-divider>
          <v-form
            class="pa-sm-8"
            @submit="updateMenuItem()"
            v-model="valid"
            ref="form"
          >
            <v-layout wrap class="mt-4 mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text">Meal Name</h5>
              </v-flex>
              <v-flex xs12 sm8 md4>
                <v-text-field
                  label="Meal Name"
                  v-model="menuItem.nameEn"
                  :rules="[requiredValidator('meal name')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                <v-text-field
                  v-model="menuItem.nameAr"
                  label="Meal Name Arabic"
                  :rules="[requiredValidator('meal name arabic')]"
                  solo
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-2">
              <v-flex xs12 sm3 md2>
                <h4 class="text-body-2 black--text">Description</h4>
              </v-flex>
              <v-flex xs12 sm8 md4 class="">
                <v-textarea
                  solo
                  name="input-7-4"
                  value
                  label="Description"
                  v-model="menuItem.descriptionEn"
                  :rules="[requiredValidator('description ')]"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                <v-textarea
                  label="Description Arabic"
                  solo
                  name="input-7-4"
                  value
                  v-model="menuItem.descriptionAr"
                  :rules="[requiredValidator('description in arabic')]"
                ></v-textarea>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text">Image</h5>
              </v-flex>
              <v-flex xs12 sm3 md1 class="">
                <v-img :width="150" class="ms-md-4" :src="getImage()" />
              </v-flex>
              <v-flex xs12 sm5 md6 class="ms-md-6">
                <croppa
                  class="ml-2 mt-2"
                  v-model="myCroppa"
                  :width="170"
                  :height="100"
                  canvas-color="black"
                  :placeholder="'Update image'"
                  :placeholder-font-size="16"
                  placeholder-color="yellow"
                  :accept="'image/*'"
                  :file-size-limit="0"
                  :quality="5"
                  :zoom-speed="3"
                  :disabled="false"
                  :disable-drag-and-drop="false"
                  :disable-click-to-choose="false"
                  :disable-drag-to-move="false"
                  :disable-scroll-to-zoom="false"
                  :disable-rotation="false"
                  :prevent-white-space="false"
                  :reverse-scroll-to-zoom="false"
                  :show-remove-button="true"
                  :remove-button-color="'red'"
                  :remove-button-size="20"
                >
                  /></croppa
                >
                <v-layout>
                  <span style="color: red" class="text-center">
                    ****Use mouse scroll wheel to crop image****
                  </span>
                </v-layout>
                <v-flex xs8 md4 v-if="isChangable" class="ms-md-16 mt-5">
                  <h5 class="font-weight-bold text-body-2 black--text">
                    Select Image
                  </h5>
                </v-flex>
                <input
                  v-if="isChangable"
                  type="file"
                  accept="image/*"
                  @change="onFileSelected($event)"
                />
              </v-flex>
              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
                snackText
              }}</v-snackbar>
            </v-layout>
            <v-layout wrap class="mt-4">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-md-5">Category</h5>
              </v-flex>
              <v-flex xs12 sm9 md10>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <!-- {{menuItem.category}} -->
                    <v-select
                      v-model="menuItem.category"
                      :items="activeCategories"
                      :rules="[requiredValidator('category')]"
                      item-value="_id"
                      item-text="nameEn"
                      dense
                      solo
                      attach
                      chips
                      multiple
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-sm-0">
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-2">Meals Tag</h5>
              </v-flex>
              <v-flex sm6 md4>
                <v-select
                  v-model="menuItem.mealsTag"
                  :items="mealsTagList"
                  item-value="_id"
                  item-text="name"
                  dense
                  solo
                  attach
                  v-on:input="limiter"
                  chips
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>

            <!-- <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">Currently Active</h5>
              </v-flex>
              <v-flex xs12 sm6>
                <v-radio-group v-model="menuItem.active" :mandatory="false" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout> -->

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">Diet Plan</h5>
              </v-flex>
              <v-flex xs12 sm9 md10>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="item in allDietPlans"
                    :key="item._id"
                  >
                    <v-checkbox
                      :label="item.nameEn"
                      v-model="dietPlans"
                      :value="item._id"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-5">
              <v-flex xs12 sm3 md2 class="pe-sm-4 pe-md-2">
                <h5 class="text-body-2 black--text">Meal Info(100-100)</h5>
              </v-flex>
              <v-flex xs12 sm9 md10 class="mt-4 mt-sm-0">
                <v-layout wrap>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text">
                    Protien(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3>
                    <v-text-field
                      label="Protein(gm)"
                      type="number"
                      @keypress="isNumber"
                      min="0"
                      v-model="menuItem.meanInfo.protein"
                      :rules="[requiredValidator('protein')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text ms-md-8">
                    Carbs(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3 class="ms-md-10">
                    <v-text-field
                      type="number"
                      min="0"
                      @keypress="isNumber"
                      label="Carbs(gm)"
                      v-model="menuItem.meanInfo.carbs"
                      :rules="[requiredValidator('carbs')]"
                      solo
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text">
                    Calories(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3>
                    <v-text-field
                      label="Calories(gm)"
                      v-model="menuItem.meanInfo.calories"
                      min="0"
                      solo
                      dense
                      :rules="[requiredValidator('calories')]"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs4 sm4 md2 class="text-body-2 black--text ms-md-8">
                    Fat(gm)
                  </v-flex>
                  <v-flex xs8 sm6 md3 class="ms-md-10">
                    <v-text-field
                      label="Fat(gm)"
                      v-model="menuItem.meanInfo.fat"
                      solo
                      :rules="[requiredValidator('fat')]"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout> </v-flex
            ></v-layout>
            <div class="text-end">
              <v-btn
                @click="onSynchronize"
                color="secondary"
                class=" text-capitalize"
                >Sync</v-btn
              >
            </div>
            <!-- <v-layout wrap>
                <v-flex xs12 md2>
                  <h5 class="font-weight-medium">Kitchen Type</h5>
                </v-flex>
                <v-flex xs8 md4 class="mt-5 mt-md-0">
                  <v-select
                    :items="kitchenTypesItems"
                    v-model="kitchenType"
                    label="Select Kitchen Type"
                    :rules="[requiredValidator('kitchen type')]"
                    dense
                    outlined
                  ></v-select>
                </v-flex>
              </v-layout> -->
            <v-layout wrap class="mt-8 mt-sm-0">
              <v-flex xs4 sm3 md2>
                <h5 class="text-body-2 black--text">Meal Code</h5>
              </v-flex>
              <v-flex xs8 sm4 md3>
                <v-text-field
                  v-model="itemCode"
                  :rules="[requiredValidator('meal code')]"
                  solo
                  :disabled="true"
                  dense
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs8 md3 class="ms-md-10">
                  <v-text-field
                    label="Price"
                    v-model="menuItem.price"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>-->
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text">Available Week Days</h5>
              </v-flex>
              <v-flex xs12 sm8 md10>
                <v-select
                  v-model="menuItem.availableWeekdays"
                  :items="listOfDays"
                  attach
                  chips
                  dense
                  solo
                  :rules="[requiredValidator('available Week days')]"
                  multiple
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm3 md2>
                <h5 class="text-body-2 black--text mt-6">
                  Show Ingredients In App
                </h5>
              </v-flex>
              <v-flex xs12 sm3>
                <v-switch
                  v-model="menuItem.showIngredientsInApp"
                  class="mt-5"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-system-bar height="1"></v-system-bar>
            <h3 class="font-weight-bold text-body-1 black--text mt-5">
              Ingredients Required
            </h3>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">{{
              snackText
            }}</v-snackbar>
            <v-form
              v-model="isValidIngredient"
              @submit="AddIngredient()"
              ref="ingredient"
            >
              <v-layout class="mt-5 mt-md-10" wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Ingredient</h5>
                </v-flex>
                <v-flex xs12 sm8 md6 lg4>
                  <v-autocomplete
                    v-model="newIngredient"
                    :items="ingredientSearcList"
                    item-text="nameEn"
                    item-value="_id"
                    :rules="[requiredValidator('ingredient')]"
                    dense
                    attach
                    solo
                    chips
                    small-chips
                    label="Ingredient Name"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by name...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data"
                      >{{ data.item.nameEn }}, {{ data.item.nameAr }}</template
                    >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Ingredient ID</h5>
                </v-flex>
                <v-flex xs12 sm8 md6 lg4>
                  <v-autocomplete
                    label="Ingredient ID"
                    v-model="newIngredient"
                    solo
                    dense
                    attach
                    :rules="[requiredValidator('ingredient id')]"
                    :items="ingredientSearcList"
                    item-text="itemId"
                    item-value="_id"
                    clearable
                    deletable-chips
                    :search-input.sync="ingredientsIdSearchKey"
                    :loading="ingredientsLoading"
                    return-object
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search ingredient by id...
                        </h5>
                      </v-flex>
                    </template>
                    <template slot="item" slot-scope="data">{{
                      data.item.itemId
                    }}</template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Dislikable</h5>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group v-model="isDislikable" :mandatory="false" row>
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Quantity</h5>
                </v-flex>
                <v-flex xs10 sm4 md2 class="mt-5 mt-md-0">
                  <v-text-field
                    type="number"
                    label="Quantity"
                    min="0"
                    @keypress="isFloatNumber"
                    v-model="newQuantity"
                    :rules="[requiredValidator('quantity')]"
                    solo
                    dense
                  >
                    <template v-if="newIngredient" v-slot:append>
                      <h4 class="mt-1 grey--text">
                        {{ newIngredient.quantityUnit }}
                      </h4>
                    </template></v-text-field
                  >
                </v-flex>
                <v-spacer></v-spacer>
                <!-- <v-flex xs12 sm4 md3 class="mt-sm-6 mt-md-0">
                  <v-btn
                    :width="
                      $vuetify.breakpoint.mdAndUp
                        ? 200
                        : $vuetify.breakpoint.sm
                        ? 120
                        : 90
                    "
                    color="primary"
                    class="secondary--text text-capitalize"
                    @click="AddIngredient()"
                    :disabled="!isValidIngredient"
                    >Add</v-btn
                  >
                </v-flex> -->
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Quantity After</h5>
                </v-flex>
                <v-flex xs10 sm4 md2 class="mt-5 mt-md-0">
                  <v-text-field
                    type="number"
                    label="Quantity"
                    min="0"
                    @keypress="isFloatNumber"
                    v-model="quantityAfter"
                    :rules="[requiredValidator('quantity')]"
                    solo
                    dense
                  >
                    <template v-if="newIngredient" v-slot:append>
                      <h4 class="mt-1 grey--text">
                        {{ newIngredient.quantityUnit }}
                      </h4>
                    </template></v-text-field
                  >
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm4 md3 class="mt-sm-6 mt-md-0">
                  <v-btn
                    :width="
                      $vuetify.breakpoint.mdAndUp
                        ? 200
                        : $vuetify.breakpoint.sm
                        ? 120
                        : 90
                    "
                    color="secondary"
                    class=" text-capitalize"
                    @click="AddIngredient()"
                    :disabled="!isValidIngredient"
                    >Add</v-btn
                  >
                </v-flex>
              </v-layout>
              <v-layout justify-sm-end wrap class="mt-3 mt-sm-0">
                <v-flex xs4 sm2 md1>
                  <h5 class="font-weight-medium error--text">Total Price:</h5>
                </v-flex>
                <v-flex xs6 sm2 md2>
                  <h4 class="font-weight-bolder error--text">
                    {{ ingredientPrice() }} SR
                  </h4>
                </v-flex>
              </v-layout>
            </v-form>
            <v-layout wrap class="mt-5">
              <v-flex xs12 md2>
                <h5 class="text-body-2 black--text">Ingredients Added</h5>
              </v-flex>
              <v-flex xs12 md10 class="mt-5 mt-md-0">
                <app-ingredient-table
                  :ingredientList="menuItem.ingredients"
                  @newFat="fatChange"
                  @newProtein="proteinChange"
                  @newCarbs="carbsChange"
                  @newCalories="caloriesChange"
                ></app-ingredient-table>
              </v-flex>
            </v-layout>
            <v-layout row justify-end class="mt-8 mb-n5">
              <v-btn
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                color="secondary"
                class=" text-capitalize"
                @click="updateMenuItem()"
                :loading="btnloading"
                :disabled="!valid"
                >Update</v-btn
              >
            </v-layout>
          </v-form>

          <v-snackbar
            v-model="snackbar"
            timeout="3000"
            right
            top
            color="success"
            >{{ text }}</v-snackbar
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import AddImage from "../../core/components/AddImage";
import IngredientTable from "../components/IngredientTable";
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {
    // "app-add-image": AddImage,
    "app-ingredient-table": IngredientTable,
  },
  props: {
    menuItemInfo: {
      required: true,
    },
  },

  data() {
    return {
      fatCount: 0,
      proteinCount: 0,
      carbsCount: 0,
      caloriesCount: 0,
      fatValue: "",
      proteinValue: "",
      carbsValue: "",
      caloriesValue: "",
      snack: false,
      myCroppa: null,
      snackColor: "",
      image: "",
      snackText: "",
      isValidIngredient: true,
      requiredValidator: requiredValidator,
      isChangable: false,
      imageUrl: process.env.VUE_APP_BASE_URL,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      menuItem: [],
      errors: [],
      snackbar: false,
      text: "",
      newIngredient: "",
      newQuantity: 0,
      quantityAfter: 0,
      isDislikable: false,
      ingredientsLoading: false,
      ingredientsSearchKey: "",
      ingredientsIdSearchKey: "",
      ingredientSearcList: [],
      awaitingSearch: false,
      awaitingSearchId: false,
      itemCode: "",
      variables: {
        limit: 25,
        skip: 0,
      },
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      categoriesList: [],
      activeCategories: [],
      mealsTagList: [],
      mealsTag: "",
      kitchenTypesItems: ["Type1", "Type2", "Type3", "Type4"],
      kitchenType: [],
      allDietPlans: [],
      dietPlans: [],
    };
  },
  watch: {
    ingredientsIdSearchKey: function (val) {
      if (val == null || val == undefined) return;
      if (val.length < 1) return;
      if (!this.awaitingSearchId) {
        setTimeout(() => {
          this.awaitingSearchId = false;
          this.ingredientsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllMealIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              this.ingredientSearcList =
                data.data.getAllMealIngredients.ingredients;
              this.ingredientsLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearchId = true;
    },
    ingredientsSearchKey: function (val) {
      if (val == null || val == undefined) return;
      if (val.length <= 0) return;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.awaitingSearch = false;
          //search
          this.ingredientsLoading = true;
          this.$apollo
            .query({
              query: require("@/stock/api/getAllMealIngredients.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              // console.log(data);
              this.ingredientSearcList =
                data.data.getAllMealIngredients.ingredients;
              this.ingredientsLoading = false;
            });
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  apollo: {
    getAllDietPlans() {
      return {
        query: require("../diet-plan/api/getAllDietPlans.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.allDietPlans = data.getAllDietPlans.filter((x) => x.active);
        },
      };
    },
    getAllCategories() {
      return {
        query: require("../api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.categoriesList = data.getAllCategories.category;
          this.categoriesList.forEach((element) => {
            if (element.active == true) {
              this.activeCategories.push(element);
            }
          });
          // console.log(data.getAllIngredients);
        },
      };
    },
    getAllMealsTags() {
      return {
        query: require("../api/getAllMealsTags.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.mealsTagList = data.getAllMealsTags;
          // console.log(data.getAllIngredients);
        },
      };
    },
  },
  created() {
    this.menuItem = JSON.parse(JSON.stringify(this.menuItemInfo));

    if (this.menuItem.dietPlans) {
      this.dietPlans = this.menuItem.dietPlans.map((x) => x._id);
    }
    this.itemCode = `M${this.menuItem.itemCode}`;
  },
  methods: {
    onSynchronize() {
      this.menuItem.meanInfo.fat = this.fatValue;
      this.menuItem.meanInfo.protein = this.proteinValue;
      this.menuItem.meanInfo.carbs = this.carbsValue;
      this.menuItem.meanInfo.calories = this.caloriesValue;
    },
    outSide() {
      console.log("outside");
      this.fatCount = 0;
      this.proteinCount = 0;
      this.carbsCount = 0;
      this.caloriesCount = 0;
    },
    fatChange(data) {
      this.fatCount++;
      console.log(data);
      this.fatValue = Math.round(data).toString();
      if (this.fatCount != 1) {
        this.menuItem.meanInfo.fat = Math.round(data).toString();
      }
    },
    proteinChange(data) {
      this.proteinCount++;
      this.proteinValue = Math.round(data).toString();
      if (this.proteinCount != 1) {
        this.menuItem.meanInfo.protein = Math.round(data).toString();
      }
    },
    carbsChange(data) {
      this.carbsCount++;
      this.carbsValue = Math.round(data).toString();
      if (this.carbsCount != 1) {
        this.menuItem.meanInfo.carbs = Math.round(data).toString();
      }
    },
    caloriesChange(data) {
      this.caloriesCount++;
      this.caloriesValue = Math.round(data).toString();
      if (this.caloriesCount != 1) {
        this.menuItem.meanInfo.calories = Math.round(data).toString();
      }
    },
    limiter(e) {
      if (e.length > 3) {
        e.pop();
      }
    },
    onShowDialog() {
      this.menuItem = JSON.parse(JSON.stringify(this.menuItemInfo));
    },
    getImage() {
      if (this.menuItem.image) return this.imageUrl + this.menuItem.image;
      else return require("../../assets/no-image.png");
    },
    AddIngredient() {
      const tempIngredient = {
        ingredient: this.newIngredient,
        quantity: this.newQuantity,
        quantityAfterPreperation: this.quantityAfter,
        isDislikable: this.isDislikable,
      };
      let exists = this.menuItem.ingredients.filter(
        (x) => x.ingredient._id == this.newIngredient._id
      );

      if (exists.length > 0) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Ingredient already exist";
        this.$refs.ingredient.reset();
      } else {
        this.menuItem.ingredients.push(tempIngredient);
        this.$refs.ingredient.reset();
      }
    },
    ingredientPrice() {
      let price = 0;
      if (this.menuItem && this.menuItem.ingredients) {
        this.menuItem.ingredients.map(function (elt) {
          price = price + elt.quantity * elt.ingredient.quantityPrice;
        });
      }
      return parseFloat(price).toFixed(3);
    },
    changeImage() {
      this.isChangable = true;
    },
    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      // console.log(selectedFile);
      this.menuItem.image = selectedFile;
    },
    updateMenuItem() {
      if (!this.menuItem.ingredients.length) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please add an ingredient";
        return;
      }
      let updatedIngredient = [];
      if (this.menuItem.meanInfo != null) {
        delete this.menuItem.meanInfo.__typename;
      }
      if (this.menuItem.ingredients != null) {
        this.menuItem.ingredients.map(function (elt) {
          delete elt.__typename;
          updatedIngredient.push({
            ingredient: elt.ingredient._id,
            quantity: String(elt.quantity),
            quantityAfterPreperation: String(
              elt.quantityAfterPreperation ? elt.quantityAfterPreperation : 0
            ),
            isDislikable: elt.isDislikable,
          });
        });
      }
      let selectedCategories = [];
      console.log(this.menuItem.category);
      if (this.menuItem.category != null) {
        this.menuItem.category.map(function (elt) {
          if (elt._id === undefined) selectedCategories.push(elt);
          else selectedCategories.push(elt._id);
        });
      }
      let selectedMealsTags = [];
      console.log(this.menuItem.mealsTag);
      if (this.menuItem.mealsTag != null) {
        this.menuItem.mealsTag.map(function (elt) {
          if (elt._id === undefined) selectedMealsTags.push(elt);
          else selectedMealsTags.push(elt._id);
        });
      } else {
        selectedMealsTags = this.menuItem.mealsTag;
      }
      // debugger;
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob((blob) => {
          this.image = blob;
          this.btnloading = true;
          this.updateMenuItemAPI(
            selectedCategories,
            selectedMealsTags,
            updatedIngredient
          );
        });
      } else {
        this.updateMenuItemAPI(
          selectedCategories,
          selectedMealsTags,
          updatedIngredient
        );
      }
    },

    updateMenuItemAPI(
      selectedCategories,
      selectedMealsTags,
      updatedIngredient
    ) {
      this.$apollo
        .mutate({
          mutation: require("@/menu-packages/api/updateMenuItem.graphql"),
          variables: {
            id: this.menuItem._id,
            nameEn: this.menuItem.nameEn,
            nameAr: this.menuItem.nameAr,
            descriptionEn: this.menuItem.descriptionEn,
            descriptionAr: this.menuItem.descriptionAr,
            showIngredientsInApp: this.menuItem.showIngredientsInApp,
            image: this.image?this.image:null,
            dietPlans: this.dietPlans,
            meanInfo: this.menuItem.meanInfo,
            active: this.menuItem.active,
            // price: this.menuItem.price,
            category: selectedCategories,
            mealsTag: selectedMealsTags,
            ingredients: updatedIngredient,
            availableWeekdays: this.menuItem.availableWeekdays,
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.isChangable = false;
            this.show_dialog_box = false;
            this.$root.$emit("updateMenuitems");
          },
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
      this.isChangable = false;
      // this.$refs.form.reset();
      this.fatCount = 0;
      this.proteinCount = 0;
      this.carbsCount = 0;
      this.caloriesCount = 0;
    },
    isFloatNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
