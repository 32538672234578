<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="white--text text-capitalize btn_fnt"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportBlock"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="blockList"
        class="elevation-1 "
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    blockList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [
           { text: "Name English", value: "nameEn", width: 230 },
        { text: "Name Arabic", value: "nameAr", width: 230 },
        { text: "Status", value: "active", width: 230 },
      ]
    };
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportBlock", "Block", false);

      this.loading = false;
    }
  }
};
</script>
