<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class=" text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-google-maps</v-icon> View Map
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <gmap-map
          :center="center"
          :zoom="12"
          style="width: 100%; height: 380px"
          ref="map"
        >
          <gmap-polygon
            v-if="paths.length > 0"
            :paths="paths"
            :editable="false"
            ref="polygon"
          ></gmap-polygon>
        </gmap-map>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCfmLa-BRLoYmupAvEtTopYQPB-VVJeRrk",
    libraries: "geometry" // This is required when working with polygons,
  }
});

export default Vue.extend({
  components: {},
  apollo: {
    getAllAreas() {
      return {
        query: require("../api/getAllAreas.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.areaList = data.getAllAreas.areas;
          this.path = [];
          //
          this.areaList.forEach(area => {
            // let allPath = new Array();
            if (area.geofencingCoordinates) {
              let path = new Array();
              area.geofencingCoordinates.forEach(element => {
                path.push({ lng: element.lng, lat: element.lat });
              });
              this.paths = [...this.paths, ...path];
              //this.paths.push(allPath);
            }
          });
          console.log(this.paths);
          this.totalItemsCount = data.getAllAreas.totalCount;
        }
      };
    }
  },

  data() {
    return {
      geoFenceCordinatesOutput: [],
      geoFenceCordinates: [],
      center: { lat: 33.13, lng: 41.262 },
      areaList: "",
      paths: [],
      dialog: false
    };
  },

  methods: {
    close() {
      this.dialog = false;
    }
  }
});
</script>
