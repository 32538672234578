<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1100px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="gray1--text">mdi-eye</v-icon>
      </template>
      <v-card class="pa-3 pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Purchase Details</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-5">
            <v-layout wrap class="mb-5">
              <v-flex xs5 sm3 md1>
                <h4 class="font-weight-light mt-2 black--text">Invoice No:</h4>
              </v-flex>
              <v-flex xs6 sm8 md2 class="mt-2">
                <h3 class="font-weight-medium black--text">
                  {{ purchase.invoiceNumber }}
                </h3>
              </v-flex>
              <v-flex xs5 sm3 md1 class="ms-md-16">
                <h4 class="font-weight-light mt-2 black--text">
                  Purchase Date:
                </h4>
              </v-flex>
              <v-flex xs6 sm8 md2 class="ms-md-1 mt-2">
                <h3 class="font-weight-medium black--text">
                  {{
                    new Date(parseInt(purchase.purchaseDate))
                      | moment("MMM DD YYYY")
                  }}
                </h3>
              </v-flex>
              <v-flex xs5 sm3 md1 class="ms-md-10">
                <h4 class="font-weight-light mt-2 black--text">Total Price:</h4>
              </v-flex>
              <v-flex xs6 sm8 md3 class="mt-2 black--text">
                <h3 class="font-weight-medium">
                  {{ purchase.purchasePrice.toFixed(3) }} SR
                </h3>
              </v-flex>
            </v-layout>
            <!-- <v-container> -->
            <v-data-table
              :mobile-breakpoint="0"
              :headers="headers"
              :items="purchase.purchaseDetails"
              :items-per-page="5"
              class="elevation-1"
              :server-items-length="totalItemsCount"
              :footer-props="{
                'items-per-page-options': [25, 50, 100, 500],
              }"
            >
              <template v-slot:item.quantity="{ item }">
                <span v-if="item.ingredient.quantityUnit == 'PIECE'">
                  {{ item.quantity.toFixed(0) }}&nbsp;{{
                    itemQuantityUnit(item)
                  }}
                </span>
                <span v-else>
                  {{ (item.quantity / 1000).toFixed(3) }}&nbsp;{{
                    itemQuantityUnit(item)
                  }}
                </span>
              </template>
              <template v-slot:item.totalPrice="{ item }">
                {{ item.totalPrice.toFixed(3) }} SR
              </template>
              <template v-slot:item.unitPrice="{ item }">
                {{ item.unitPrice.toFixed(3) }} SR
              </template>
            </v-data-table>
            <!-- </v-container> -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    purchase: {
      default: true,
    },
  },
  data() {
    return {
      show_dialog_box: false,
      headers: [
        { text: "Item ID", value: "itemId", sortable: false },
        { text: "Item Name", value: "nameEn", sortable: false },
        { text: "Supplier", value: "description", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Unit Price", value: "unitPrice", sortable: false },
        { text: "Total Price", value: "totalPrice", sortable: false },
      ],
      totalItemsCount: 25,
    };
  },
  methods: {
    itemQuantityUnit(item) {
      let unit = "";
      if (item.ingredient) {
        if (item.ingredient.quantityUnit === "GRAMS") unit = "KG";
        else if (item.ingredient.quantityUnit === "ML") unit = "L";
        else if (item.ingredient.quantityUnit === "PIECE") unit = "PCS";
      }
      return unit;
    },
    close() {
      this.show_dialog_box = false;
      // this.$refs.form.reset();
    },
  },
};
</script>
