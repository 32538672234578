<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" @click="onShowDialog"
          >mdi-square-edit-outline</v-icon
        >
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Area</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" @submit="updateArea()" ref="form">
          <v-card-text>
            <v-container>
              <v-layout>
                <v-flex xs12 sm4 md12>
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="area.region"
                    item-value="_id"
                    :items="regions"
                    :rules="[requiredValidator('region ')]"
                    label="Select Region"
                    dense
                    attach
                    solo
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 sm4 md12>
                  <Geofence
                    v-model="geoFenceCordinatesOutput"
                    :geoFenceCordinates="geoFenceCordinates"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-3 mt-sm-0" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Area</h4>
                </v-flex>
                <v-flex xs12 sm7 md4 class="mt-5 mt-md-0">
                  <v-text-field
                    placeholder="Area Name"
                    v-model="area.nameEn"
                    :rules="[requiredValidator('Area Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Area (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm7 md4>
                  <v-text-field
                    label="Area Name Arabic"
                    v-model="area.nameAr"
                    :rules="[requiredValidator('Area Name Arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class="text-capitalize"
              :loading="btnloading"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="updateArea"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import Geofence from "@/region/components/Geofence.vue";
export default {
  components: {
    Geofence
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      geoFenceCordinatesOutput: [],
      geoFenceCordinates: [],
      btnloading: false,
      dialog: false,
      addedArea: [],
      nameEn: "",
      nameAr: "",
      snackColor: "",
      snackbar: false,
      text: "",
      area: [],
      regions: [],
      region: ""
    };
  },
  apollo: {
    getAllRegions() {
      return {
        query: require("@/region/api/getAllRegions.graphql"),
        variables: {
          skip: 0,
          limit: 0
        },
        result({ data, loading }) {
          this.loading = loading;
          this.regions = data.getAllRegions.regions;
        }
      };
    }
  },
  props: {
    areaInfo: {
      required: true
    }
  },
  created() {
    this.area = JSON.parse(JSON.stringify(this.areaInfo));
  },

  methods: {
    onShowDialog() {
      this.area = JSON.parse(JSON.stringify(this.areaInfo));

      this.geoFenceCordinates = [this.areaInfo.geofencingCoordinates];
      this.geoFenceCordinatesOutput = [this.areaInfo.geofencingCoordinates];
    },
    updateArea() {
      if (this.$refs.form.validate()) {
        if (this.geoFenceCordinatesOutput.length == 0) {
          this.text = "Please plot area in map.";
          this.snackColor = "error";
          this.snackbar = true;
          return true;
        }
        this.btnloading = true;
        this.areaInfo.nameEn = this.area.nameEn;
        this.areaInfo.nameAr = this.area.nameAr;
        this.areaInfo.region = this.area.region;
        let location = new Array();
        this.geoFenceCordinatesOutput[0].forEach(element => {
          location.push({ lat: element.lat, lng: element.lng });
        });
        this.geoFenceCordinates = [location];
        this.$apollo
          .mutate({
            mutation: require("../api/updateArea.graphql"),
            variables: {
              id: this.area._id,
              nameEn: this.area.nameEn,
              nameAr: this.area.nameAr,
              geofencing: location,
              region: this.area.region._id
                ? this.area.region._id
                : this.area.region
            },
            update: () => {
              this.btnloading = false;
              this.dialog = false;
            }
          })
          .catch(error => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
