var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"px-md-7 px-lg-6",attrs:{"col-12":"","xs12":"","md6":"","lg4":""}},[_c('v-card',{staticClass:"pt-8 mx-auto",attrs:{"height":"440","tile":"","max-width":_vm.$vuetify.breakpoint.xl
        ? 400
        : _vm.$vuetify.breakpoint.lg
        ? 350
        : _vm.$vuetify.breakpoint.md
        ? 390
        : _vm.$vuetify.breakpoint.sm
        ? 420
        : 340}},[_c('label',{staticClass:"text-h6 ms-4"},[_vm._v(" Birthday ")]),(_vm.customers.length > 0)?_c('div',[_c('v-virtual-scroll',{staticClass:"mt-4",attrs:{"height":"300","item-height":"64","bench":_vm.benched,"items":_vm.customers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{key:item._i},[_c('v-list-item-action',[_c('v-avatar',{attrs:{"width":80,"height":80}},[_c('v-img',{attrs:{"src":require('../../assets/user.jpg'),"contain":"","max-width":"50","max-height":"50","position":"left"}})],1)],1),_c('v-row',[_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
                      name: 'CustomerDetails',
                      params: { customerId: item._id },
                      query: { customerId: item._id },
                    }}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user.firstnameEn))+" "+_vm._s(_vm.capitalizeFirstLetter(item.user.lastnameEn))+" ")])])],1),_c('v-list-item-title',[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm._f("moment")(new Date(parseInt(item.user.dob)),"DD-MMM-YYYY"))+" ")])])],1)],1)],1)]}}],null,false,2032723864)})],1):_c('div',[_c('v-list-item-title',{staticClass:"text-center mb-10 mt-16"},[_c('v-layout',{staticClass:"pt-16",attrs:{"justify-center":""}},[_vm._v("******No Birthdays******")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }