<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3   white--text text-capitalize btn_fnt" 
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportManagerList"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="managerList"
        class="elevation-1 "
      >
        <template v-slot:[`item.user`]="{ item }">
          <!-- <v-span v-if="item.user">  -->
          {{
            item.user.firstnameEn[0].toUpperCase() +
              item.user.firstnameEn.substring(1)
          }}
          {{
            item.user.lastnameEn[0].toUpperCase() +
              item.user.lastnameEn.substring(1)
          }}
          <!-- </v-span> -->
        </template>

        <template v-slot:[`item.userRole`]="{ item }">
          <div v-if="item.user.userRole">
            <span v-if="item.user.userRole.role">
              {{ item.user.userRole.role.toUpperCase() }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.referralCode`]="{ item }">
          <div v-if="item.user.userId">
            {{
              parseInt(item.user.userId)
                .toString(16)
                .toUpperCase()
            }}
          </div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    managerList: {
      required: true
    }
  },
  data() {
    return {
      dietPlansExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [
        { text: "Name", value: "user", width: 200, sortable: false },
        { text: "Phone", value: "user.phone", width: 150, sortable: false },
        { text: "Is Active", value: "isActive", width: 80, sortable: false },
        {
          text: "Referral Code",
          value: "referralCode",
          sortable: false,
          width: 100
        },
        { text: "Type of User", value: "userRole", sortable: false, width: 100 }
      ]
    };
  },
  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportManagerList", "Manager List", false);
      this.loading = false;
    }
  }
};
</script>
