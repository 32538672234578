var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shiftList != null)?_c('div',[_c('v-layout',{attrs:{"justify-end":""}},[_c('ExportShift',{attrs:{"headers":_vm.headers,"shiftList":_vm.shiftList}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"items":_vm.shiftList,"mobile-breakpoint":0,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.zone",fn:function(ref){
var item = ref.item;
return [(item.zone)?_c('span',[_vm._v(" "+_vm._s(item.zone.zoneName))]):_vm._e()]}},{key:"item.mngDriver",fn:function(ref){
var item = ref.item;
return [(item.mngDriver)?_c('span',[_vm._v(" "+_vm._s(item.mngDriver.user.firstnameEn)+" "+_vm._s(item.mngDriver.user.lastnameEn)+" ")]):_vm._e()]}},{key:"item.evngDriver",fn:function(ref){
var item = ref.item;
return [(item.evngDriver)?_c('span',[_vm._v(" "+_vm._s(item.evngDriver.user.firstnameEn)+" "+_vm._s(item.evngDriver.user.lastnameEn)+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.active),expression:"item.active"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.active),expression:"!item.active"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.LOCATION_EDIT))?_c('ShiftUpdateForm',{attrs:{"shiftInfo":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.LOCATION_DELETE))?_c('ShiftDelete',{attrs:{"shift":item}}):_vm._e()],1)]}}],null,false,3878065227)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }