<template>
  <v-container fluid class="pa-4 pa-sm-8">
    <v-progress-linear v-if="loading" color="primary"></v-progress-linear>
    <div v-if="celebrity.customer && celebrity.customer.user">
      <v-row>
        <v-col cols="4" md="1">
          <v-avatar :width="80" :height="80">
            <v-img
              v-if="celebrity.image != null"
              :src="imageUrl + celebrity.image"
            ></v-img>
            <v-img
              v-else
              :src="require('../../assets/user.jpg')"
              contain
              :position="!$vuetify.rtl ? 'left' : 'right'"
            />
          </v-avatar>
        </v-col>
        <v-col cols="6" md="4" align-self="center" class="ms-sm-4">
          <v-row>
            <h2 class="primary--text mb-n2">
              <router-link
                :to="{
                  name: 'CustomerDetails',
                  params: { customerId: celebrity.customer._id },
                  query: { customerId: celebrity.customer._id },
                }"
                class="mx-auto"
              >
                {{
                  celebrity.customer.user.firstnameEn[0].toUpperCase() +
                  celebrity.customer.user.firstnameEn.substring(1)
                }}
                {{
                  celebrity.customer.user.lastnameEn[0].toUpperCase() +
                  celebrity.customer.user.lastnameEn.substring(1)
                }}
              </router-link>
              <a
                v-if="celebrity.image != null"
                :href="imageUrl + celebrity.image"
                download
              >
                <v-icon>mdi-cloud-download</v-icon>
              </a>
            </h2>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-8 mt-sm-0">
        <v-spacer></v-spacer>
        <CelebrityPayment :celebrity="celebrity" />
        <CelebrityUpdateForm
          v-if="hasRole(AdminUserPermissions.CELEBRITY_EDIT)"
          :celebrityInfo="celebrity"
        />
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <CelebrityInfo :celebrity="celebrity" />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <CelebrityPersonalInfo :celebrity="celebrity" />
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-layout wrap class="mt-8 mb-6">
        <v-tabs
          v-model="tabs"
          :mobile-breakpoint="0"
          background-color="bg"
          slider-size="3"
        >
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
          >
            Payments
          </v-tab>

          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
          >
            Plans Taken
          </v-tab>
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              black--text
              text-capitalize
            "
          >
            Packages
          </v-tab>
        </v-tabs>
      </v-layout>
      <!-- {{ referralTransactions }} -->
      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item>
          <CelebrityPaymentHistoryTable
            :referralTransactions="referralTransactions"
            :loading="transactionListloading"
          />
        </v-tab-item>
        <v-tab-item>
          <CelebrityPlanTakenTable
            :referralTransactions="referralTransactions"
            :loading="transactionListloading"
          />
        </v-tab-item>
        <v-tab-item>
          <v-layout class="bg" wrap>
            <AssignPackage :celebrity="celebrity"/>
          </v-layout>
          <CelebrityPackagesTable :celebrity="celebrity" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import CelebrityUpdateForm from "../components/CelebrityUpdateForm.vue";
import CelebrityInfo from "../components/CelebrityInfo.vue";
import CelebrityPaymentHistoryTable from "../components/CelebrityPaymentHistoryTable.vue";
import CelebrityPlanTakenTable from "../components/CelebrityPlanTakenTable.vue";
import CelebrityPayment from "../components/CelebrityPayment.vue";
import CelebrityPersonalInfo from "../components/CelebrityPersonalInfo.vue";
import CelebrityPackagesTable from "../components/CelebrityPackagesTable.vue";
import AssignPackage from "../components/AssignPackage.vue";

export default {
  components: {
    CelebrityUpdateForm,
    CelebrityPaymentHistoryTable,
    CelebrityPlanTakenTable,
    CelebrityPayment,
    CelebrityInfo,
    CelebrityPersonalInfo,
    CelebrityPackagesTable,
    AssignPackage,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.CELEBRITY_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    this.getCelebrityDetails();
  },
  mounted() {
    this.$root.$on("updategetCelebrityById", () => {
      this.getCelebrityDetails();
    });
  },
  watch: {
    "$route.query.celebrityId": {
      handler() {
        this.getCelebrityDetails();
      },
    },
  },
  methods: {
    getAllReferralTransactionsByUser() {
      this.transactionListloading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllReferralTransactionsByUser.graphql"),
          variables: { id: this.celebrity.customer.user._id },
        })
        .refetch({})
        .then((data) => {
          this.referralTransactions = [];
          this.transactionListloading = false;
          if (data.data.getAllReferralTransactionsByUser) {
            this.referralTransactions =
              data.data.getAllReferralTransactionsByUser.userReferrals;
            this.referralTransactions = this.referralTransactions.map(
              (items, index) => ({
                ...items,
                index: index + 1,
              })
            );
          }
        })
        .catch((error) => {
          this.transactionListloading = false;
          console.log(error);
        });
    },
    getCelebrityDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getCelebrityById.graphql"),
          variables: {
            id: this.$route.query.celebrityId,
          },
        })
        .refetch({
          variables: {
            id: this.$route.query.celebrityId,
          },
        })
        .then((data) => {
          this.loading = false;

          if (data.data.getCelebrityById) {
            this.celebrity = data.data.getCelebrityById;
            let date = new Date();
            this.celebrity.image = this.celebrity.image + "?" + date.getTime();
            this.getAllReferralTransactionsByUser();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  data() {
    return {
      isDisable: true,
      imageUrl: process.env.VUE_APP_BASE_URL,
      celebrity: this.$route.query.celebrityId,
      snackbar: false,
      text: "",
      tabs: null,
      btnloading: false,
      loading: true,
      transactionListloading: true,
      referralTransactions: [],
    };
  },
};
</script>