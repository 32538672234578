<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Add FAQ Question</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="updateFaq()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Question</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-textarea
                    solo
                    name="input-7-4"
                    label="Question"
                    v-model="faq.question"
                    :rules="[requiredValidator('question')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Answer</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-textarea
                    solo
                    name="input-7-4"
                    label="Answer"
                    v-model="faq.answer"
                    :rules="[requiredValidator('answer')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Active</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-radio-group v-model="faq.active" :mandatory="false" row>
                    <v-radio label="Yes" color="secondary" :value="true"></v-radio>
                    <v-radio label="No" color="secondary" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm7 md6>
                  <v-text-field
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                    @keypress="isNumber"
                    label="Show Order"
                    type="number"
                    v-model="faq.showOrder"
                    min="0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn
            color="secondary"
            class=" mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="updateFaq"
            :loading="btnloading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </div>
</template>


<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  props: {
    faq: {
      required: true,
    },
  },
  data() {
    return {
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      question: "",
      answer: "",
      showOrder: 0,
      active: true,
      show_dialog_box: false,
      requiredValidator: requiredValidator,
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    updateFaq() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/faq/api/updateFaq.graphql"),
          variables: {
            id: this.faq._id,
            question: this.faq.question,
            answer: this.faq.answer,
            showOrder: this.faq.showOrder,
            active: this.faq.active,
          },
          update: () => {
            // this.$refs.form.reset();
            this.btnloading = false;
            this.show_dialog_box = false;
          },
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>