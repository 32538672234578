import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },

    theme: {
        themes: {
            dark: {
                primary: '#000000',
                secondary: '#6fbf98',
                pop: '#69F0AE',
                bg: '#ffffff',
                pop_bg: '#ffffff',
                solid:'#c16d87',
                table_body:'#f4e2ce',
                text_white:'#ffffff'
            },
            light: {
                primary: '#000000',
                secondary: '#6fbf98',
                pop: '#69F0AE',
                bg: '#ffffff',
                pop_bg: '#ffffff',
                solid:'#c16d87',
                table_body:'#f4e2ce',
                text_white:'#ffffff'
            },
        },
    },
})
