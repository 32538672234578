<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-row class="ms-1">
          <v-btn :disabled="!isAddress" class=" text-capitalize mt-4 mb-8" color="secondary" v-on="on">
            <v-icon class="me-1" small>mdi-plus</v-icon>ADD
          </v-btn>
          <h5 v-if="!isAddress" class="error--text ms-2 mt-4">
            No address found
          </h5>
        </v-row>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-3" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n2">Delivery Schedule</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2"></v-divider>
        <v-form @submit="createDeliverySchedule()" v-model="valid" ref="form" class="pa-6 pa-sm-8">
          <v-layout wrap class="ms-2">
            <!-- {{ selectPackage }} -->
            <v-flex xs12 md3>
              <h4 class="font-weight-medium text-body-2 black--text">Week</h4>
            </v-flex>
            <v-flex xs12 md8>
              <v-select v-model="week" :items="listOfDays" solo dense :rules="[requiredValidator('week')]" attach chips
                label="Week" return-object></v-select>
            </v-flex>
          </v-layout>

          <v-row class="mx-2 mt-4 mb-4" justify="space-between" align="end">
            <h4 class="primary--text text-h6">Address</h4>
          </v-row>
          <div v-for="address in addressList" :key="address._id">
            <v-row class="mb-n6 mt-n5" justify="end">
              <v-radio-group v-model="selectdAddress" :rules="[requiredValidator('address')]">
                <v-radio :value="address._id"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row class="mb-n6 mt-n14">
              <v-col class="ms-2 text-body-2 black--text" md="5">Area</v-col>
              <v-col md="6" class="font-weight-medium text-body-2 black--text">
                {{ address.area.nameEn }}
              </v-col>
            </v-row>

            <v-row class="mb-n6">
              <v-col md="5" class="ms-2 text-body-2 black--text">Street</v-col>
              <v-col md="6" class="font-weight-medium text-body-2 black--text">{{ address.street }}</v-col>
            </v-row>

            <v-row class="mb-n6">
              <v-col class="ms-2 text-body-2 black--text" md="5">House number</v-col>
              <v-col md="6" class="font-weight-medium text-body-2 black--text">{{ address.house_number }}</v-col>
            </v-row>

            <v-row class="mb-n6">
              <v-col class="ms-2 text-body-2 black--text" md="5">Flat</v-col>
              <v-col md="6" class="font-weight-medium text-body-2 black--text">{{ address.flat }}</v-col>
            </v-row>
         

            <v-row class="mb-6">
              <v-col class="ms-2 text-body-2 black--text" md="5">Floor number</v-col>
              <v-col md="6" class="font-weight-medium text-body-2 black--text">

                {{ address.floor }}
              </v-col>
            </v-row>
          </div>

          <v-layout row class="mt-8 mb-n5">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="primary--text text-capitalize" width="120" @click="createDeliverySchedule()"
              :loading="btnloading" :disabled="!valid">Save</v-btn>
          </v-layout>
        </v-form>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">{{ text }}</v-snackbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { requiredValidator } from "../../../core/methods/validations";
import { extend, setInteractionMode } from "vee-validate";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} cannot be empty"
});
export default {
  components: {},

  data() {
    return {
      selectdAddress: null,
      addressList: [],
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      week: "",
      selectPackage: "",
      choice: "",
      start_date: "",
      menu1: false,
      snack: false,
      snackColor: "",
      snackText: "",
      isValidIngredient: true,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      isAddress: false,
      errors: [],
      snackbar: false,
      text: "",
      snackbarColor: "",
      variables: {
        limit: 25,
        skip: 0
      },
      packagesList: []
    };
  },

  created() {
    this.getCustomerDetails();
  },
  watch: {
    "$route.query.customerId": {
      handler() {
        this.getCustomerDetails();
      }
    }
  },
  methods: {
    defaultAddress() {
      return this.customer.addressDetails;
    },
    getCustomerDetails() {
      this.$apollo
        .query({
          query: require("../../api/getCustomerById.graphql"),
          variables: {
            id: this.$route.query.customerId
          }
        })
        .then(data => {
          this.loading = false;

          if (data.data.getCustomerById) {
            this.customer = data.data.getCustomerById;
            this.addressList = this.customer.addressDetails;
            if (this.addressList.length > 0) {
              this.isAddress = true;
              this.selectdAddress = this.addressList[0]._id;
            }
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    createDeliverySchedule() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../api/createDeliverySchedule.graphql"),
          variables: {
            customer: this.$route.query.customerId,
            week: this.week,
            address: this.selectdAddress
          },
          update: (store, { data: { createDeliverySchedule } }) => {
            try {
              const query = {
                query: require("../../api/getDeliveryScheduleByCustomerId.graphql"),
                variables: {
                  id: this.$route.query.customerId
                }
              };

              let allDeliverySchedule = store.readQuery(query);
              allDeliverySchedule.getDeliveryScheduleByCustomerId.unshift(
                createDeliverySchedule
              ); //push to top
              store.writeQuery({
                ...query,
                data: allDeliverySchedule
              });

              this.text = "Delivery Schedule  Added";
              this.snackbarColor = "success";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.show_dialog_box = false;
          }
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            this.button_loading = false;
            this.error = null;
            this.dialog = false;
            console.error(error.graphQLErrors);

            if (error.graphQLErrors[0].message === "ALREADY_EXIST") {
              this.text = "Week already exist";
            }

            this.snackbarColor = "error";
            this.snackbar = true;
          }
          this.btnloading = false;
          console.log(error);
        });
    },
    close() {
      this.show_dialog_box = false;
    }
  }
};
</script>
