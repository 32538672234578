var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.referralTransactions,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.credit)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.credit).toFixed(3))+" SR ")]):_c('span',[_vm._v(" -- ")])]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.debit)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.debit).toFixed(3))+" SR ")]):_c('span',[_vm._v(" -- ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdAt)),"MMM DD YYYY hh:mmA"))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.status),expression:"!item.status"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }