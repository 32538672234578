<template>
  <v-container>
    <v-dialog v-model="show_dialog_box" max-width="1500px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="font-weight-bold text-capitalize"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Email Template
        </v-btn>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Pickup Address</span>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createFaq()" v-model="valid" ref="form">
              <v-layout>
                <v-flex>
                  <h4 class="black--text text-body-2">
                    Template (English & Arabic)
                  </h4>
                  Place holders
                  :CUSTOMER_NAME,PLAN_NAME,START_DATE,END_DATE,NO_OF_DAYS,AMOUNT_PAID,INVOICE_LINK
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm8 md6>
                  <client-only> <VueEditor v-model="templateEn" /> </client-only
                ></v-flex>

                <v-flex xs12 sm8 md6>
                  <client-only> <VueEditor v-model="templateAr" /> </client-only
                ></v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn
            color="secondary"
            class="mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="updateTax"
            :loading="btnloading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import { VueEditor } from "vue2-editor";
export default {
  components: { VueEditor },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations) {
            let subscriptionEmailTemplate =
              data.getAppConfigurations.subscriptionEmailTemplate;
            if (subscriptionEmailTemplate) {
              this.templateEn = subscriptionEmailTemplate.en;
              this.templateAr = subscriptionEmailTemplate.ar;
            }
          }
        }
      };
    }
  },
  methods: {
    updateTax() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            subscriptionEmailTemplate: {
              en: this.templateEn,
              ar: this.templateAr
            }
          }
        })
        .then(() => {
          this.show_dialog_box = false;
          this.btnloading = false;
          this.snackbar = true;
          this.text = "Address Updated";
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    }
  },
  data: () => ({
    templateEn: "",
    templateAr: "",
    loading: true,
    valid: false,
    requiredValidator,
    snackbar: false,
    text: "",
    btnloading: false,
    show_dialog_box: false,
    taxPercent: "",
    taxPercentService: "",
    supplierName: "",
    vatNumber: "",
    totalItemsCount: 25,
    searchPlace: "",
    map_location: "",
    latitude: 0,
    longitude: 0,
    addressCoordinates: null,
    addressLine1: "",
    addressLine2: ""
  }),

  created() {}
};
</script>
