var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.ingredientList,"items-per-page":5},scopedSlots:_vm._u([{key:"item.ingredient",fn:function(props){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","autofocus":""},model:{value:(props.item.ingredient.nameEn),callback:function ($$v) {_vm.$set(props.item.ingredient, "nameEn", $$v)},expression:"props.item.ingredient.nameEn"}})]}},{key:"item.quantity",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.quantity,"large":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "quantity", $event)},"update:return-value":function($event){return _vm.$set(props.item, "quantity", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v("Update Quantity")])]},proxy:true},{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","autofocus":"","type":"number"},model:{value:(props.item.quantity),callback:function ($$v) {_vm.$set(props.item, "quantity", $$v)},expression:"props.item.quantity"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(parseFloat(props.item.quantity).toFixed(3)))])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toFixed(3))+" ")]}},{key:"item.quantityAfterPreperation",fn:function(ref){
var item = ref.item;
return [(item.quantityAfterPreperation === null)?_c('p',[_vm._v("0")]):_c('p',[_vm._v(_vm._s(parseFloat(item.quantityAfterPreperation).toFixed(3)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity * item.ingredient.quantityPrice).toFixed(3))+" ")]}},{key:"item.ingredient.quantityUnit",fn:function(props){return [_c('div',[_vm._v(_vm._s(props.item.ingredient.quantityUnit))])]}},{key:"item.actions",fn:function(props){return [_c('v-row',[_c('UpdateMealIngredient',{attrs:{"ingredient":props.item},on:{"update":_vm.updateIngredient}}),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(props.item.ingredient._id)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.meanInfo",fn:function(ref){
var item = ref.item;
return [_vm._v(" Fat:"+_vm._s(( item.ingredient.meanInfo.fat * item.quantityAfterPreperation ).toFixed(2))),_c('br'),_vm._v(" Protein:"+_vm._s(( item.ingredient.meanInfo.protein * item.quantityAfterPreperation ).toFixed(2))+" "),_c('br'),_vm._v(" Carbs :"+_vm._s(( item.ingredient.meanInfo.carbs * item.quantityAfterPreperation ).toFixed(2))+" "),_c('br'),_vm._v(" Calories:"+_vm._s(( item.ingredient.meanInfo.calories * item.quantityAfterPreperation ).toFixed(2))+" ")]}},{key:"item.dislikable",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isDislikable),expression:"item.isDislikable"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.isDislikable),expression:"!item.isDislikable"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}}],null,true)},[_c('template',{slot:"body.append"},[(_vm.totalFat || _vm.totalProtein || _vm.totalCarbs || _vm.totalCalories)?_c('tr',[_c('th'),_c('th'),_c('th',[_vm._v("Total")]),_c('th'),_c('th',[_vm._v(" Total Fat: "+_vm._s(_vm.totalFat.toFixed(2))+" "),_c('br'),_vm._v(" Total Protein: "+_vm._s(_vm.totalProtein.toFixed(2))),_c('br'),_vm._v(" Total Carbs: "+_vm._s(_vm.totalCarbs.toFixed(2))+" "),_c('br'),_vm._v(" Total Calories: "+_vm._s(_vm.totalCalories.toFixed(2))+" "),_c('br')]),_c('th',[_vm._v(" Total Nutrients: "),_c('br'),_vm._v(" "+_vm._s(_vm.totalNutrientsValue.toFixed(2))+" ")]),_c('th'),_c('th'),_c('th')]):_vm._e()])],2),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }