<template>
  <v-container class="pa-6 pa-sm-8" fluid>
    <!-- {{ promocode }} -->
    <v-progress-linear v-if="loading" color="primary"></v-progress-linear>
    <div v-if="promocode.promoCode">
      <v-layout class="mb-6">
        <v-row>
          <v-col cols="7">
            <h3 class="primary--text">
              {{ promocode.promoCode }}
            </h3>
            <h4>{{ promocode.titleEn }} {{ promocode.titleAr }}</h4>
          </v-col>
          <v-spacer></v-spacer>
          <UpdatePromoCode v-if="hasRole(AdminUserPermissions.PROMO_CODE_EDIT)" :promoCodeInfo="promocode" />
        </v-row>
      </v-layout>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <PromoCodeInfo :promocode="promocode" />
        </v-col>
        <!-- <v-divider
          vertical
          class="mx-md-4"
          v-show="$vuetify.breakpoint.smAndUp"
        ></v-divider>
        <v-divider
          class="mx-4 mx-sm-6"
          v-show="$vuetify.breakpoint.xs"
        ></v-divider>
        <v-col cols="12" sm="5">
          <PromoCodeInfo :promocode="promocode" />
          <v-spacer></v-spacer>
        </v-col> -->
      </v-row>

      <v-layout wrap class="pa-sm-8 mt-4 mt-sm-0 mb-6 mb-sm-0">
        <v-tabs v-model="tabs" :mobile-breakpoint="0" background-color="bg">
          <v-tab
            class="
              text-caption text-sm-body-2
              font-weight-medium
              text-capitalize
            "
            >Customers</v-tab
          >
        </v-tabs>
      </v-layout>
      <v-tabs-items v-model="tabs" class="mb-6 mb-sm-0">
        <v-tab-item>
          <PromocodeCustomersTable :promocode="promocode" :loading="loading" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import UpdatePromoCode from "../components/UpdatePromoCode.vue";
import PromoCodeInfo from "../components/PromoCodeInfo.vue";
import PromocodeCustomersTable from "../components/PromocodeCustomersTable.vue";
export default {
  components: { UpdatePromoCode, PromoCodeInfo, PromocodeCustomersTable },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.PROMO_CODE_VIEW)) {
      this.$router.push({
        name: "Error404",
      });}
    this.getPromocodeDetails();
  },
  mounted() {
    this.$root.$on("updatePromoCode", () => {
      this.getPromocodeDetails();
      // console.log(this.customer);
    });
  },
  watch: {
    "$route.query.promocodeId": {
      handler() {
        this.getPromocodeDetails();
      },
    },
  },
  methods: {
    getPromocodeDetails() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getPromoCodeById.graphql"),
          variables: {
            id: this.$route.query.promocodeId,
          },
        })
        .refetch({
          variables: {
            id: this.$route.query.promocodeId,
          },
        })
        .then((data) => {
          this.loading = false;
          if (data.data.getPromoCodeById) {
            this.promocode = data.data.getPromoCodeById;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  data() {
    return {
      isDisable: true,
      promocode: this.$route.query.promocodeId,
      snackbar: false,
      text: "",
      tabs: null,
      btnloading: false,
      loading: true,
    };
  },
};
</script>