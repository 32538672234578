<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="white--text text-capitalize btn_fnt"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportFaq"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers" 
        :items="faqList"
        class="elevation-1 "
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils"; 
export default {
  props: {
    faqList: {
      required: true
    },
    
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [
        { text: "Question", value: "question", width: 700, sortable: false },
        // { text: "Answer", value: "answer" },
        { text: "Active", value: "active", sortable: false },
        { text: "Show Order", value: "showOrder", sortable: false },
      ],
    };
  },
  created(){
   this.head=this.headers.filter(x=>x.value!='actions' && x.value!='index')
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportFaq", "Faq", false); 

      this.loading = false;
    }
  }
};
</script>
