<template>
  <v-container>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="font-weight-bold text-capitalize"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Tax Settings
        </v-btn>
      </template>
      <v-card color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Tax</span>
          <v-spacer></v-spacer>
          <AuditTrialLog auditType="TAX_UPDATE" />
          <v-btn color="primary" text @click="show_dialog_box = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form @submit="createFaq()" v-model="valid" ref="form">
              <v-layout>
                <v-flex>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">
                        Product Tax
                      </h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Product Tax"
                        append-icon="mdi-percent"
                        v-model="taxPercent"
                        :rules="[requiredValidator('product tax')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">Service Tax</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Service Tax"
                        append-icon="mdi-percent"
                        v-model="taxPercentService"
                        :rules="[requiredValidator('service tax')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">VAT Number</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="VAT Number"
                        v-model="vatNumber"
                        :rules="[requiredValidator('VAT number')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">Supplier Name</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Supplier Name"
                        v-model="supplierName"
                        :rules="[requiredValidator('supplier name')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">Address Line 1</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Address Line 1"
                        v-model="addressLine1"
                        :rules="[requiredValidator('address line 1')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">Address Line 2</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Address Line 2"
                        v-model="addressLine2"
                        :rules="[requiredValidator('address line 2')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm2 md4>
                      <h4 class="text-body-2 black--text">Address Line 3</h4>
                    </v-flex>
                    <v-flex xs12 sm7 md7>
                      <v-text-field
                        solo
                        dense
                        name="input-7-4"
                        label="Address Line 3"
                        v-model="addressLine3"
                        :rules="[requiredValidator('address line 3')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-end">
          <v-btn
            color="secondary"
            class="mb-2 mb-sm-4 text-capitalize"
            :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
            @click="updateTax"
            :loading="btnloading"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: { AuditTrialLog },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations) {
            this.taxPercent = data.getAppConfigurations.taxPercent;
            this.taxPercentService =
              data.getAppConfigurations.taxPercentService;
            this.supplierName = data.getAppConfigurations.supplierName;
            this.vatNumber = data.getAppConfigurations.vatNumber;
            this.addressLine1 = data.getAppConfigurations.addressLine1;
            this.addressLine2 = data.getAppConfigurations.addressLine2;
            this.addressLine3 = data.getAppConfigurations.addressLine3;
          }
        }
      };
    }
  },
  methods: {
    updateTax() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            taxPercent: parseFloat(this.taxPercent),
            taxPercentService: parseFloat(this.taxPercentService),
            vatNumber: this.vatNumber,
            supplierName: this.supplierName,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            addressLine3: this.addressLine3
          }
        })
        .then(() => {
          this.show_dialog_box = false;
          this.btnloading = false;
          this.snackbar = true;
          this.text = "Tax Updated";
        })
        .catch(error => {
          // Error
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    }
  },
  data: () => ({
    loading: true,
    valid: false,
    requiredValidator,
    snackbar: false,
    text: "",
    btnloading: false,
    show_dialog_box: false,
    taxPercent: "",
    taxPercentService: "",
    supplierName: "",
    vatNumber: "",
    totalItemsCount: 25,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
  }),

  created() {}
};
</script>
