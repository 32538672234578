<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" class="text_white--text text-capitalize" color="solid">
          +Add Package
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Assign Package</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-sm-8 mt-n6" v-model="valid" ref="form">
          <v-card-text>
            <v-container>
              <div>
                <v-layout wrap>
                  <!-- {{ selectPackage }} -->
                  <v-flex xs12 sm3 md2>
                    <h4 class="text-body-2 black--text">Plan</h4>
                  </v-flex>
                  <v-flex xs12 sm7 md8>
                    <v-select @change="choices = []" v-model="selectPackage" :items="packagesList" solo dense
                      item-value="_id" :rules="[requiredValidator('package')]" item-text="titleEn" chips label="Plan"
                      return-object></v-select>
                  </v-flex>
                </v-layout>

                <v-layout v-if="selectPackage && selectPackage.availableChoices" wrap>
                  <v-flex xs12 sm3 md2>
                    <h4 class="black--text text-body-2">Plan Choices</h4>
                  </v-flex>

                  <v-flex xs12 sm7 md8>
                    <v-select v-model="choices" :items="selectPackage.availableChoices" item-value="_id" dense multiple
                      solo item-text="choiceNameEn" chips deletable-chips return-object label="Choices">
                    </v-select>
                  </v-flex>
                </v-layout>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n6">
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="primary--text text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90" @click="addNewPackage" :disabled="!valid">Add</v-btn>
          </v-card-actions>
          <!-- {{ isPercentage }} -->
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";

export default {
  data() {
    return {
      requiredValidator,
      dialog: false,
      choices: [],
      valid: false,
      selectPackage: "",
      packagesList: [],
    };
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackagesWithChoice.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.packagesList = data.getAllPackages.packages.filter(
            (x) => x.active
          );
        },
      };
    },
  },
  methods: {
    addNewPackage() {
      let packageAssign = {
        plan: this.selectPackage,
        choices: this.choices,
      };
      this.$emit("addNewPackage", packageAssign);
      this.$refs.form.reset();
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>