var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3 mt-sm-0"},[_c('h2',{staticClass:"primary--text text-h5 font-weight-bold ms-2 mb-6"},[_vm._v(" Celebrities ")])]),_c('v-row',{staticClass:"mt-n4 mt-md-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-text-field',{attrs:{"placeholder":"Search..","solo":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.runSearch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.search.length > 0)?_c('v-icon',{staticClass:"me-n2",attrs:{"color":"black"},on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("mdi-close")]):_vm._e(),(_vm.search.length == 0)?_c('v-icon',{staticClass:"me-n2",attrs:{"color":"black"},on:{"click":function($event){return _vm.runSearch()}}},[_vm._v("mdi-magnify")]):_vm._e()]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mb-4 mb-sm-0",attrs:{"cols":"1"}},[(_vm.hasRole(_vm.AdminUserPermissions.CELEBRITY_ADD))?_c('CelebrityCreateForm',{attrs:{"Celebrity":_vm.Celebrity}}):_vm._e()],1),_c('v-col',{staticClass:"mb-4 mb-sm-0",attrs:{"cols":"1"}},[_c('AuditTrialLog',{staticClass:"ms-16 ms-md-4 mt-n6",attrs:{"auditType":"CELEBRITY_LOG"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.celebrityList,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
          name: 'CelebrityInfo',
          params: { celebrityId: item._id },
          query: { celebrityId: item._id },
        }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.referralCode",fn:function(ref){
        var item = ref.item;
return [(item.customer.user)?_c('span',[_vm._v(" "+_vm._s(parseInt(item.customer.user.userId).toString(16).toUpperCase())+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!item.status),expression:"!item.status"}],attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.CELEBRITY_DELETE))?_c('CelebrityDeleteForm',{attrs:{"celebrity":item}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }