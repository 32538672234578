<template>
  <div class="solid text_white--text rounded-lg" style="height: 270px">
    <v-flex class="mt-4 mx-2 mb-2">
      <h4 class="text_white--text text-h6 pt-4 ml-4 font-weight-bold">Personal Info</h4>
    </v-flex>

    <v-row class="ml-2">
      <v-col md="5" class="ms-2">Mobile</v-col>
      <v-col md="6" class="font-weight-medium">{{
        dietitian.user.phone
      }}</v-col>
    </v-row>
    <v-row class="mt-n6 ml-2">
      <v-col md="5" class="ms-2">Available Days</v-col>
      <v-col md="6" class="font-weight-medium">{{
        dietitian.availableDays.join(", ")
      }}</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dietitian: {
      required: true,
    },
  },
  methods: {
    toPascalCase(string) {
      return `${string}`
        .replace(new RegExp(/[-_]+/, "g"), " ")
        .replace(new RegExp(/[^\w\s]/, "g"), "")
        .replace(
          new RegExp(/\s+(.)(\w+)/, "g"),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, "g"), "")
        .replace(new RegExp(/\w/), (s) => s.toUpperCase());
    },
  },
  computed: {
    age: {
      get: function () {
        if (this.dietitian.user.dob) {
          let dateofbirth = new Date(parseInt(this.dietitian.user.dob));
          var ageDifMs = Date.now() - dateofbirth.getTime();
          var ageDate = new Date(ageDifMs);
          return Math.abs(ageDate.getUTCFullYear() - 1970);
        } else return "";
      },
      set: () => null,
    },
  },
};
</script>