<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="referralTransactions"
      class="elevation-1"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.customer="{ item }">
        <v-row v-if="item.customer">
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </v-row>
        <v-row v-else> -- </v-row>
      </template>
      <template v-slot:item.plan="{ item }">
        <span v-if="item.package">
          {{ item.package.titleEn }}
        </span>
        <span v-else> -- </span>
      </template>
      <!-- <template v-slot:item.choice="{ item }">
        <span
          v-if="
            item.package &&
            getChoice(item.package.availableChoices, item.choice)
          "
        >
          {{
            getChoice(item.package.availableChoices, item.choice).choiceNameEn
          }}
        </span>
        <span v-else> -- </span>
      </template> -->
      <template v-slot:item.noOfDays="{ item }">
        <span v-if="item.planDays">
          {{ item.planDays }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.credit="{ item }">
        <span v-if="item.credit">
          {{ parseFloat(item.credit).toFixed(3) }} SR
        </span>
        <span v-else> -- </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    referralTransactions: {
      require: true,
    },
    loading: {
      default: true,
    },
  },

  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Sl No",
        value: "index",
        sortable: false,
        width: 70,
      },
      {
        text: "Customer Name",
        value: "customer",
        sortable: false,
        width: 120,
      },
      {
        text: "Plan Name",
        value: "plan",
        sortable: false,
        width: 120,
      },
      // {
      //   text: "Days Choice",
      //   value: "choice",
      //   sortable: false,
      //   width: 120,
      // },
      { text: "No of Days", value: "noOfDays", sortable: false, width: 70 },
      {
        text: "Earning",
        value: "credit",
        sortable: false,
        width: 20,
      },
    ],
  }),

  methods: {
    getChoice(availableChoices, choiceId) {
      let selectedChoice = null;
      if (availableChoices && choiceId) {
        selectedChoice = availableChoices.find((x) => x._id == choiceId);
      }
      return selectedChoice;
    },
  },
};
</script>

