<template>
  <v-container>
    <v-row>
      <v-btn
        class=" text-capitalize ms-sm-4 mb-4"
        color="secondary"
        @click.stop="assignPackage()"
      >
        <v-icon class="me-1" small>mdi-plus</v-icon>Package
      </v-btn>
    </v-row>
    <v-dialog v-model="showDialog" max-width="1300">
      <v-card class="pa-8" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n4"
            >Assign Package</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-progress-linear v-show="loading"></v-progress-linear>

        <v-row class="mt-4">
          <v-col cols="12" md="10" class="mx-auto">
            <v-row justify="center">
              <v-row class="mt-n4 mt-sm-0 px-0">
                <v-col
                  v-for="plan in packages"
                  :key="plan.id"
                  cols="12"
                  sm="4"
                  lg="3"
                >
                  <v-card
                    max-width="300"
                    elevation="3"
                    class="mx-auto mt-2 mt-sm-0 pt-3"
                  >
                    <v-img
                      class="white--text align-end mx-3"
                      height="150px"
                      :src="imageUrl + plan.image"
                    ></v-img>
                    <v-layout
                      justify-center
                      class="primary--text font-weight-medium text-body-1 mt-4"
                    >
                      {{ plan.titleEn }}
                    </v-layout>
                    <v-layout justify-end>
                      <v-card-actions>
                        <v-checkbox v-model="plan.assigned"> </v-checkbox>
                      </v-card-actions>
                    </v-layout>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
            <v-row justify="end" class="mt-6">
              <v-form ref="form" v-model="valid">
                <v-btn
                  :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
                  class="primary--text me-3 text-capitalize"
                  color="secondary"
                  @click="saveDate()"
                  :disabled="!valid"
                  :loading="btnloading"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Add
                </v-btn>
              </v-form>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import { requiredValidator } from "@/core/methods/validations";
export default Vue.extend({
  props: {
    celebrity: {
      require: true
    }
  },

  methods: {
    getAllCelebrityPackages() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/celebrity/api/getAllCelebrityPackages.graphql")
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          let celebrityPackages = data.data.getAllPackages.packages;
          let celebritySelectedIds = this.celebrity.packages
            ? this.celebrity.packages.map(x => x._id)
            : new Array();
          this.packages = [];

          let activePacakges = celebrityPackages.filter(x => x.active);

          activePacakges.forEach(element => {
            if (!celebritySelectedIds.includes(element._id)) {
              this.packages.push(element);
            }
          });
        });
    },
    assignPackage() {
      this.getAllCelebrityPackages();
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    saveDate() {
      let seletedPackage = this.packages
        .filter(x => x.assigned)
        .map(p => p._id);
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/assignPackageToCelebrity.graphql"),
          variables: {
            celebrityId: this.celebrity._id,
            packages: seletedPackage
          }
        })
        .then(() => {
          this.btnloading = false;
          this.showDialog = false;
          this.packages = [];
          this.$root.$emit("updategetCelebrityById");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    }
  },

  data() {
    return {
      loading: true,
      requiredValidator,
      valid: false,
      btnloading: false,
      showDialog: false,
      imageUrl: process.env.VUE_APP_BASE_URL
    };
  }
});
</script>
