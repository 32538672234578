<template>
  <div>
    <v-btn
      @click="exportExcel"
      width="150"
      color="secondary"
      class="text_white--text text-capitalize"
    >
      <v-icon color="text_white" size="17" class="me-1">fa-file-export</v-icon
      >Export
    </v-btn>
<!--  -->
    <div  v-show="false"> 
      <v-data-table
        id="printOutward"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="stockListAll"
      >
        <template v-slot:[`item.quantity`]="{ item }">
          <div v-if="item.ingredient">
            {{ parseFloat(item.outward / 1000).toFixed(3) }}&nbsp;{{
              itemQuantityUnit(item.ingredient)
            }}
          </div>
          <div v-else>
            {{ parseFloat(item.outward / 1000).toFixed(3) }}
          </div>
        </template> 
        <template v-slot:[`item.totalPrice`]="{ item }">
          {{ parseFloat(item.totalPrice).toFixed(3) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Utils } from "../../../utils/utils";
export default {
  props: {
    fDate: {
      require: true
    },
    stockList: {
      require: true
    },
    tDate: {
      require: true
    },
    headers: {
      require: true
    }
  },
  // watch: {
  //   stockList: {
  //     handler() {
  //       this.getAllStocks();
  //     },
  //     deep: true,
  //   },
  // },
  created() {},
  methods: {
    exportExcel() {
      this.loading = true;
      this.getAllStocks();
    },
    itemQuantityUnit(item) {
      let unit = "";
      if (item.quantityUnit === "GRAMS") unit = "KG";
      else if (item.quantityUnit === "ML") unit = "L";
      return unit;
    },
    getAllStocks() {
      const fDateTime = moment(`${this.fDate}`, "YYYY-MM-DD").format();
      let modifiedFromDate = new Date(fDateTime);
      const tDateTime = moment(`${this.tDate}`, "YYYY-MM-DD").format();
      let modifiedToDate = new Date(tDateTime);
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllStocks.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            stockType: "outward",
            fromDate: moment(modifiedFromDate).format("MM-DD-YYYY"),
            toDate: moment(modifiedToDate).format("MM-DD-YYYY")
          }
        })
        .refetch({
          stockType: "outward",
          fromDate: moment(modifiedFromDate).format("MM-DD-YYYY"),
          toDate: moment(modifiedToDate).format("MM-DD-YYYY"),
          limit: 0,
          skip: 0
        })
        .then(data => {
          if (data.data.getAllStocks) {
            this.stockListAll = data.data.getAllStocks.stock;
            
            setTimeout(() => {
              Utils.htmlToExcel("printOutward", "Ingredient", false);
            }, 100);
            this.loading = false;
          }
        })
        .catch(error => {
          this.loading = false;
          console.lo(error);
        });
    }
  },
  data() {
    return {
      loading: true,
      stockListAll: []
    };
  }
};
</script>
