<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0">
        <h2 class="primary--text text-h5 font-weight-bold ms-3">
          Region
        </h2>
        <!-- <v-spacer></v-spacer> -->
        <v-layout justify-end class="me-3">
          <ExportRegion class="mr-3 mt-n3  " :blockList="itemsWithSno"/>  
          <CreateRegion
            v-if="hasRole(AdminUserPermissions.REGION_ADD)"
          ></CreateRegion>
        </v-layout>
      </v-row>
      <!-- <v-layout>
        
      </v-layout> -->

      <!-- <v-system-bar height="1" class="mt-2"></v-system-bar> -->
      <!-- {{ menuList }} -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="itemsWithSno"
        class="elevation-1 mt-10"
        :loading="loading"
        :server-items-length="totalItemsCount"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      >
        <template v-slot:item.actions="{ item }">
          <v-row>
            <!-- {{ item }} -->
            <UpdateRegion
              v-if="hasRole(AdminUserPermissions.REGION_EDIT)"
              :regionInfo="item"
            />
            <DeleteRegion
              v-if="hasRole(AdminUserPermissions.REGION_DELETE)"
              :region="item"
            />
          </v-row>
        </template>
        <template v-slot:item.active="{ item }">
          <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
          <v-icon v-show="item.active" color="secondary"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon v-show="!item.active" color="grey"
            >mdi-checkbox-blank-outline</v-icon
          >
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import CreateRegion from "../components/CreateRegion.vue";
import DeleteRegion from "../components/DeleteRegion.vue";
import UpdateRegion from "../components/UpdateRegion.vue";
import ExportRegion from "@/customer/components/Export/ExportRegion.vue";


export default {
  components: {
    CreateRegion,
    DeleteRegion,
    UpdateRegion,
    ExportRegion
  },
  mounted() {
    this.$root.$on("updateRegions", () => {
      this.loading = true;
      this.$apollo.queries.getAllRegions.refetch({
        variables: this.variables
      });
    });
  },
  data() {
    return {
      loading: false,
      totalItemsCount: 25,
      regions: [],
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [
        {
          text: "#",
          sortable: false,
          value: "slNo",
          width: 120
        },
        { text: "Name English", value: "nameEn", width: 230 },
        { text: "Name Arabic", value: "nameAr", width: 230 },
        { text: "Status", value: "active", width: 230 },
        { text: "Action", value: "actions", sortable: false, width: 90 }
      ]
    };
  },
  apollo: {
    getAllRegions() {
      return {
        query: require("../api/getAllRegions.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.regions = data.getAllRegions.regions;
          this.loading = loading;
          this.totalItemsCount = data.getAllRegions.totalCount;
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.REGION_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  computed: {
    itemsWithSno() {
      return this.regions.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
};
</script>
