<template>
  <div class="mr-5">
    <v-btn icon color="secondary" @click="printQrCode">
      <v-icon color="primary" class="ms-2">mdi-download</v-icon>
    </v-btn>
  </div>
</template>
<script>
var QRCode = require("qrcode");
import { printQR } from "../methods/print_qr_code";
export default {
  methods: {
    printQrCode() {
      let siteUrl = "https://planetzero.sa/download";
      QRCode.toDataURL(siteUrl, function (err, url) {
        console.log(err);
        console.log(self.invoice);
        let qrCode = url;
        printQR(qrCode);
      });
    },
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>
