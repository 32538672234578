<template>
  <v-container fluid class="px-0">
    <v-form v-model="validProfile" ref="profileform" class="mt-8">
      <!-- <v-layout wrap>
          <v-flex xs4 sm1>
            <h4>ADMIN</h4>
          </v-flex>
          <v-flex xs4 sm1>
            <v-icon @click="edit">mdi-account-edit</v-icon>
          </v-flex>
        </v-layout> -->
      <!-- <v-layout justify-end wrap class="mt-16 mt-md-0">
          <v-flex xs12 md4 class="mt-5 mt-md-0">
            <app-user-image></app-user-image>
          </v-flex>
        </v-layout> -->
      <v-layout class="mt-5 mt-md-0" wrap>
        <v-flex xs12 sm4 md3>
          <v-text-field
            label="Firstname"
            v-model="firstnameEn"
            :rules="[requiredValidator('First Name')]"
            solo
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md3 class="ms-sm-10">
          <v-text-field
            label="Lastname"
            v-model="lastnameEn"
            :rules="[requiredValidator('Last Name')]"
            solo
            dense
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md3>
          <v-text-field
            label="Email Id"
            v-model="email"
            @click="doesEmailExist = false"
            :rules="[
              requiredValidator('email'),
              emailValidator(),
              this.doesEmailExist != true || 'email already exists.',
            ]"
            solo
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md3 class="ms-sm-10">
          <v-text-field
            label="Mobile Number"
            v-model="phone"
            solo
            dense
            type="number"
            class="inputPhone"
            @click="doesPhoneExist = false"
            :rules="[
              requiredValidator('phone'),
              this.doesPhoneExist != true || 'phone already exists.',
            ]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout class="mt-5 mt-md-0" wrap>
        <v-flex xs12 sm4 md3>
          <v-text-field
            label="User Name"
            v-model="username"
            :rules="[requiredValidator('user name')]"
            solo
            dense
          ></v-text-field>
        </v-flex>
      </v-layout>
      <!-- <v-layout class="text-h6 font-weight-bold mt-6 primary--text">
        Language
      </v-layout>
      <v-layout wrap class="mt-5">
        <v-flex class="text-body-1 black--text" col-6 sm3 md2>
          Currently Active
        </v-flex>
        <v-flex col-6 sm4 md4>
          <v-radio-group v-model="column" column>
            <v-radio label="English" value="english"></v-radio>
            <v-radio label="Arabic" value="arabic"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout> -->
      <v-flex xs12 sm12 md10 lg9 class="pe-2">
        <v-row justify="end">
          <v-btn
            color="secondary"
            :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
            class=" text-capitalize mt-4 font-weight-bold"
            :loading="btnProfileLoading"
            :disabled="!validProfile"
            @click="updateProfile"
            >Update</v-btn
          >
        </v-row>
      </v-flex>
    </v-form>

    <!-- <h3 class="primary--text">Language</h3>
      <v-layout class="mt-4 ms-16">
        <v-flex md2>
          <h4>Currently Active</h4>
        </v-flex>
        <v-flex class="mt-n4">
          <v-radio-group row>
            <v-radio label="English" value="english"></v-radio>
            <v-radio label="Arabic" value="arabic"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout> -->
    <v-form
      @submit="updatePassword()"
      v-model="valid"
      ref="formPassword"
      class="mt-10"
    >
      <h3 class="text-h6 font-weight-bold">Change Password</h3>

      <v-layout class="mt-4" wrap>
        <v-flex xs12 sm4 md3>
          <v-text-field
            label="Current Password"
            v-model="currentPassword"
            :rules="[requiredValidator('Current Password')]"
            :append-icon="
              show3 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
            "
            :type="show3 ? 'text' : 'password'"
            name="input-10-1"
            @click:append="show3 = !show3"
            solo
            dense
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm4 md3>
          <v-text-field
            label="New Password"
            v-model="newPassword"
            :rules="[
              requiredValidator('New Password'),
              minLengthValidator('password', 8),
            ]"
            :append-icon="
              show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
            "
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            @click:append="show1 = !show1"
            solo
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4 md3 class="ms-sm-10">
          <v-text-field
            label="Confirm Password"
            v-model="confirmPassword"
            solo
            dense
            :append-icon="
              show2 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
            "
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            @click:append="show2 = !show2"
            :rules="[confirmPasswordValidator(newPassword)]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout wrap class="mt-8">
        <v-flex xs12 sm12 md10 lg9 class="pe-2">
          <v-row justify="end">
            <v-btn
              color="secondary"
              :width="$vuetify.breakpoint.smAndUp ? 150 : 100"
              class="text-capitalize text-end font-weight-bold"
              :loading="btnPasswordLoading"
              @click="updatePassword"
              :disabled="!valid"
              >Update</v-btn
            >
          </v-row>
        </v-flex>
      </v-layout>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackbarColor"
      >{{ text }}</v-snackbar
    >
  </v-container>
</template>


<script>
//import UserImage from "../components/UserImage";
import {
  requiredValidator,
  emailValidator,
} from "../../core/methods/validations";
import {
  confirmPasswordValidator,
  minLengthValidator,
} from "../../core/methods/validations";
export default {
  components: {
    //   "app-user-image": UserImage
  },

  data() {
    return {
      confirmPasswordValidator: confirmPasswordValidator,
      minLengthValidator: minLengthValidator,
      requiredValidator: requiredValidator,
      emailValidator: emailValidator,
      doesPhoneExist: false,
      doesEmailExist: false,
      btnPasswordLoading: false,
      btnProfileLoading: false,
      btnloading: false,
      dialog: false,
      snackbarColor: "success",
      showDialog: false,
      show1: false,
      validProfile: true,
      show2: false,
      show3: false,
      passwordChangeErrorAlert: false,
      successAlert: false,
      btnLoading: false,
      firstnameEn: "",
      lastnameEn: "",
      username: "",
      phone: "",
      email: "",
      snackbar: false,
      text: "",
      valid: true,
      userId: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      column: "",
    };
  },
  apollo: {
    getUser() {
      return {
        query: require("../api/getUser.graphql"),
        result({ data, loading }) {
          this.firstnameEn = data.getUser.firstnameEn;
          this.lastnameEn = data.getUser.lastnameEn;
          this.phone = data.getUser.phone;
          this.email = data.getUser.email;
          this.username = data.getUser.username;
          this.userId = data.getUser._id;
          this.loading = loading;
          // this.driverList = this.driverList.map((items, index) => ({
          //   ...items,
          //   index: index + 1,
          // }));
        },
      };
    },
  },
  methods: {
    edit() {
      console.log("Edit");
    },
    updateProfile() {
      this.btnProfileLoading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateUser.graphql"),
          variables: {
            id: this.userId,
            firstnameEn: this.firstnameEn,
            lastnameEn: this.lastnameEn,
            username: this.username,
            email: this.email,
            phone: this.phone,
          },
        })
        .then(() => {
          this.text = "Profile details updated";
          this.snackbar = true;
          this.btnProfileLoading = false;
          this.snackbarColor = "success";
          this.dialog = false;
        })
        .catch((error) => {
          this.btnProfileLoading = false;
          // debugger;
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: EMAIL_ALREADY_EXIST") {
              this.doesEmailExist = true;
            }
            if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            }
            console.error(error.graphQLErrors);
          }
          console.log(error);
        });
    },
    updatePassword() {
      this.btnPasswordLoading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updatePassword.graphql"),
          variables: {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          },
        })
        .then(() => {
          this.text = "Password updated";
          this.snackbar = true;
          this.snackbarColor = "success";
          this.btnPasswordLoading = false;
          this.dialog = false;
          this.$refs.formPassword.reset();
        })
        .catch((error) => {
          this.snackbarColor = "error";
          this.text = "Check your current password.";
          this.currentPassword = "";
          this.snackbar = true;
          this.btnPasswordLoading = false;
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>


<style>
.inputPhone input[type="number"] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.update_btn {
  border: 2px solid #00acca;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
}
</style>