<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="black--text text-capitalize font-weight-bold"
          color="secondary"
          @click="openDialogBox"
          >+ Add</v-btn
        >
      </template>
      <v-card class="ma-0 pa-4" color="pop_bg">
        <v-card-text>
          <v-row>
            <span class="black--text text-h6 ms-2 mt-3"> Add Package</span>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="close" class="mt-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider class="mt-5"></v-divider>
          <v-form class="" v-model="sectionOneValid" ref="formOne">
            <div v-show="section === 1" class="pa-sm-8">
              <v-layout wrap class="mb-5">
                <h4
                  class="font-weight-bold text-body-1 black--text mt-3 mt-md-0"
                >
                  1. Basic information
                </h4>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout wrap class="mt-6 mt-sm-0">
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Plan Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="titleEn"
                    placeholder="Plan Name"
                    :rules="[requiredValidator('plan name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md2 class="ms-md-16">
                  <h4 class="text-body-2 black--text">Show Order</h4>
                </v-flex>
                <v-flex xs12 sm8 md2 class="mt-3 mt-md-0">
                  <v-text-field
                    type="number"
                    v-model="showOrder"
                    @keypress="isNumber"
                    placeholder="Order"
                    min="0"
                    :rules="[requiredValidator('order')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Plan Name Arabic</h4>
                </v-flex>
                <v-flex xs12 sm8 md4 class="mt-2 mt-sm-0">
                  <v-text-field
                    v-model="titleAr"
                    placeholder="Plan Name Arabic"
                    :rules="[requiredValidator('plan name in arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md4 class="ms-md-16">
                  <v-checkbox
                    label="Enable Set Meal "
                    v-model="isSetMealEnabled"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Image</h4>
                </v-flex>
                <v-flex xs12 sm8 md6>
                  <input
                    type="file"
                    accept="image/*"
                    @change="onFileSelected($event)"
                    class="mt-2 mt-sm-0"
                  />
                </v-flex>
                <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                  {{ snackText }}
                </v-snackbar>
              </v-layout>
              <v-layout class="mt-6" wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Description</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-textarea
                    solo
                    name="input-7-4"
                    label="Description"
                    value=""
                    v-model="descriptionEn"
                    :rules="[requiredValidator('description ')]"
                    class="mt-2 mt-sm-0"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-3 offset-md-0 class="ms-md-16">
                  <v-textarea
                    solo
                    name="input-7-4"
                    value=""
                    label="Description Arabic"
                    v-model="descriptionAr"
                    :rules="[requiredValidator('description in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-6" wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Meal Info</h4>
                </v-flex>
                <v-flex xs6 sm4 md4 class="mt-5 mt-sm-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >Protien</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="Protein"
                        value=""
                        @keypress="isNumber"
                        dense
                        min="0"
                        type="number"
                        v-model="protein"
                        :rules="[requiredValidator('protein')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 sm4 md4 class="ms-4 ms-sm-0 ms-md-16 mt-5 mt-sm-0">
                  <v-layout wrap>
                    <v-flex xs6 sm4 md4 class="text-body-2 black--text"
                      >Carbs</v-flex
                    >
                    <v-flex xs6 sm5 md8>
                      <v-text-field
                        solo
                        label="Carbs"
                        dense
                        @keypress="isNumber"
                        min="0"
                        value=""
                        type="number"
                        v-model="carbs"
                        :rules="[requiredValidator('carbs')]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start>
                <v-flex xs12 sm3 md2 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Start Date</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        :value="formatDate"
                        dense
                        append-icon="fa-calendar-alt black--text"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      :min="new Date() | moment('YYYY-MM-DD')"
                      v-model="startDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <!--  -->

              <!-- <v-divider></v-divider>    -->
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h4 class="text-body-2 black--text">Diet plan</h4>
                </v-flex>
                <v-flex xs12 sm8 md10 class="mt-n3">
                  <v-radio-group v-model="dietPlan" :mandatory="true" row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        lg="4"
                        v-for="item in allDietPlans"
                        :key="item._id"
                      >
                        <v-radio
                          :label="item.nameEn"
                          :value="item._id"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <div class="d-flex justify-end">
                <v-btn
                  :disabled="!sectionOneValid"
                  @click="goToSectionTwo()"
                  class="primary"
                  >Next</v-btn
                >
              </div>
            </div>
          </v-form>
          <v-form class="" v-model="sectionTwoValid" ref="formTwo">
            <div v-show="section === 2" class="pt-5  pt-sm-8 ">
              <!-- Days Choices -->
              <v-layout class="" wrap>
                <h4 class="font-weight-bold text-body-1 black--text">
                  2. Days Choices
                </h4>
                <v-spacer></v-spacer>
                <PackageChoicesForm :availableChoices="availableChoices" />
              </v-layout>
              <!-- <h4 class="mt-10 mt-md-10">Added Choices</h4> -->
              <PackageChoiceTable
                class="mt-4"
                :availableChoices="availableChoices"
                :allCategories="allCategories"
              >
              </PackageChoiceTable>
              <v-system-bar height="1"></v-system-bar>
              <div class="d-flex justify-space-between mt-3">
                <v-btn @click="section = 1">Back</v-btn>
                <v-btn
                  :disabled="!availableChoices.length"
                  @click="section = 3"
                  class="primary"
                  >Next</v-btn
                >
              </div>
            </div>
          </v-form>
          <v-form class="" v-model="sectionThreeValid" ref="formThree">
            <div v-show="section === 3">
              <!-- Add meals -->

              <v-layout class="mt-4" wrap>
                <h4 class="font-weight-bold text-body-1 black--text">
                  3. Add meals to the plan
                </h4>
                <v-spacer></v-spacer>
              </v-layout>

              <v-row class="ms-n14 ms-sm-0">
                <v-tabs
                  class="mt-12"
                  background-color="secondary"
                  slider-size="3"
                  :show-arrows="true"
                  v-model="menuCategoryIndex"
                  center-active
                >
                  <div
                    v-for="category in allCategories.filter(x => x.hasMeal)"
                    :key="category._id"
                    :value="category._id"
                  >
                    <v-tab
                      class="
                      text-caption text-sm-body-2 mt-3
                      black--text
                      text-capitalize
                    "
                      >{{ category.nameEn }}</v-tab
                    >
                  </div>
                </v-tabs>
              </v-row>

              <div
                v-for="(category, index) in allCategories.filter(
                  x => x.hasMeal
                )"
                :key="category._id"
              >
                <PackageMeals
                  v-show="menuCategoryIndex == index"
                  :noOfPackageWeek="noOfPackageWeek"
                  :category="category"
                  :dietPlan="dietPlan"
                  @updateCategory="updateCategory"
                  :customNutrient="getNutrients(category)"
                />
              </div>
              <div class="d-flex justify-space-between mt-3">
                <v-btn @click="section = 2">Back</v-btn>
                <v-btn
                  color="secondary"
                  class="black--text text-capitalize"
                  :width="$vuetify.breakpoint.xs ? 200 : 300"
                  @click="createPackage()"
                  :loading="btnloading"
                  >Create Package</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>

<script>
import PackageChoiceTable from "../components/PackageChoiceTable.vue";
import PackageChoicesForm from "../components/PackageChoicesForm.vue";
import PackageMeals from "../components/PackageMeals.vue";
// import PackageDefaultItems from "../components/PackageDefaultItems.vue";
import { requiredValidator, positive } from "../../core/methods/validations";
// import IngredientsDislike from "../components/IngredientsDislike.vue";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";

export default {
  components: {
    PackageChoiceTable: PackageChoiceTable,
    PackageChoicesForm,
    // PackageDefaultItems,
    PackageMeals
    // IngredientsDislike
  },

  // apollo: {
  //   getAllDietPlans() {
  //     return {
  //       query: require("../diet-plan/api/getAllDietPlans.graphql"),
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.allDietPlans = data.getAllDietPlans.filter(x => x.active);
  //       }
  //     };
  //   }
  // },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../../settings/api/getAppConfigurations.graphql"),
        result({ data }) {
          let packageCycleDay = data.getAppConfigurations.packageCycleDay;
          console.log("packageCycleDay");
          console.log(packageCycleDay);
          if (packageCycleDay) {
            this.noOfPackageWeek = packageCycleDay / 7;
          } else {
            this.noOfPackageWeek = 1;
          }
        }
      };
    }
  },

  data() {
    return {
      noOfPackageWeek: 1,
      menu2: false,
      startDate: "",
      section: 1,
      snack: false,
      isKetoDiet: false,
      snackColor: "",
      componentKey: 0,
      tab: "",
      protein: 100,
      carbs: 100,
      snackText: "",
      requiredValidator: requiredValidator,
      positive,
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
      image: null,
      active: true,
      showOrder: null,
      allCategories: [],
      count: 0,
      weekDay: null,
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      selectedCategory: null,
      tempItems: [],
      tempDefaultMenu: [],
      menuCategoryIndex: null,
      defaultMenu: [],
      availableChoices: [],
      show_dialog_box: false,
      menuItemsLoading: false,
      menuItemsSearchKey: "",
      menuItemSearcList: [],
      awaitingSearch: false,
      btnloading: false,
      valid: true,

      sectionOneValid: true,
      sectionTwoValid: true,
      sectionThreeValid: true,

      errors: [],
      snackbar: false,
      text: "",
      allDietPlans: [],
      dietPlan: "",
      isCelebrityPackage: false,
      customNutrients: [],
      dislikeIngredientsList: [],
      isSetMealEnabled:false
    };
  },
  computed: {
    formatDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    }
  },
  watch: {
    availableChoices: {
      handler() {
        this.allCategories.forEach(category => {
          let hasMeal = false;

          let choices = this.availableChoices;
          choices.forEach(choice => {
            choice.mealChoice.forEach(mealChoice => {
              mealChoice.dayChoices.forEach(dayChoice => {
                let containCategory = dayChoice.mealCount.find(
                  x => x._id == category._id
                );
                if (containCategory) {
                  if (parseInt(containCategory.count) > 0) {
                    hasMeal = true;
                  }
                }
              });
            });
          });
          category.hasMeal = hasMeal;
        });
      },
      deep: true
    },
    show_dialog_box: {
      handler() {
        this.section = 1;
      }
    }
  },
  created() {
    this.getAllCategories();
  },
  methods: {
    getAllDietPlans() {
      this.$apollo
        .watchQuery({
          query: require("../diet-plan/api/getAllDietPlans.graphql")
        })
        .refetch({})
        .then(data => {
          if (data.data) {
            let result = data.data.getAllDietPlans;
            if (result.length) this.allDietPlans = result.filter(x => x.active);
          }
        });
    },
    openDialogBox() {
      this.getAllDietPlans();
    },

    goToSectionTwo() {
      if (!this.image) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please choose an image";
        return;
      } else {
        this.section = 2;
      }
      // this.section = 2;
    },
    dislikeIngredients(data) {
      this.dislikeIngredientsList = data;
    },
    getAllCategories() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/menu-packages/api/getAllCategories.graphql"),
          variables: { limit: 0, skip: 0 }
        })
        .refetch({
          variables: { limit: 0, skip: 0 }
        })
        .then(data => {
          this.loading = false;

          let allCategories = data.data.getAllCategories.category;

          let daysItem = [];
          this.listOfDays.forEach(day => {
            daysItem.push({
              weekDay: day,
              menuItem: ""
            });
          });

          allCategories.forEach(category => {
            category.count = 0;
            category.hasMeal = false;

            let packageMenuWeek = [];
            for (let index = 0; index < this.noOfPackageWeek; index++) {
              let weekCategories = {
                categoryInfo: {
                  daysItem: daysItem
                },
                count: index
              };
              packageMenuWeek.push(weekCategories);
            }
            category.packageMenuWeek = packageMenuWeek;

            category.daysItem = JSON.parse(JSON.stringify(daysItem));
          });
          this.allCategories = JSON.parse(
            JSON.stringify(allCategories.filter(x => x.active))
          );
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateCategory(category) {
      let allCategories = this.allCategories;
      var foundIndex = allCategories.findIndex(x => x._id == category._id);
      allCategories[foundIndex] = category;
      this.allCategories = allCategories;
    },
    getNutrients(category) {
      let newNutrients = new Object();
      let data = this.customNutrients.find(x => x.menuCategory == category._id);
      if (!data) {
        newNutrients.menuCategory = category._id;
        newNutrients.protein = "0";
        newNutrients.fat = "0";
        newNutrients.carbs = "0";
        newNutrients.calories = "0";
        this.customNutrients.push(newNutrients);
      }
      return this.customNutrients;
    },

    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      this.image = selectedFile;
    },

    createPackage() {
      try {
        if (!this.availableChoices.length) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Please add a choice";
          return;
        }
        // if (!this.dislikeIngredientsList.length) {
        //   this.snack = true;
        //   this.snackColor = "error";
        //   this.snackText = "Please add dislike ingredients";
        //   return;
        // }
        debugger;
        this.btnloading = true;
        //  let defaultMenuInputs = new Array();
        //   let packageMenuInputs = new Array();
        let mealsCount = new Array();

        let packageMenus = new Array();
        let packageDefaultMenus = new Array();

        this.allCategories.forEach(category => {
          if (category.hasMeal) {
            category.packageMenuWeek.forEach(packageMenuWeek => {
              let daysItems = new Array();
              let daysDefaultItems = new Array();

              packageMenuWeek.categoryInfo.daysItem.forEach(item => {
                let daysItem = {
                  weekDay: item.weekDay,
                  menuItem: item.menuItem.map(x => x._id)
                };
                daysItems.push(daysItem);
              });

              packageMenuWeek.categoryInfo.daysItem.forEach(item => {
                let daysItem = {
                  weekDay: item.weekDay,
                  menuItem: item.defaultMenu.map(x => x._id)
                };
                daysDefaultItems.push(daysItem);
              });

              let weekMenu = {
                weekNo: packageMenuWeek.count + 1,
                daysItem: daysItems,
                menuCategory: category._id
              };
              let weekDefaultMenu = {
                weekNo: packageMenuWeek.count + 1,
                daysItem: daysItems,
                menuCategory: category._id
              };
              packageMenus.push(weekMenu);
              packageDefaultMenus.push(weekDefaultMenu);
            });
          }
        });

        let meanInfo = {
          protein: this.protein.toString(),
          carbs: this.carbs.toString()
        };

        let availableChoices = new Array();

        this.availableChoices.forEach(element => {
          let availableDays = element.availableDays.flatMap(x => x.day);
          let mealChoices = new Array();

          element.mealChoice.forEach(mealChoice => {
            let dayChoices = new Array();
            mealChoice.dayChoices.forEach(dayChoice => {
              let mealCounts = new Array();
              dayChoice.mealCount.forEach(element => {
                if (parseInt(element.count) > 0) {
                  let mealCount = {
                    count: parseInt(element.count),
                    category: element._id
                  };
                  mealCounts.push(mealCount);
                }
              });
              let dayChoiceItem = {
                day: dayChoice.day,
                mealCount: mealCounts
              };
              dayChoices.push(dayChoiceItem);
            });
            mealChoices.push({
              price: parseFloat(mealChoice.price),
              strikeThroughPrice: parseFloat(mealChoice.strikeThroughPrice),
              variantNameEn: mealChoice.variantNameEn,
              variantNameAr: mealChoice.variantNameAr,
              dayChoices: dayChoices
            });
          });

          let avail = {
            availableDays: availableDays,
            availableNumOfDays: element.availableNumOfDays,
            choiceNameAr: element.choiceNameAr,
            choiceNameEn: element.choiceNameEn,
            mealChoice: mealChoices
          };
          availableChoices.push(avail);
        });

        this.$apollo
          .mutate({
            mutation: require("@/menu-packages/api/createPackages.graphql"),
            variables: {
              titleEn: this.titleEn,
              titleAr: this.titleAr,
              descriptionEn: this.descriptionEn,
              descriptionAr: this.descriptionAr,
              image: this.image,
              active: this.active,
              isCelebrityPackage: this.isCelebrityPackage,
              showOrder: parseInt(this.showOrder),
              defaultMenu: packageDefaultMenus,
              packageMenu: packageMenus,
              availableChoices: availableChoices,
              mealsCount: mealsCount,
              meanInfo: meanInfo,
              dietPlan: this.dietPlan,
              customNutrients: this.customNutrients,
              dislikeIngredients: this.dislikeIngredientsList,
              startDate: this.startDate,
              isSetMealEnabled: this.isSetMealEnabled
            },
            update: (store, { data: { createPackages } }) => {
              try {
                const query = {
                  query: require("@/menu-packages/api/getAllPackages.graphql")
                };

                let allPackages = store.readQuery(query);

                allPackages.getAllPackages.packages.unshift(createPackages); //push to top
                store.writeQuery({
                  ...query,
                  data: allPackages
                });
                this.text = "Package Added";
                this.snackbar = true;
              } catch (e) {
                // console.log(e);
                console.log("Something bad happend- PackageForm.vue");
              }
              this.$refs.formOne.reset();
              this.$refs.formTwo.reset();
              this.$refs.formThree.reset();
              this.btnloading = false;
              this.defaultMenu = [];
              this.availableChoices = [];
              this.show_dialog_box = false;
            }
          })
          .catch(error => {
            // this.defaultMenu = [];
            //this.availableChoices = [];
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
              // console.log(error);
            }
            this.btnloading = false;
            this.snackText = "Something went wrong";
            this.snack = true;
            this.snackColor = "error";
          });
      } catch (error) {
        this.btnloading = false;
      }
    },
    close() {
      this.show_dialog_box = false;
    }
  }
};
</script>
