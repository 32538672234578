<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h3 class="primary--text text-h5 font-weight-bold ms-2 mb-6">Drivers</h3>
    </v-row>

    <v-row align="center" class="mt-n4 mt-md-0">
      <v-col cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="Name.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="1" class="mb-4 mb-sm-0">
        <DriverCreateForm v-if="hasRole(AdminUserPermissions.DRIVER_ADD)" />
      </v-col>
      <v-col cols="1" class="mb-4 mb-sm-0">
        <AuditTrialLog class="ms-16 ms-md-4 mt-n6" auditType="DRIVER_LOG" />
      </v-col>
    </v-row>
     <v-layout wrap justify-end>
      <ExportDriver :headers="headers" :driverList="driverList" />
    </v-layout> 
    <div class="">
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="driverList"
        class="elevation-1 mt-1"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      >
        <template v-slot:item.user="{ item }">
          <v-row v-if="item.user">
            {{ item.user.firstnameEn }} {{ item.user.lastnameEn }}
          </v-row>
        </template>
        <template v-slot:item.isActive="{ item }">
          <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
          <v-icon v-show="item.isActive" color="secondary"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon v-show="!item.isActive" color="grey"
            >mdi-checkbox-blank-outline</v-icon
          >
        </template>
        <template v-slot:item.morningShift="{ item }">
          <div v-if="item.morningShift.zone.length > 0">
            <span v-for="zone in item.morningShift.zone" :key="zone._id">
              {{ zone.zoneName }}
            </span>
          </div>
          <div v-if="item.eveningShift.zone.length > 0">
            <span v-for="zone in item.eveningShift.zone" :key="zone._id">
              {{ zone.zoneName }}
            </span>
          </div>
        </template>
        <template v-slot:item.eveningShift="{ item }">
          <div v-if="item.morningShift.zone.length > 0">Morning</div>
          <div v-if="item.eveningShift.zone.length > 0">Evening</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <!-- <ReferralTransations :user="item.user" isUserTable="true" /> -->
            <DriverUpdateForm
              v-if="hasRole(AdminUserPermissions.DRIVER_EDIT)"
              :driverInfo="item"
              class="ms-2"
            />
            <DriverDelete
              v-if="hasRole(AdminUserPermissions.DRIVER_DELETE)"
              :driver="item"
              :variables="variables"
            />
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import DriverCreateForm from "../components/DriverCreateForm.vue";
import DriverUpdateForm from "../components/DriverUpdateForm.vue";
import DriverDelete from "../components/DriverDeleteForm.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import ExportDriver from "../components/ExportDriver.vue";
// import ReferralTransations from "@/referral-transactions/components/ReferralTransations.vue";

export default {
  components: {
    DriverUpdateForm,
    DriverDelete,
    AuditTrialLog,
    DriverCreateForm,
    ExportDriver
    // ReferralTransations,
  },
  mounted() {
    this.$root.$on("refetchDriver", () => {
      this.$apollo.queries.getAllDrivers.refetch({
        variables: this.variables,
      });
    });
  },
  apollo: {
    getAllDrivers() {
      return {
        query: require("../api/getAllDrivers.graphql"),
        variables: {
          limit: 10000,
          skip: 0,
          searchKey: this.search,
        },
        result({ data, loading }) {
          this.loading = loading;
          this.driverList = data.getAllDrivers.drivers;
          this.driverList = this.driverList.map((items, index) => ({
            ...items,
            index: index + 1,
          }));
        },
      };
    },
  },

  data() {
    return {
      variables: {
        limit: 25,
        skip: 0,
        searchKey: "",
      },
      search: "",

      driverList: [],
      loading: true,
      headers: [
        {
          text: "Sl No",
          value: "index",
        },
        { text: "Name", value: "user", width: 120 },
        { text: "Phone", value: "user.phone" },
        { text: "Is Active", value: "isActive" },
        { text: "Assigned Zone", value: "morningShift" },
        { text: "Shift", value: "eveningShift" },
        { text: "Action", value: "actions", sortable: false, width: 100 },
      ],
      driver: {
        firstzoneName: "",
        lastzoneName: "",
        password: "",
        phone: "",
      },
    };
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DRIVER_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },

  methods: {
    runSearch() {
      this.$apollo.queries.getAllDrivers.refetch({
        limit: 25,
        skip: 0,
        searchKey: this.search,
      });
    },
    clearSearch() {
      this.search = "";
      this.$apollo.queries.getAllDrivers.refetch({
        limit: 25,
        skip: 0,
        searchKey: "",
      });
    },
  },
};
</script>