<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class=" text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Add Area </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="createArea()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout>
                <v-flex xs12 sm4 md12>
                  <v-autocomplete
                    item-text="nameEn"
                    v-model="region"
                    item-value="_id"
                    :items="regions"
                    :rules="[requiredValidator('region ')]"
                    label="Select Region"
                    dense
                    attach
                    solo
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs12 sm4 md12>
                  <Geofence
                    v-model="geoFenceCordinatesOutput"
                    :geoFenceCordinates="geoFenceCordinates"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-3 mt-sm-0 ml-3" wrap>
                <v-flex xs12 sm4 md1>
                  <h4 class="text-body-2 black--text">Area</h4>
                </v-flex>
                <v-flex xs12 sm7 md4 class="mt-5 mt-md-0">
                  <v-text-field
                    label="Area Name"
                    v-model="nameEn"
                    :rules="[requiredValidator('Area Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md2 class="ml-5">
                  <h4 class="text-body-2 black--text">Area (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm7 md4>
                  <v-text-field
                    label="Area Name Arabic"
                    v-model="nameAr"
                    :rules="[requiredValidator('Area Name Arabic')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout justify-center>
                <v-flex xs8 md2 class="mt-8"> </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n16">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class=" text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createArea"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      right
      top
      :color="snackColor"
      >{{ text }}</v-snackbar
    >
  </v-container>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
import Geofence from "@/region/components/Geofence.vue";
export default {
  components: {
    Geofence
  },
  apollo: {
    getAllRegions() {
      return {
        query: require("@/region/api/getAllRegions.graphql"),
        variables: {
          skip: 0,
          limit: 0
        },
        result({ data, loading }) {
          this.loading = loading;
          this.regions = data.getAllRegions.regions;
        }
      };
    }
  },
  data() {
    return {
      requiredValidator,
      regions: [],
      geoFenceCordinatesOutput: [],
      geoFenceCordinates: [],
      dialog: false,
      btnloading: false,
      addedArea: [],
      nameEn: "",
      nameAr: "",
      snackbar: false,
      snackColor: "",
      region: "",
      text: "",
      valid: true
    };
  },
  props: {
    area: {
      required: true
    }
  },
  methods: {
    createArea() {
      if (this.geoFenceCordinatesOutput.length == 0) {
        this.text = "Please plot area in map.";
        this.snackColor = "error";
        this.snackbar = true;
        return true;
      }
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../api/createArea.graphql"),
          variables: {
            nameEn: this.nameEn,
            nameAr: this.nameAr,
            geofencing: this.geoFenceCordinatesOutput[0],
            region: this.region
          },
          update: (store, { data: { createArea } }) => {
            try {
              const query = {
                query: require("../api/getAllAreas.graphql")
              };

              let allAreas = store.readQuery(query);
              allAreas.getAllAreas.areas.unshift(createArea); //push to top
              store.writeQuery({
                ...query,
                data: allAreas
              });
              this.text = "Area Added";
              this.snackbar = true;
              this.snackColor = "success";
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          console.log(error);
          if (error.graphQLErrors[0].message === "ALREADY_EXIST") {
            this.text = "Area already exist";
            this.snackColor = "error";
            this.snackbar = true;
          }
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
