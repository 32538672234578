<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="primary--text text-h6 font-weight-bold mt-8">Tutorials</h2>
    <app-tutorial-form></app-tutorial-form>
  </v-container>
</template>
<script>
import TutorialForm from "../components/TutorialForm.vue";
export default {
  components: {
    "app-tutorial-form": TutorialForm,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SETTINGS_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
};
</script>