<template>
  <div>
    <v-layout>
      <ExportAccount :accountType="'credit_note'" :headers="headers" />
    </v-layout>
    <v-data-table :headers="headers" :items="accountList" class="elevation-1 mt-10" :mobile-breakpoint="0"
      :loading="loading" :options.sync="options" :items-per-page="25" :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:[`item.siNo`]="{ item }">
        {{
            accountList
              .map(function (x) {
                return x._id;
              })
              .indexOf(item._id) + 1 + variables.skip
        }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        <router-link v-if="item.user" :to="{
          name: 'CustomerDetails',
          params: { customerId: item.customer },
          query: { customerId: item.customer }
        }" class="mx-auto">
          {{ capitalizeFirstLetter(item.user.firstnameEn) }}
          {{ capitalizeFirstLetter(item.user.lastnameEn) }}
        </router-link>
      </template>
      <template v-slot:[`item.contact`]="{ item }">
        {{ item.user.phone }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount }}
      </template>
      <template v-slot:[`item.taxes`]="{ item }">
        {{ item.tax }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ item.total }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <InvoiceDownload :invoice="item" type="refund" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import ExportAccount from "./ExportAccount.vue";

import InvoiceDownload from "../components/InvoiceDownload.vue";
export default {
  components: {
    InvoiceDownload,
    ExportAccount
  },
  data: () => ({
    accountList: [],
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo"
      },
      { text: "Customer Name", value: "customerName" },
      { text: "Contact", value: "contact" },
      { text: "Date and Time", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Taxes", value: "taxes" },
      { text: "Total ", value: "total" },
      { text: "Action ", value: "action" }
    ],
    options: {
      itemsPerPage: 25,
      page: 1
    },
    variables: {
      limit: 25,
      skip: 0
    },
    totalItemsCount: 0
  }),
  created() {
    this.getAllCreditTransaction();
  },
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;

          this.getAllCreditTransaction();
        }
      },
      deep: true
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss a");
    },
    getAllCreditTransaction() {
      this.$apollo
        .query({
          query: require("../api/getAllCreditNoteTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.accountList =
              data.data.getAllCreditNoteTransactions.transactionList;
            this.totalItemsCount =
              data.data.getAllCreditNoteTransactions.totalCount;
            this.loading = false;
          }
        });
    }
  }
};
</script>
