<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h2 class="primary--text text-h5 font-weight-bold ms-4">Plans</h2>
      <!-- <v-spacer></v-spacer> -->
      <v-layout justify-end class="pe-3">
        <app-package-form v-if="hasRole(AdminUserPermissions.PLAN_ADD)"></app-package-form>
        <AuditTrialLog class="ml-2" auditType="PLANS_LOG" />
      </v-layout>
    </v-row>

    <v-layout wrap class="mt-5">
      <app-package-card></app-package-card>
    </v-layout>
  </v-container>
</template>
<script>
import PackageCard from "../components/PackageCard.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
import PackageForm from "../components/PackageForm.vue";
export default {
  components: {
    "app-package-card": PackageCard,
    AuditTrialLog,
    "app-package-form": PackageForm,
  },
  created(){
    if (!this.hasRole(this.AdminUserPermissions.PLAN_VIEW)) {
      this.$router.push({
        name: "Error404",
      });}
  }
};
</script>