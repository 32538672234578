<template>
  <v-container>
    <v-progress-linear
      v-show="this.$apollo.queries.getAllPackages.loading"
    ></v-progress-linear>
    <v-row class="mt-n4 mt-sm-0 px-0">
      <v-col
        v-for="packages in packagesList"
        :key="packages.id"
        cols="12"
        sm="4"
        lg="3"
      >
        <v-card max-width="300" elevation="3" class="mx-auto mt-2 mt-sm-0 pt-3">
          <v-img
            class="white--text align-end mx-3"
            height="150px"
            :src="imageUrl + packages.image"
          ></v-img>
          <v-layout
            justify-center
            class="primary--text font-weight-medium text-body-1 mt-4"
          >
            {{ packages.titleEn }}
          </v-layout>
          <v-layout justify-end>
            <v-card-actions>
              <v-icon v-show="packages.active" color="secondary"
                >mdi-checkbox-marked</v-icon
              >
              <v-icon v-show="!packages.active" color="grey"
                >mdi-checkbox-blank-outline</v-icon
              >
              <PackageUpdateForm v-if="hasRole(AdminUserPermissions.PLAN_EDIT)" :package_id="packages._id" />
              <PackageDelete v-if="hasRole(AdminUserPermissions.PLAN_DELETE)" :package_id="packages._id" />
            </v-card-actions>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template> 
<script>
import PackageUpdateForm from "../components/PackageUpdateForm.vue";
import PackageDelete from "../components/PackageDelete.vue";
export default {
  components: {  PackageUpdateForm, PackageDelete },
  data: () => ({
    imageUrl: process.env.VUE_APP_BASE_URL,
    dialog: false,
    packagesList: [],
  }),
  mounted() {
    this.$root.$on("updatePackage", () => {
      this.loading = true;
      this.$apollo.queries.getAllPackages.refetch({});
    });
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("../api/getAllPackages.graphql"),
        result({ data }) {
          this.packagesList = data.getAllPackages.packages;
        },
      };
    },
  },
  methods: {},
};
</script>

