<template>
  <div v-if="offerList != null">
     <v-layout justify-end  class="mt-4 ">
      <ExportOffer :headers="headers" :offerList="offerList"/> 
    </v-layout> 
    <v-data-table
      :headers="headers"
      :items="offerList"
      class="elevation-1 mt-2" 
      :mobile-breakpoint="0"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.active="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="secondary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ new Date(parseInt(item.startDate)) | moment("DD/MM/YYYY") }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ new Date(parseInt(item.endDate)) | moment("DD/MM/YYYY") }}
      </template>
      <template v-slot:item.promoCode="{ item }">
        {{ item.promoCode.promoCode }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <OfferUpdateForm v-if="hasRole(AdminUserPermissions.OFFER_EDIT)" :offer="item" />
          <deleteOffer v-if="hasRole(AdminUserPermissions.OFFER_DELETE)" :offer="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import OfferUpdateForm from "../components/OfferUpdateForm";
import deleteOffer from "../components/OfferDeleteForm";
import ExportOffer from "../components/ExportOffer.vue";

export default {
  components: {
    OfferUpdateForm,
    deleteOffer,
    ExportOffer
  },
  mounted() {
    this.$root.$on("updateOffer", () => {
      this.getAllOffers();
    });
  },

  data: () => ({
    dialog: false,
    offerList: [],
    loading: true,
    headers: [
      {
        text: "Offer Title (English)",
        value: "promoCode.titleEn",
      },
      { text: "Offer Title (Arabic)", value: "promoCode.titleAr" },
      { text: "Active", value: "active" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate" },

      { text: "Promo Code", value: "promoCode.promoCode" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.getAllOffers();
  },
  methods: {
    
    getAllOffers() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllOffers.graphql"),
        })
        .refetch()
        .then((data) => {
          this.loading = false;
          if (data.data.getAllOffers) {
            this.offerList = data.data.getAllOffers.offers;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

