import { jsPDF } from "jspdf";
import moment from "moment";
//import { TIME_ZONE } from "../../utils/utils";

const defaultFontSize = 9;
const lineHeight = Math.floor(defaultFontSize / 2);
let currentY = 2 * lineHeight;

const pageWidth = 58;
const xCenter = Math.floor(pageWidth / 2);
const pageHeight = 38;
const offset = 1;

let doc = new jsPDF("l", "mm", [pageHeight, pageWidth], true);

const print_meal_sticker = (customers, fDate) => {
  initialiseDoc();
  debugger;
  if (customers != null) {
    customers.forEach(customer => {
      //printTitle(customer);
      printBody(customer, fDate);
      gotoNextPage();
    });
  }
  saveDoc();
};
const initialiseDoc = () => {
  doc = new jsPDF("l", "mm", [pageHeight, pageWidth], true);
  doc.setFontSize(defaultFontSize);
  doc.setFont("times", "bold");
};

const printBody = (meal, fDate) => {
  gotoNextLine(2);
  console.log(meal);
  doc.rect(offset, offset, pageWidth - offset * 2, pageHeight - offset * 2);
  doc.setTextColor("#000000");
  let xoffset = 30;
  xoffset = Math.ceil("Birthday Cake".length);
  doc.addImage(
    "img/meal.png",
    "png",
    xCenter - xoffset - 2,
    currentY - 3,
    defaultFontSize / 3,
    defaultFontSize / 3
  );
  doc.text("Birthday Cake", xCenter, currentY, "center");

  doc.addImage("img/logo.png", "png", pageWidth - 16, pageHeight - 12, 14, 10);
  gotoNextLine(1);
  // doc.text(meal.customerName, xCenter, currentY, 'center');
  // gotoNextLine(1);
  // doc.setFontSize(defaultFontSize - 1);
  doc.text("" + getDate(fDate), xCenter, currentY, "center");
  doc.setFontSize(defaultFontSize);
};
const getDate = date => {
  let converted = moment(date);
  return converted.format("MMM DD YYYY");
};

const gotoNextLine = numberOfLines => {
  if (numberOfLines == null) numberOfLines = 1;
  currentY += numberOfLines * lineHeight;
};
const gotoNextPage = () => {
  doc.addPage();
  currentY = 2 * lineHeight;
};
const saveDoc = () => {
  doc.save("BirtdaySticker.pdf");
};
export { print_meal_sticker };
