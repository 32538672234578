<template>
  <v-container class="bg px-0" fluid>
    <v-layout class="mt-2 ms-sm-n4" wrap>
      <v-flex xs6 sm4 md3 class="ms-sm-5 me-5">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              label="Date"
              readonly
              solo
              append-icon="fa-calendar-alt secondary--text" 
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            color="secondary"
            v-model="date"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs3 sm5 md2 class="ms-sm-5 ms-md-0">
        <v-btn
          class="ms-sm-5 text_white--text text-capitalize"
          color="solid"
          width="150"
          @click="searchDelivery"
          >Search
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="ms-3 ms-sm-0" wrap>
      <v-flex
        xs12
        sm12
        md8
        lg7
        class="ms-sm-5 ms-md-0 mb-sm-5 mb-sm-0"
        v-if="reportGeneratedDate"
      >
        <v-row>
          <v-flex xs12 sm5 md5 lg6>
            <label class="ms-md-3">
              Report generated on
              {{
                new Date(parseInt(reportGeneratedDate))
                  | moment("MMM DD YYYY HH:mm a")
              }}
            </label>
          </v-flex>
          <v-flex
            xs12
            sm4
            md3
            lg6
            class="mt-3 mt-sm-0 ms-md-7 ms-lg-0 ms-xl-n6"
          >
            <v-btn
             v-if="hasRole(AdminUserPermissions.GENERATE_ITEMS)"
              color="solid"
              class="text_white--text text-capitalize"
              :loading="btnloading"
              width="150"
              @click="isAllowToGenerateReport"
              >ReGenerate
            </v-btn>
          </v-flex>
        </v-row>
      </v-flex>
      <v-flex xs12 sm12 md8 lg7 class="ms-sm-5 ms-md-0 mb-sm-5 mb-sm-0" v-else>
        <v-row>
          <v-flex xs12 sm5 md5 lg6>
            <label class="ms-md-4">Report not generated</label>
          </v-flex>
          <v-flex
            xs12
            sm4
            md3
            lg6
            class="mt-3 mt-sm-0 ms-md-7 ms-lg-0 ms-xl-n6"
          >
            <v-btn
             v-if="hasRole(AdminUserPermissions.GENERATE_ITEMS)"
              color="solid"
              class="text_white--text text-capitalize"
              :loading="btnloading"
              @click="isAllowToGenerateReport"
              width="150"
              >Generate
            </v-btn>
          </v-flex>
        </v-row>
      </v-flex>
      <!-- </v-layout>
    <v-layout> -->
      <v-flex
        xs12
        sm12
        lg5
        xl5
        class="ms-n3 ms-sm-0 mt-12 mt-sm-4 mt-lg-0 text-end d-flex"
      >
        <v-row justify="end">
          <PrintIngredients
            :disabled="reportList.length == 0"
            :ingredientsList="reportList"
            :headers="headers"
            :meanInfos="meanInfos"
          />
        </v-row>
      </v-flex>
    </v-layout>

    <v-data-table
      class="mt-6"
      v-if="reportList"
      :mobile-breakpoint="0"
      :headers="headers"
      :items="reportList"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:body="props">
        <tbody>
          <tr v-for="item in props.items" :key="item.id">
            <td v-for="(header, index) in headers" :key="index">
              <span v-if="index == 0">{{ item.slNo }} </span>
              <span v-else-if="index == 1"> {{ item.name }}</span>
              <span v-else-if="header.value == 'total'"
                >{{ getTotalValue(item) }}
              </span>

              <span v-else>{{ getCellValue(item, header) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog v-model="showErrorDialog" max-width="500">
      <v-card class="pa-3">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n4"
            >Meals Log</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showErrorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <ul class="mt-5" v-if="errorLog.length > 0">
          <li v-for="item in errorLog" :key="item">{{ item }}</li>
        </ul>
        <div v-else>All meals are generated successfully!</div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="error">{{
      text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import PrintIngredients from "./PrintIngredients.vue";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  components: { PrintIngredients },
  data() {
    return {
      btnloading: false,
      snackbar: false,
      text: "",
      menu: false,
      loading: false,
      errorLog: [],
      showErrorDialog: false,
      counter: 0,
      reportList: [],
      reportGeneratedDate: [],
      meanInfos: [],
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      nowDate: new Date().toISOString().slice(0, 10),
      headers: [],
      customerList: [],
    };
  },
  created() {
    this.getIngredientDetailsByDate();
  },

  methods: {
    searchDelivery() {
      this.getIngredientDetailsByDate();
    },
    increment() {
      let counter = this.counter;
      this.counter = this.counter + 1;
      return counter;
    },
    getIngredientDetailsByDate() {
      this.reportList = [];
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo
        .watchQuery({
          query: require("../api/getIngredientDetailsByDate.graphql"),
          variables: { date: modifiedDate },
        })
        .refetch({ variables: { date: modifiedDate } })
        .then((data) => {
          this.loading = false;
          this.reportGeneratedDate = "";
          if (data.data.getIngredientDetailsByDate) {
            this.meanInfos = data.data.getIngredientDetailsByDate.meanInfos;

            this.reportList = data.data.getIngredientDetailsByDate.ingredients;
            if (this.reportList) {
              this.reportList = this.reportList.map((d, index) => ({
                ...d,
                slNo: index + 1,
              }));
            }
            this.updateHeaders();

            if (data.data.getIngredientDetailsByDate.generatedDate) {
              this.reportGeneratedDate =
                data.data.getIngredientDetailsByDate.generatedDate;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    getTotalValue(item) {
      let sum = item.meansInfo
        .map((x) => x.quantity)
        .reduce((a, b) => a + b, 0);
      return sum.toFixed(3);
    },
    getCellValue(item, header) {
      let meansInfo = item.meansInfo;
      let info = meansInfo.find(
        (x) => x.protein == header.protein && x.carbs == header.carbs
      );
      return info.quantity.toFixed(3);
    },
    updateHeaders() {
      let header = [
        { text: "Sl.No", value: "slNo", printWidth: "10mm" },
        { text: "Ingredient Name", value: "itemName", printWidth: "" },
      ];
      if (this.meanInfos)
        this.meanInfos.forEach((element) => {
          let item = {
            text: `P${element.protein}/C${element.carbs}`,
            value: `${element.protein}/${element.carbs}`,
            protein: element.protein,
            carbs: element.carbs,
            printWidth: "80px",
          };
          header.push(item);
        });
      let total = { text: "Total", value: "total", printWidth: "50px" };
      header.push(total);
      this.headers = header;
    },

    generateReport() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../../delivery/api/generateDeliveryReport.graphql"),
          variables: {
            date: this.date,
          },
        })
        .then((data) => {
          this.btnloading = false;
          if (data.data.generateDeliveryReport.errors) {
            this.errorLog = data.data.generateDeliveryReport.errors;
            this.showErrorDialog = true;
          }
          this.searchDelivery();
        })
        .catch((error) => {
          this.searchDelivery();
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    isAllowToGenerateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        this.$apollo
          .query({
            query: require("../../delivery/api/isAllowToGenerateReport.graphql"),
            variables: {
              date: this.date,
            },
          })
          .then((data) => {
            this.btnloading = false;
            if (data.data.isAllowToGenerateReport) {
              this.generateReport();
            } else {
              this.text = `Please generate report upto ${this.date}`;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
  },
};
</script>