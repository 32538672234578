<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class=" text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Add Shift</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="createShift()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap class="mt-3 mt-sm-0">
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Zone</h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-md-0">
                  <v-autocomplete
                    item-text="zoneName"
                    v-model="zoneName"
                    item-value="_id"
                    :items="zoneList"
                    :rules="[requiredValidator('zone ')]"
                    label="Select Zone"
                    dense
                    attach
                    solo
                  >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">
                          Search for zone...
                        </h5>
                      </v-flex>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text">Morning Driver</h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-2 mt-sm-0">
                  <v-autocomplete
                    item-text="user.firstnameEn"
                    v-model="mngDriver"
                    item-value="_id"
                    :items="driverList"
                    label="Select Driver"
                    dense
                    attach
                    solo
                  >
                    <template slot="item" slot-scope="data"
                      >{{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}</template
                    >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">No driver found</h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="text-body-2 black--text pe-md-2">
                    Evening Driver
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md5 class="mt-2 mt-sm-0">
                  <v-autocomplete
                    item-text="user.firstnameEn"
                    item-value="_id"
                    v-model="evngDriver"
                    :items="driverList"
                    label="Select Driver"
                    dense
                    attach
                    solo
                  >
                    <template slot="item" slot-scope="data"
                      >{{ data.item.user.firstnameEn }}
                      {{ data.item.user.lastnameEn }}</template
                    >
                    <template slot="no-data">
                      <v-flex xs12 md12 class="mt-2 mb-2">
                        <h5 class="font-weight-medium ml-4">No driver found</h5>
                      </v-flex>
                    </template></v-autocomplete
                  >
                </v-flex>
                <v-snackbar
                  v-model="snack"
                  :timeout="3000"
                  :color="snackColor"
                  >{{ snackText }}</v-snackbar
                >
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n4 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class=" text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              @click="createShift"
              :loading="btnloading"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      requiredValidator: requiredValidator,
      isDaysToggled: false,
      dialog: false,
      btnloading: false,
      areaList: [],
      driverList: [],
      zoneList: [],
      areaName: "",
      zoneName: "",
      mngDriver: "",
      evngDriver: "",
      snackbar: false,
      text: "",
      block: "",
      valid: true,
      variables: {
        limit: 25,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$root.$on("updateZone", () => {
      this.loading = true;
      this.$apollo.queries.getAllZones.refetch({
        variables: this.variables,
      });
    });
  },
  props: {
    shift: {
      required: true,
    },
  },

  apollo: {
    getAllDrivers() {
      return {
        query: require("@/reports/api/getAllDrivers.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.driverList = data.getAllDrivers.drivers;
        },
      };
    },
    getAllZones() {
      return {
        query: require("../api/getAllZones.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.zoneList = data.getAllZones.zones;
        },
      };
    },
  },
  methods: {
    createShift() {
      this.btnloading = true;
      if (
        (this.mngDriver === "" || this.mngDriver === undefined) &&
        (this.evngDriver === "" || this.evngDriver === undefined)
      ) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please Select any shift";
        return;
      } else {
        this.$apollo
          .mutate({
            mutation: require("../api/createShift.graphql"),
            variables: {
              zone: this.zoneName,
              mngDriver: this.mngDriver,
              evngDriver: this.evngDriver,
            },
          })
          .then(() => {
            this.text = "Shift Added";
            this.btnloading = false;
            this.$refs.form.reset();
            this.snackbar = true;
            this.dialog = false;
            this.$root.$emit("updateShift");
          })
          .catch((error) => {
            this.btnloading = false;
            console.log(error);
          });
      }
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>