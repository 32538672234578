<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="mt-n3 white--text text-capitalize btn_fnt"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        :id="exportAccount"
        :headers="head"
        :items="accountList"
        :items-per-page="-1"
        class="elevation-1 mt-10"
        :mobile-breakpoint="0"
        :loading="loading"
      >
        <template v-slot:[`item.siNo`]="{ item }">
          {{
            accountList
              .map(function(x) {
                return x._id;
              })
              .indexOf(item._id) + 1
          }}
        </template>
        <template v-slot:[`item.customerName`]="{ item }">
        
            {{ capitalizeFirstLetter(item.user.firstnameEn) }}
            {{ capitalizeFirstLetter(item.user.lastnameEn) }}
         
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          {{ item.user.phone }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type }}
        </template>
        <template v-slot:[`item.payment`]="{ item }">
          {{ item.paymentMethod }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ dateFormat(new Date(parseInt(item.createdAt))) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ numberWithCommas(item.amount) }}
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          {{ numberWithCommas(item.discount) }}
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{ numberWithCommas(item.total) }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    accountType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      accountList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: [],
      exportAccount: ""
    };
  },
  created() {
    this.head = this.headers;
    if (this.accountType == "invoice") {
      this.head = this.headers.filter(x => x.value !== "action");
      this.exportAccount = "exportInvoice";
    } else {
      this.exportAccount = "exportCredit";
    }
  },
  methods: {
    getAllInvoiceTransaction() {
      this.$apollo
        .query({
          query: require("../api/getAllInvoiceTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.accountList =
              data.data.getAllInvoiceTransactions.transactionList;

            this.loading = false;
            setTimeout(() => {
              Utils.htmlToExcel("exportInvoice", "Invoice", false);
            }, 100);
          }
        });
    },
    getAllCreditTransaction() {
      this.$apollo
        .query({
          query: require("../api/getAllCreditNoteTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.accountList =
              data.data.getAllCreditNoteTransactions.transactionList;
            this.loading = false;
            setTimeout(() => {
              Utils.htmlToExcel("exportCredit", "Credit Note", false);
            }, 100);
          }
        });
    },
    exportExcel() {
      if (this.accountType == "invoice") {
        this.getAllInvoiceTransaction();
      } else if (this.accountType == "credit_note") {
        this.getAllCreditTransaction();
      }
    },
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss a'");
    },
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
