<template>
  <v-container>
    <v-btn icon color="secondary" @click="printInvoice">
      <v-icon color="primary" class="ms-2">mdi-download</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { printInvoice } from "../methods/a4_invoice";
var QRCode = require("qrcode");
//import moment from "moment";
export default {
  components: {},

  props: {
    invoice: {
      required: true
    },
    type: {
      required: true
    }
  },

  created() { },
  methods: {
    getTLVForValue(tagNum, tagValue) {
      let tagBuff = Buffer.from([tagNum], 'utf8');
      let tagValueLenBuff = Buffer.from([tagValue.length], 'utf8');
      let tagValueBuff = Buffer.from(tagValue, 'utf8');
      let bufsArray = [tagBuff, tagValueLenBuff, tagValueBuff]
      return Buffer.concat(bufsArray);
    },
    printInvoice() {
      let self = this;
      debugger

      let sellerNameBuf = this.getTLVForValue('1', this.invoice.companyInfo.name);
      let vatRegistrationNameBuf = this.getTLVForValue('2', this.invoice.companyInfo.vat);

      let timeFormatted = new Date(parseInt(this.invoice.createdAt)).toISOString()// toTimeString() //"2022-04-25T15:30:00Z"
      let timeStampBuf = this.getTLVForValue('3', timeFormatted);

      let invoiceTotalBuf = this.getTLVForValue('4', this.invoice.total.toFixed(2));
      let vatTotalBuf = this.getTLVForValue('5', this.invoice.tax.toFixed(2));

      let tagBufsArray = [sellerNameBuf, vatRegistrationNameBuf, timeStampBuf, invoiceTotalBuf, vatTotalBuf];
      let qrCodeBuf = Buffer.concat(tagBufsArray);
      let qrCodeB64 = qrCodeBuf.toString('base64');
      // let qrCodeString = "";

      // let qrCodeString = `Supplier Name : ${this.invoice.companyInfo.name}
      //     \nVAT Number : ${this.invoice.companyInfo.vat}
      //     \nCustomer Name : ${this.invoice.customerInfo.firstnameEn} ${this.invoice.customerInfo.lastnameEn
      //   }
      //     \nInvoice Date : ${}
      //     \nCreated Datetime : ${moment(
      //     new Date(parseInt(this.invoice.createdAt))
      //   ).format("DD MMM YYYY, h:mm:ss A")}
      //     \nTotal VAT : ${}
      //     \nTotal Amount Due : ${}

      //     `;
      QRCode.toDataURL(qrCodeB64, function (err, url) {
        console.log(err);
        console.log(self.invoice);
        self.invoice.qrCode = url;
        printInvoice(self.invoice, self.type);
      });
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
  },
  data() {
    return {
      valid: true,
      imageUrl: ""
    };
  }
};
</script>
