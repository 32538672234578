<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class=" text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Add Driver</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n8"
          @submit="createDriver()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap class="mt-5 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">First Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="First Name"
                    v-model="firstnameEn"
                    :rules="[requiredValidator('First Name')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="Last Name"
                    v-model="lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Phone</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    v-model="phone"
                    :counter="10"
                    label="Phone"
                    required
                    solo
                    dense
                    type="number"
                    @keypress="isNumber"
                    class="inputPhone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 10),
                      maxLengthValidator('phone', 10),
                      this.doesPhoneExist != true || 'phone already exists.',
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="Password"
                    v-model="password"
                    :rules="[
                      requiredValidator('password'),
                      minLengthValidator('password', 8),
                    ]"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap class="mb-4">
                <v-flex xs8 md7 offset-md-3>
                  <v-checkbox label="Is Active" color="secondary" v-model="isActive"></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3>
                  <h4 class="black--text text-body-2">
                    Max. Referral Amount(SR)
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    placeholder="Max. Referral Amount"
                    v-model="maxReferralAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('max. referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
              <!-- <v-layout wrap class="mt-n2 mt-sm-0">
                <v-flex xs12 md3 class="mt-1">
                  <h4 class="text-body-2 black--text">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm8 md7>
                  <v-text-field
                    label="Referred By"
                    v-model="referredBy"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="createDriver"
              :loading="btnloading"
              :disabled="!valid"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              class="
                mt-n12 mt-sm-n8 mt-md-0
                mb-3 mb-sm-0
                text-capitalize
              "
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackbarColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator,
} from "../../core/methods/validations";
// import { ValidationProvider } from "vee-validate";
export default {
  data() {
    return {
      show1: false,
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      doesPhoneExist: false,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      // referredBy: "",
      percentage: 0,
      maxReferralAmount: 0,
      btnloading: false,
      phone: "",
      password: "",
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      isActive: false,
    };
  },

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createDriver() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../api/createDriver.graphql"),
          variables: {
            firstnameEn: this.firstnameEn,
            lastnameEn: this.lastnameEn,
            phone: this.phone,
            password: this.password,
            isActive: this.isActive,
            percentage: parseFloat(this.percentage),
            maxReferralAmount: parseFloat(this.maxReferralAmount),
          },
        })
        .then(() => {
          this.text = "Driver Added";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.$root.$emit("refetchDriver");
          this.$refs.form.reset();
          this.dialog = false;
          this.btnloading = false;
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
            }
          }

          this.btnloading = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>