<template>
  <div class="bg">
    <v-layout>
      <AddCustomerDietitianAppoinment :customer_id="customer_id" />
      <!-- v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_ADD)" -->
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="appointmentList"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :mobile-breakpoint="0"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
       :item-class="(item) => item.isCancelled ? ' darken-1 red--text' : ' darken-1 black--text'" 
    >
      <template v-slot:item.name="{ item }">
        {{ item.customer.user.firstnameEn }}
        {{ item.customer.user.lastnameEn }}
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.height="{ item }">
        {{ parseFloat(item.height).toFixed(3) }}
      </template>
      <template v-slot:item.weight="{ item }">
        {{ parseFloat(item.weight).toFixed(3) }}
      </template>
      <template v-slot:item.fat="{ item }">
        {{ parseFloat(item.fat).toFixed(3) }}
      </template>
      <template v-slot:item.muscle="{ item }">
        {{ parseFloat(item.muscle).toFixed(3) }}
      </template>
      <template v-slot:item.targetCalories="{ item }">
        {{ parseFloat(item.targetCalories).toFixed(3) }}
      </template>
      <template v-slot:item.dietitian="{ item }">
        <span v-if="item.dietitian && item.dietitian.user">
          {{
            item.dietitian.user.firstnameEn[0].toUpperCase() +
              item.dietitian.user.firstnameEn.substring(1)
          }}
          {{
            item.dietitian.user.lastnameEn[0].toUpperCase() +
              item.dietitian.user.lastnameEn.substring(1)
          }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.appointmentTime="{ item }">
        <span v-if="item.appointmentDate">
          {{ new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY hh:mm A") }}
        </span>
      </template>

      <template v-slot:item.workoutTime="{ item }">
        <span v-if="item.workoutTime">
          {{ new Date(parseInt(item.workoutTime)) | moment("hh:mm:A") }}
        </span>
        <span v-else> - </span>
      </template>

      <template v-slot:item.sleepingTime="{ item }">
        <span v-if="item.sleepingTime">
          {{ new Date(parseInt(item.sleepingTime)) | moment("hh:mm:A") }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.fee="{ item }">
        <span v-if="item.fee">
          {{ numberWithCommas(item.fee) }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.tax="{ item }">
        <span v-if="item.tax">
          {{ numberWithCommas(item.tax) }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.total="{ item }">
        <span v-if="item.total">
          {{ numberWithCommas(item.total) }}
        </span>
        <span v-else> - </span>
      </template>
      <template v-slot:item.reason="{ item }">
        <span v-if="item.reasonToCancel"> 
          {{ item.reasonToCancel  }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row v-if="!item.isCancelled"> 
          <div v-if="isPaymentPending(item)">
            <RegenerateApptPayment
              v-if="hasRole(AdminUserPermissions.CUSTOMER_SALES_ORDER_UPDATE)"
              :appointment="item"
            />
          </div>
          <div v-else>
            <UpdateCustomerDietitianAppoinment
              v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_EDIT)"
              :appointment="item"
            />
          </div>
          <DeleteCustomerDietitianAppt
            v-if="hasRole(AdminUserPermissions.DIETITIAN_APPOINTMENT_DELETE)"
            :dietitianappointment="item"
          />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AddCustomerDietitianAppoinment from "../customerDietitian/AddCustomerDietitianAppoinment.vue";
import UpdateCustomerDietitianAppoinment from "../customerDietitian/UpdateCustomerDietitianAppoinment.vue";
import DeleteCustomerDietitianAppt from "../customerDietitian/DeleteCustomerDietitianAppt.vue";
import RegenerateApptPayment from "../customerDietitian/RegenerateApptPayment.vue";

export default {
  props: {
    customer_id: {
      required: true
    }
  },
  mounted() {
    this.$root.$on("updateDietitianAppointments", () => {
      this.loading = true;
      this.$apollo.queries.getAllAppoinments.refetch({
        variables: this.variables
      });
    });
  },
  components: {
    AddCustomerDietitianAppoinment,
    RegenerateApptPayment,
    UpdateCustomerDietitianAppoinment,
    DeleteCustomerDietitianAppt
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      dialog: false,
      editDialog: false,
      appointmentList: [],
      search: "",
      headers: [
        {
          text: "Appointment Date and Time",
          value: "appointmentTime",
          width: "175",
          sortable: false
        },
        { text: "Dietitian", value: "dietitian", width: "150" },
        { text: "Date", value: "createdDate", width: "150", sortable: false },
        { text: "Height", value: "height", sortable: false },
        { text: "Weight", value: "weight", sortable: false },
        { text: "Fat(%)", value: "fat", sortable: false },
        { text: "Muscle(%)", value: "muscle", sortable: false },
        { text: "Workout Time", value: "workoutTime", sortable: false },
        { text: "Sleeping Time", value: "sleepingTime", sortable: false },
        { text: "Target Calories", value: "targetCalories", sortable: false },
        {
          text: "Fees",
          value: "fee",
          width: 80,
          sortable: false
        },
        {
          text: "Tax",
          value: "tax",
          width: 80,
          sortable: false
        },
        {
          text: "Total",
          value: "total",
          width: 80,
          sortable: false
        },
        {
          text: "Payment Status",
          value: "payment_status",
          width: 120,
          sortable: false
        },
        { text: "Paid By", value: "paid_by", width: 90, sortable: false },
        { text: "Reason for cancel", value: "reason", width: 90, sortable: false },
        { text: "", value: "actions", sortable: false, width: "80" }
      ],
      tags: [],
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 25,
        page: 1
      },

      variables: {
        limit: 0,
        skip: 0,
        customerId: this.$route.query.customerId
      },
      loading: true
    };
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getAllAppoinments)
          this.$apollo.queries.getAllAppoinments.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 500,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage,
            customerId: this.$route.query.customerId
          });
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  apollo: {
    getAllAppoinments() {
      return {
        query: require("../../api/getAllAppoinments.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.appointmentList = data.getAllAppoinments.appointments;
          this.totalItemsCount = data.getAllAppoinments.totalCount;
        }
      };
    }
  },

  methods: {
    isPaymentPending(appt) {
      if (appt.payment_status == "PENDING" && appt.paymentUrl) {
        return true;
      }
      return false;
    },
    numberWithCommas(x) {
      if (!x) {
        return 0;
      }
      return parseFloat(x)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>

<style scoped></style>
