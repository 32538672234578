<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <h2 class="primary--text text-h6 font-weight-bold mt-8">
      Terms and Condition
    </h2>
    <app-terms-form></app-terms-form>
  </v-container>
</template>
<script>
import TermsForm from "../components/TermsForm.vue";
export default {
  components: {
    "app-terms-form": TermsForm,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SETTINGS_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
};
</script>