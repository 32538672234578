<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="170 "
        :loading="loading"
        class="white--text text-capitalize btn_fnt"
        color="secondary"
      >
        <v-icon small class="me-2">fa-file-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportZone"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="zoneList"
        class="elevation-1 "
      > 
       <template v-slot:[`item.areaDetails`]="{ item }">

        <div v-for="(areaItem, index) in item.areaDetails" :key="index">
          <span v-if="areaItem.areas">
            <span v-if="areaItem.areas.length > 0">
              <span v-if="areaItem.region">
                {{ areaItem.region.nameEn }}
                ( {{ areaItem.areas.map(x => x.nameEn).join(", ") }})
              </span>
            </span>
          </span>
        </div>
      </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    zoneList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
       headers: [
     {
        text: "Zone",
        value: "zoneName"
      },
      { text: "Area", value: "areaDetails" },
    ],
    };
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportZone", "Zone", false);

      this.loading = false;
    }
  }
};
</script>
