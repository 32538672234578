import { jsPDF } from "jspdf";

var pageWidth = 90;
var pageHeight = 90;

var start = 0;



var currentY = 0;
// var xCenter = Math.floor(pageWidth / 2);

let doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);

const printQR = (qrCode) => {
    doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);

    doc.addFont('Amiri-Regular-normal.ttf', 'Amiri-Regular', 'normal');




    doc.addImage(qrCode, "png", start - 5, currentY - 5, 100, 100);


    doc.save(`QrCode.pdf`);

};



export { printQR };
