<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          class="text-capitalize mb-5 ms-1"
          :disabled="hasUpcomingPackage"
          color="secondary"
          v-on="on"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon>Add
        </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="bg">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n2"
            >Add Sales Order</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="createSalesOrder()" v-model="valid" ref="form">
            <v-layout>
              <v-flex>
                <v-radio-group
                  v-model="pacakageSelectionMethod"
                  @change="onPackageModeChange"
                >
                  <v-radio value="PACKAGE" label="Choose From Packages">
                  </v-radio>
                  <!-- 
                  <v-radio value="CELEBRITY" label="Choose From Celebrity">
                  </v-radio> -->
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              class="mt-n2"
              v-if="pacakageSelectionMethod == 'CELEBRITY'"
            >
              <v-radio-group v-model="rdoCeleb">
                <div v-for="celebrity in celebrities" :key="celebrity._id">
                  <v-card width="300" v-if="celebrity.packages" class="mt-2">
                    <v-layout>
                      <v-flex md10>
                        <v-layout>
                          <v-flex xs12 sm8 md12>
                            <span v-if="celebrity.customer" class="ml-3 mt-3">
                              <span v-if="celebrity.customer.user">
                                <strong>
                                  {{ celebrity.customer.user.firstnameEn }}
                                  {{ celebrity.customer.user.lastnameEn }}
                                </strong>
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex xs12 sm8 md12>
                            <span v-if="celebrity.packages">
                              <div
                                v-for="packageItem in celebrity.packages"
                                :key="packageItem._id"
                              >
                                <div width="300" height="75">
                                  <v-layout class="mt-2 ml-2">
                                    <v-flex xs12 sm2>
                                      <v-radio
                                        @click="
                                          updateCelebityId(
                                            celebrity,
                                            packageItem
                                          )
                                        "
                                      >
                                      </v-radio>
                                    </v-flex>
                                    <v-flex>
                                      <strong>
                                        {{
                                          capitalizeFirstLetter(
                                            packageItem.titleEn
                                          )
                                        }}</strong
                                      >
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </div>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
              </v-radio-group>
            </v-layout>

            <v-layout
              wrap
              class="mt-n2"
              v-if="pacakageSelectionMethod == 'PACKAGE'"
            >
              <!-- {{ selectPackage }} -->
              <v-flex xs12 sm2 md2>
                <label class="font-weight-medium black--text text-body-2"
                  >Plan</label
                >
              </v-flex>

              <v-flex xs12 sm8 md8>
                <v-select
                  @change="onPackageSelect"
                  v-model="selectPackage"
                  :items="packagesList"
                  solo
                  dense
                  attach
                  :rules="[requiredValidator('plan')]"
                  item-value="_id"
                  item-text="titleEn"
                  chips
                  label="Plan"
                  return-object
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout v-if="selectPackage.availableChoices" wrap class="mt-2">
              <!-- {{ selectPackage.availableChoices }} -->
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text">Plan Choices</label>
              </v-flex>
              <v-flex xs12 sm8>
                <v-select
                  v-model="choice"
                  :items="selectPackage.availableChoices"
                  :rules="[requiredValidator('choices')]"
                  item-value="_id"
                  dense
                  solo
                  item-text="choiceNameEn"
                  attach
                  chips
                  return-object
                  label="Choices"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout
              v-for="(item, index) in choice.mealChoice"
              :key="item._id"
              wrap
              class="mb-5"
            >
              <v-flex xs12 sm2 v-if="index == 0">
                <label class="text-body-2 black--text">Meal Choices</label>
              </v-flex>
              <v-flex xs12 sm8 :offset-sm-2="index > 0">
                <v-card>
                  <v-radio-group v-model="mealChoice" @change="onChoiceChange">
                    <v-row class="text-h5 ml-4">
                      {{ item.variantNameEn }}
                    </v-row>
                    <v-row>
                      <v-col cols="2" sm="1" class="mt-1 ml-3">
                        <v-radio :value="item"></v-radio>
                      </v-col>
                      <v-col cols="10">
                        <div
                          v-for="(item, idxDayChoices) in item.dayChoices"
                          :key="idxDayChoices"
                        >
                          <v-divider v-if="idxDayChoices > 0"></v-divider>
                          <strong> {{ item.day }} </strong>

                          <v-row
                            v-for="meal in item.mealCount"
                            :key="meal._id"
                            class="mt-1 mb-1"
                          >
                            <div v-if="meal.category && meal.count > 0">
                              {{ meal.count }} {{ meal.category.nameEn }}
                            </div>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-card>
              </v-flex>
            </v-layout>
            <!-- <v-divider class="mb-6"></v-divider> -->

            <v-layout wrap v-if="choice">
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text">Available Days</label>
              </v-flex>

              <v-select
                v-model="availableDays"
                :items="listOfDays"
                item-text="day"
                item-value="day"
                attach
                return-object
                solo
                chips
                :rules="[requiredValidatorSelect('available days')]"
                label="Available Days"
                multiple
              ></v-select>
            </v-layout>
            <v-layout>
              <!-- <v-flex xs8 md2 class="mt-md-0">
              </v-flex> -->
              <v-flex>
                <v-tabs
                  class="mt-2"
                  background-color="bg"
                  v-model="tab"
                  center-active
                >
                  <div
                    v-for="day in availableDays"
                    :key="day.day"
                    :value="day.day"
                  >
                    <v-tab
                      ><label
                        class="
                          text-caption text-sm-body-2
                          black--text
                          text-capitalize
                        "
                        >{{ day.day }}</label
                      ></v-tab
                    >
                  </div>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="(day, index) in availableDays"
                    :key="index"
                  >
                    <v-card color="pop_bg">
                      <span v-show="false">{{ index }}</span>
                      <v-flex
                        xs12
                        sm5
                        class="text-body-2 black--text ms-sm-5"
                        v-for="(item, dayIndex) in day.allCategories"
                        :key="item._id"
                      >
                        {{ item.nameEn }}
                        <span v-show="false">{{ dayIndex }}</span>

                        <v-text-field
                          :label="item.nameEn + '(No.)'"
                          solo
                          type="number"
                          @keypress="isNumber"
                          v-model="item.count"
                          min="0"
                          dense
                        ></v-text-field>
                      </v-flex>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <!-- <v-select
                  v-model="choice.availableDays"
                  :items="choice.availableDays"
                  multiple
                  disabled
                  dense
                  attach
                  solo
                  label="Choices"
                  class="black--text"
                ></v-select> -->
              </v-flex>
            </v-layout>

            <v-layout wrap class="mt-3">
              <v-flex xs12 sm2 v-if="!hasActivePackage">
                <label class="black--text text-body-2">Start date</label>
              </v-flex>
              <!-- <v-flex xs8 md2 class="mt-md-0">
              </v-flex> -->
              <v-flex xs12 sm3 lg4 pe-lg-6 v-if="!hasActivePackage">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      dense
                      label="Start Date"
                      :value="formatDate"
                      append-icon="fa-calendar-alt black--text"
                      readonly
                      :rules="[requiredValidator('start date')]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    color="secondary"
                    v-model="start_date"
                    @input="menu1 = false"
                    :min="minDate"
                    :landscape="landscape"
                    :reactive="reactive"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm2 v-if="choice">
                <label class="black--text text-body-2">Package Days</label>
              </v-flex>

              <v-flex xs12 sm4 v-if="choice">
                <v-text-field
                  :value="choice.availableNumOfDays"
                  solo
                  disabled
                  dense
                >
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice">
              <v-flex xs12 sm2>
                <label class="black--text text-body-2">Additional Days</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <v-text-field v-model="additionalDays" solo 
                type="number"
                min="0"
                max="100"
                 dense
                 >
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text">Protein</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field
                    v-model="protein"
                    solo
                    label="protein"
                    :rules="[
                      requiredValidator('protein'),
                      positiveNumber('protein')
                    ]"
                    dense
                    type="number"
                    min="0"
                  >
                    <template v-slot:append>
                      <!-- <h4 class="mt-1 grey--text">SR</h4> -->
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 md2 v-if="choice">
                <h4 class="text-body-2 black--text">Carbs</h4>
              </v-flex>

              <v-flex xs12 sm4>
                <h4 class="text-body-2">
                  <v-text-field
                    v-model="carbs"
                    label="carbs"
                    type="number"
                    :rules="[
                      requiredValidator('carbs'),
                      positiveNumber('carbs')
                    ]"
                    min="0"
                    solo
                    dense
                  >
                    <template v-slot:append>
                      <!-- <h4 class="mt-1 grey--text">SR</h4> -->
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>
            </v-layout>
            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <label class="text-body-2 black--text">Package Price</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field
                    :value="
                      parseFloat(
                        mealChoice.price ? mealChoice.price : 0
                      ).toFixed(3)
                    "
                    solo
                    disabled
                    dense
                  >
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">SR</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 md2 v-if="choice">
                <h4 class="text-body-2 black--text">Discount</h4>
              </v-flex>

              <v-flex xs12 sm4>
                <h4 class="text-body-2">
                  <v-text-field
                    v-model="discount"
                    @change="onChoiceChange"
                    :rules="positiveNo"
                    type="number"
                    min="0"
                    solo
                    dense
                  >
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">SR</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice">
              <v-flex xs12 sm2>
                <label class="black--text text-body-2">Additional Price</label>
              </v-flex>

              <v-flex xs12 sm4 lg4 pe-sm-6>
                <v-text-field
                  v-model="additionalPrice"
                  @change="onChoiceChange"
                  :rules="positiveNo"
                  solo
                  type="number"
                  dense
                >
                  <template v-slot:append>
                    <h4 class="mt-1 grey--text">SR</h4>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm2 md2>
                <h4 class="text-body-2 black--text">
                  Tax ( {{ taxPercent }} %)
                </h4>
              </v-flex>

              <v-flex xs12 sm4>
                <h4 class="text-body-2">
                  <v-text-field v-model="taxPrice" solo dense :disabled="true">
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">SR</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>
            </v-layout>

            <v-layout v-if="choice" wrap>
              <v-flex xs12 sm2>
                <h4 class="text-body-2 black--text">Total Price</h4>
              </v-flex>

              <v-flex xs12 sm4 pe-sm-6>
                <h4 class="text-body-2">
                  <v-text-field
                    :value="netPrice"
                    type="number"
                    disabled
                    color="red"
                    solo
                    dense
                  >
                    <template v-slot:append>
                      <h4 class="mt-1 grey--text">SR</h4>
                    </template>
                  </v-text-field>
                </h4>
              </v-flex>

              <v-flex xs12 sm2 v-if="parseFloat(netPrice) > 0">
                <h4 class="text-body-2 black--text">Payment Type</h4>
              </v-flex>

              <v-flex xs12 sm4 v-if="parseFloat(netPrice) > 0">
                <v-select
                  solo
                  dense
                  :rules="[requiredValidatorSelect('payment method')]"
                  attach
                  item-text="text"
                  item-value="value"
                  v-model="paymentMethod"
                  :items="avalilablePaymentMethod"
                >
                </v-select>
              </v-flex>
            </v-layout>

            <div v-if="pacakageSelectionMethod">
              <v-layout wrap v-if="isNotAlreadyReferred()">
                <v-flex xs12 sm2>
                  <h4 class="text-body-2 black--text mt-md-2">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm4 pe-sm-6>
                  <v-text-field v-model="referredBy" solo dense> </v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <!-- <v-divider class="mb-6"></v-divider> -->

            <v-layout row class="mt-10" justify-end>
              <v-btn
                :width="$vuetify.breakpoint.xs ? 90 : 120"
                color="secondary"
                class="primary--text text-capitalize"
                @click="createSalesOrder()"
                :loading="btnloading"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackColor"
          >{{ text }}</v-snackbar
        >
        <!-- </v-container> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">CLOSE</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  requiredValidator,
  requiredValidatorSelect,
  positiveNumber
} from "../../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT, Utils } from "../../../utils/utils";

export default {
  components: {},
  props: {
    customer_id: {
      required: true
    },
    customer: {
      required: true
    },
    subscribedPackages: {
      required: true
    }
  },

  watch: {
    subscribedPackages: {
      handler() {
        this.getActivePackage();
      },
      deep: true
    },
    mealChoice: {
      handler() {
        this.packageCountListing();
      },
      deep: true
    },
    choice: {
      handler() {
        this.mealChoice = "";
        this.availableDays = [];
      },
      deep: true
    },

    selectPackage: {
      handler(item) {
        this.choice = "";
        this.mealChoice = "";
        this.availableDays = [];
        if (item.startDate) {
          let packageStartDate = moment(new Date(parseInt(item.startDate)));
          if (packageStartDate.isAfter(this.minDate)) {
            this.minDate = packageStartDate.format("YYYY-MM-DD");
          }
        } else {
          this.minDate = this.packageSelectionDate.format("YYYY-MM-DD");
        }
      },
      deep: true
    }
  },
  created() {
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_ONLINE)) {
      const data = this.paymentMethods[0];
      this.avalilablePaymentMethod.push(data);
    }
    // if (this.hasRole(this.AdminUserPermissions.PAYMENT_OFFLINE)) {
    //   const data = this.paymentMethods[1];
    //   this.avalilablePaymentMethod.push(data);
    // }
    if (this.hasRole(this.AdminUserPermissions.PAYMENT_FREE)) {
      const data = this.paymentMethods[1];
      this.avalilablePaymentMethod.push(data);
    }
    this.getActivePackage();
  },
  data() {
    return {
      //   nowDate: new Date().toISOString().slice(0,10),
      nowDate: moment()
        .add(3, "d")
        .toDate()
        .toISOString(),
      avalilablePaymentMethod: [],
      allCategories: [],
      celebrityId: "",
      paymentMethods: [
        {
          value: "ONLINE",
          text: "Online"
        },
        // {
        //   value: "OFFLINE",
        //   text: "Offline",
        // },
        {
          value: "FREE",
          text: "Free"
        }
      ],
      paymentMethod: "",
      availableDays: "",
      listOfDays: [],
      tab: "",
      date: new Date(),
      netPrice: 0,
      basePrice: 0,
      taxPrice: 0,
      landscape: false,
      discount: "",
      reactive: false,
      selectPackage: "",
      additionalPrice: "",
      additionalDays: "",
      choice: "",
      referredBy: "",
      mealChoice: "",
      start_date: "",
      menu1: false,
      activePackage: [],
      btnDisabled: false,
      snack: false,
      snackColor: "",
      snackText: "",
      hasActivePackage: false,
      hasUpcomingPackage: false,
      isValidIngredient: true,
      requiredValidator: requiredValidator,
      requiredValidatorSelect,
      positiveNumber,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      minDate: moment()
        .add(3, "day")
        .toDate()
        .toISOString(),
      text: "",
      pacakageSelectionMethod: "PACKAGE",
      celebrity: "",
      variables: {
        limit: 0,
        skip: 0
      },
      packagesList: [],
      celebrities: [],
      positiveNo: [v => v >= 0 || "Enter a valid discount"],
      taxPercent: 0,
      protein: "",
      carbs: ""
    };
  },
  //   created() {
  //     this.salesOrderTemp = JSON.parse(JSON.stringify(this.salesOrder));
  //   },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/menu-packages/api/getAllPackagesWithChoice.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          // debugger;
          this.packagesList = data.getAllPackages.packages.filter(
            x => x.active
          );
          // console.log(data.getAllIngredients);
        }
      };
    },
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          let allCategories = data.getAllCategories.category.filter(
            x => x.active == true
          );
          this.allCategories = JSON.parse(JSON.stringify(allCategories));
        }
      };
    },
    customerGetAllCelebrities() {
      return {
        query: require("./api/customerGetAllCelebrities.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.celebrities = data.customerGetAllCelebrities.celebrities;
        }
      };
    },
    getAppConfigurations() {
      return {
        query: require("@/accounts/api/getAppConfigurations.graphql"),
        result({ data }) {
          if (data.getAppConfigurations) {
            this.taxPercent = data.getAppConfigurations.taxPercent;
          }
        }
      };
    }
  },
  getMealSelectionDate() {
    return {
      query: require("./api/getMealSelectionDate.graphql"),
      variables: this.variables,
      result({ data, loading }) {
        this.loading = loading;

        let date = data.getMealSelectionDate.packageSelectionDate;
        this.packageSelectionDate = moment(new Date(parseInt(date)));
        if (this.packageSelectionDate.isAfter(this.minDate)) {
          this.minDate = this.packageSelectionDate.format("YYYY-MM-DD");
        }
      }
    };
  },
  methods: {
    onPackageSelect() {
      (this.choice = ""), (this.protein = this.selectPackage.meanInfo.protein);
      this.carbs = this.selectPackage.meanInfo.carbs;
    },
    packageCountListing() {
      // debugger;
      this.availableDays = [];
      if (this.choice && this.mealChoice) {
        this.allCategories.forEach(element => {
          element.count = 0;
        });
        this.listOfDays = [];

        let sunday = {
          day: "Sunday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let monday = {
          day: "Monday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let tuesday = {
          day: "Tuesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let wednesday = {
          day: "Wednesday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let thursday = {
          day: "Thursday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let friday = {
          day: "Friday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };
        let saturday = {
          day: "Saturday",
          allCategories: JSON.parse(JSON.stringify(this.allCategories))
        };

        this.availableChoices = new Array();
        // let packageInfo = this.selectPackage;
        this.choice.availableDays.forEach(day => {
          switch (day) {
            case "Sunday": {
              sunday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(sunday);
              break;
            }
            case "Monday": {
              monday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(monday);
              break;
            }
            case "Tuesday": {
              tuesday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(tuesday);
              break;
            }
            case "Wednesday": {
              wednesday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(wednesday);
              break;
            }
            case "Thursday": {
              thursday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(thursday);
              break;
            }
            case "Friday": {
              friday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(friday);
              break;
            }
            case "Saturday": {
              saturday.allCategories.forEach(category => {
                let dayChoice = this.mealChoice.dayChoices.find(
                  x => x.day == day
                );
                if (dayChoice) {
                  let dayCategory = dayChoice.mealCount.find(
                    x => x.category._id == category._id
                  );
                  if (dayCategory) {
                    category.count = dayCategory.count;
                  }
                }
              });
              this.availableDays.push(saturday);
              break;
            }
          }
        });
        this.listOfDays.push(sunday);
        this.listOfDays.push(monday);
        this.listOfDays.push(tuesday);
        this.listOfDays.push(wednesday);
        this.listOfDays.push(thursday);
        this.listOfDays.push(friday);
        this.listOfDays.push(saturday);
      }
    },
    onPackageModeChange() {
      this.celebrity = null;
      this.selectPackage = "";
      this.choice = "";
    },
    updateCelebityId(celebrity, packageItem) {
      this.celebrity = celebrity;
      this.selectPackage = packageItem;
    },
    isNotAlreadyReferred() {
      if (this.pacakageSelectionMethod == "CELEBRITY") {
        return false;
      }
      if (this.customer.referredByUser) {
        if (this.customer.isPackageSubscribed) {
          return true;
        }
        return false;
      }
      return true;
    },
    onChoiceChange() {
      if (this.mealChoice) {
        let price = parseFloat(this.mealChoice.price);
        let additionalPrice = parseFloat(
          this.additionalPrice ? this.additionalPrice : 0
        );
        let discount = this.discount ? parseFloat(this.discount) : 0;
        let discountedPrice = price - discount + additionalPrice;
        if (discountedPrice < 0) {
          discount = 0;
          this.discount = 0;
        }
        this.basePrice = (price - discount + additionalPrice).toFixed(3);
        let tax = (this.basePrice * this.taxPercent) / 100;
        this.taxPrice = tax.toFixed(2);
        let netPrice = parseFloat(this.basePrice) + tax;

        this.netPrice = netPrice.toFixed(2);
      }
    },

    getActivePackage() {
      let runningPackages = Utils.getActiveAndPendingCustomerPackages(
        this.subscribedPackages
      );
      this.currentPlanStatus(runningPackages);
    },

    currentPlanStatus(runningPackages) {
      this.hasActivePackage = false;
      this.hasUpcomingPackage = false;
      if (runningPackages.activePackage) {
        this.hasActivePackage = true;
      }
      if (runningPackages.upcomingPackage) {
        this.hasUpcomingPackage = true;
      }
    },
    close() {
      this.show_dialog_box = false;
      this.$refs.form.reset();
      this.selectPackage = "";
      this.start_date = "";
      this.choice = "";
    },
    createSalesOrder() {
      if (!this.valid) return false;

      let price = parseFloat(this.choice.price);
      let discount = this.discount ? parseFloat(this.discount) : 0;
      let netPrice = price - discount;
      if (this.pacakageSelectionMethod == "CELEBRITY") {
        if (this.celebrity) {
          this.referredBy = parseInt(
            this.celebrity.customer.user.userId
          ).toString(16);
        }
      }

      if (!this.selectPackage || !this.selectPackage._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select a package";
        return false;
      }

      if (!this.choice || !this.choice._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select a choice";
        return false;
      }

      if (!this.mealChoice || !this.mealChoice._id) {
        this.snackbar = true;
        this.snackColor = "error";
        this.text = "Please select meal choice";
        return false;
      }

      let mealData = JSON.parse(JSON.stringify(this.availableDays));

      let isValid = true;
      mealData.forEach(mealCount => {
        let hasMeal = false;
        mealCount.allCategories.forEach(category => {
          if (parseInt(category.count) > 0) {
            hasMeal = true;
          }
        });

        if (!hasMeal) {
          this.snackbar = true;
          this.snackColor = "error";
          this.text = "Please add atleast one meal on : " + mealCount.day;
          this.btnloading = false;
          isValid = false;
          return false;
        }
      });

      if (isValid) {
        let customerPackage = {};

        let dayChoices = new Array();
        this.availableDays.forEach(dayChoice => {
          let mealCounts = new Array();
          dayChoice.allCategories.forEach(element => {
            if (parseInt(element.count) > 0) {
              let mealCount = {
                count: parseInt(element.count),
                category: element._id
              };
              mealCounts.push(mealCount);
            }
          });
          let dayChoiceItem = {
            day: dayChoice.day,
            mealCount: mealCounts
          };
          dayChoices.push(dayChoiceItem);
        });

        customerPackage.dayChoices = dayChoices;
        let availableDays = this.availableDays.map(x => x.day);
        const subPackages = {
          package: this.selectPackage._id,
          start_date: this.start_date,
          selected_choice: this.choice._id,
          discount: discount,
          netPrice: netPrice,
          paymentMethod: this.paymentMethod,
          mealChoice: this.mealChoice._id,
          referralCode: this.referredBy,
          additionalDays: this.additionalDays
            ? parseInt(this.additionalDays)
            : 0,
          additionalPrice: this.additionalPrice
            ? parseFloat(this.additionalPrice)
            : 0,
          packageInfo: customerPackage,
          availableDays: availableDays,
          protein: parseFloat(this.protein),
          carbs: parseFloat(this.carbs)
        };

        this.btnloading = true;
        this.$apollo
          .mutate({
            mutation: require("../../api/adminCheckoutCustomerPackage.graphql"),
            variables: {
              customerId: this.customer_id,
              input: subPackages
            }
          })
          .then(data => {
            console.log(data);
            this.snackbar = true;
            this.text = "Customer sales order added";
            this.$root.$emit("updateGetCustomerById");
            this.snackColor = "success";
            this.$refs.form.reset();
            this.btnloading = false;
            this.close();
          })
          .catch(error => {
            this.snackbar = true;
            this.snackColor = "error";
            this.text = "Something went wrong";
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    }
  },
  computed: {
    formatDate() {
      return this.start_date ? moment(this.start_date).format(DATE_FORMAT) : "";
    }
  }
};
</script>
