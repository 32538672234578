var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mt-3",attrs:{"justify-end":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":"","lg2":""}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Requests")])]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":25,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.customer != null)?_c('span',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.appointmentTime",fn:function(ref){
var item = ref.item;
return [(item.appointmentTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.appointmentDate)),"MMM DD YYYY"))+" "+_vm._s(item.appointmentTime)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('updateAppointment',{attrs:{"appointment":item,"limit":_vm.variables.limit,"skip":_vm.variables.skip}})],1)]}}])}),_c('v-layout',{staticClass:"mt-10"},[_c('h2',{staticClass:"primary--text text-h5 font-weight-bold"},[_vm._v("Calendar View")])]),_c('v-row',{staticClass:"mt-6 ms-1"},[(_vm.loading == false)?_c('BookingCalender',{attrs:{"appoinments":_vm.customerList}}):_vm._e()],1),_c('app-closedAppointments-table',{attrs:{"customerList":_vm.customerList,"totalItemsCount":_vm.totalItemsCount,"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }