<template>
  <div>
    <!-- {{ faqList }} -->
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:item.active="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="secondary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <FaqUpdateForm v-if="hasRole(AdminUserPermissions.FAQ_EDIT)" :faq="item" />
          <FaqDelete v-if="hasRole(AdminUserPermissions.FAQ_DELETE)" :faq="item" />
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FaqDelete from "../components/FaqDelete";
import FaqUpdateForm from "../components/FaqUpdateForm";
export default {
  components: { FaqDelete, FaqUpdateForm },
  props: {
    faqList: {
      required: true,
    },
    totalItemsCount: {
      default: 25,
    },
    loading: {
      default: true,
    },
  },
  computed: {
    itemsWithSno() {
      if (this.faqList == null) return [];
      return this.faqList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  data() {
    return {
      variables: {
        limit: 25,
        skip: 0,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false,
        },
        { text: "Question", value: "question", width: 700, sortable: false },
        // { text: "Answer", value: "answer" },
        { text: "Active", value: "active", sortable: false },
        { text: "Show Order", value: "showOrder", sortable: false },
        { text: "Action", value: "actions", sortable: false, width: 90 },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  methods: {
    deleteItem(choice) {
      // console.log(item);
      const index = this.faqList.findIndex(
        (item) => item.choiceNameEn === choice.choiceNameEn
      );
      this.faqList.splice(index, 1);
      // console.log(index);
    },
  },
};
</script>