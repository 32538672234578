var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"gray1--text"},on),[_vm._v("mdi-eye")])]}}]),model:{value:(_vm.show_dialog_box),callback:function ($$v) {_vm.show_dialog_box=$$v},expression:"show_dialog_box"}},[_c('v-card',{staticClass:"pa-3 pop_bg"},[_c('v-card-title',[_c('span',{staticClass:"text-h6 primary--text"},[_vm._v("Purchase Details")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-container',{staticClass:"mt-5"},[_c('v-layout',{staticClass:"mb-5",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs5":"","sm3":"","md1":""}},[_c('h4',{staticClass:"font-weight-light mt-2 black--text"},[_vm._v("Invoice No:")])]),_c('v-flex',{staticClass:"mt-2",attrs:{"xs6":"","sm8":"","md2":""}},[_c('h3',{staticClass:"font-weight-medium black--text"},[_vm._v(" "+_vm._s(_vm.purchase.invoiceNumber)+" ")])]),_c('v-flex',{staticClass:"ms-md-16",attrs:{"xs5":"","sm3":"","md1":""}},[_c('h4',{staticClass:"font-weight-light mt-2 black--text"},[_vm._v(" Purchase Date: ")])]),_c('v-flex',{staticClass:"ms-md-1 mt-2",attrs:{"xs6":"","sm8":"","md2":""}},[_c('h3',{staticClass:"font-weight-medium black--text"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(_vm.purchase.purchaseDate)),"MMM DD YYYY"))+" ")])]),_c('v-flex',{staticClass:"ms-md-10",attrs:{"xs5":"","sm3":"","md1":""}},[_c('h4',{staticClass:"font-weight-light mt-2 black--text"},[_vm._v("Total Price:")])]),_c('v-flex',{staticClass:"mt-2 black--text",attrs:{"xs6":"","sm8":"","md3":""}},[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.purchase.purchasePrice.toFixed(3))+" SR ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.purchase.purchaseDetails,"items-per-page":5,"server-items-length":_vm.totalItemsCount,"footer-props":{
              'items-per-page-options': [25, 50, 100, 500],
            }},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [(item.ingredient.quantityUnit == 'PIECE')?_c('span',[_vm._v(" "+_vm._s(item.quantity.toFixed(0))+" "+_vm._s(_vm.itemQuantityUnit(item))+" ")]):_c('span',[_vm._v(" "+_vm._s((item.quantity / 1000).toFixed(3))+" "+_vm._s(_vm.itemQuantityUnit(item))+" ")])]}},{key:"item.totalPrice",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPrice.toFixed(3))+" SR ")]}},{key:"item.unitPrice",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.unitPrice.toFixed(3))+" SR ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }