<template>
  <v-container class="mt-n6 pa-0">
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="text-capitalize font-weight-bold"
          color="secondary"
        >
          <v-icon small class="me-1">mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Add Dietitian</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8"
          @submit="createDietitian()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">First Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="First Name"
                    v-model="firstnameEn"
                    :rules="[requiredValidator('First Name')]"
                    solo
                    dense
                    @keydown="isSpecialCharacter($event)"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Last Name</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Last Name"
                    v-model="lastnameEn"
                    :rules="[requiredValidator('Last Name ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Phone</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    v-model="phone"
                    :counter="10"
                    :error-messages="errors"
                    placeholder="Phone"
                    required
                    solo
                    dense
                    @keypress="isNumber"
                    type="number"
                    class="inputPhone"
                    @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 10),
                      maxLengthValidator('phone', 10),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout class="mt-6" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Password</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Password"
                    v-model="password"
                    :append-icon="
                      show1 ? 'mdi-eye black--text' : 'mdi-eye-off black--text'
                    "
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    @click:append="show1 = !show1"
                    :rules="[
                      requiredValidator('password '),
                      minLengthValidator('password', 8)
                    ]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Fee</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Fee"
                    v-model="fee"
                    suffix="SR"
                    @keypress="isFloatNumber"
                    type="number"
                    min="0"
                    max="999999"
                    :rules="[requiredValidator('Fee ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Designation</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Designation"
                    v-model="designation"
                    :rules="[requiredValidator('Designation ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Certification</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Certification"
                    v-model="certification"
                    :rules="[requiredValidator('Certification ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Referral Percent</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Referral Percent"
                    v-model="percentage"
                    type="number"
                    min="0"
                    append-icon="mdi-percent"
                    :rules="[requiredValidator('referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">
                    Max. Referral Amount(SR)
                  </h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Max. Referral Amount"
                    v-model="maxReferralAmount"
                    type="number"
                    min="0"
                    :rules="[requiredValidator('max. referral percentage ')]"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
              <!-- <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Referred By</h4>
                </v-flex>
                <v-flex xs12 sm8 md4>
                  <v-text-field
                    placeholder="Referred By"
                    v-model="referredBy"
                    solo
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
              <v-layout wrap>
                <v-flex xs12 sm3 md2>
                  <h5 class="text-body-2 black--text">Image</h5>
                </v-flex>

                <v-flex xs12 sm7 class="mt-3 mt-sm-0">
                  <croppa
                    class="ml-2 mt-2"
                    v-model="myCroppa"
                    :width="170"
                    :height="120"
                    canvas-color="black"
                    :placeholder="'Choose an image'"
                    :placeholder-font-size="16"
                    placeholder-color="yellow"
                    :accept="'image/*'"
                    :file-size-limit="0"
                    :quality="5"
                    :zoom-speed="3"
                    :disabled="false"
                    :disable-drag-and-drop="false"
                    :disable-click-to-choose="false"
                    :disable-drag-to-move="false"
                    :disable-scroll-to-zoom="false"
                    :disable-rotation="false"
                    :prevent-white-space="false"
                    :reverse-scroll-to-zoom="false"
                    :show-remove-button="true"
                    :remove-button-color="'red'"
                    :remove-button-size="10"
                  ></croppa>

                  <v-layout>
                    <span style="color: red">
                      ****Use mouse scroll wheel to crop image****
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm4 md2>
                  <h4 class="black--text text-body-2">Description</h4>
                </v-flex>
                <v-flex xs12 sm8 md4 class="mt-5 mt-md-0">
                  <v-textarea
                    solo
                    name="input-7-4"
                    placeholder="Description"
                    value=""
                    v-model="descriptionEn"
                    :rules="[requiredValidator('description ')]"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm8 md4 offset-sm-4 offset-md-0 class="ms-md-8">
                  <v-textarea
                    solo
                    name="input-7-4"
                    value=""
                    label="Description in Arabic"
                    v-model="descriptionAr"
                    :rules="[requiredValidator('description in arabic')]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs6 sm4 md2>
                  <h4 class="black--text text-body-2">Currently Active</h4>
                </v-flex>
                <v-flex xs6 sm6 class="mt-n4">
                  <v-radio-group row v-model="isActive">
                    <v-radio
                      label="Yes"
                      color="secondary"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      label="No"
                      color="secondary"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md2 class="mt-4">
                  <h4 class="black--text text-body-2">Available Days</h4>
                </v-flex>
                <v-flex sm8 md10>
                  <div>
                    <v-layout wrap>
                      <v-flex
                        xs6
                        sm4
                        md4
                        lg3
                        v-for="(day, index) in listOfDays"
                        :key="index"
                      >
                        <v-checkbox
                          :label="day"
                          v-model="availableDays"
                          :value="day"
                          color="secondary"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-n6 mt-sm-0 mb-sm-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              class=" text-capitalize"
              :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
              :loading="btnloading"
              @click="createDietitian"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          :color="snackbarColor"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator
} from "../../core/methods/validations";

export default {
  data() {
    return {
      show1: false,
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      requiredValidator: requiredValidator,
      doesPhoneExist: false,
      dialog: false,
      addedArea: [],
      firstnameEn: "",
      lastnameEn: "",
      phone: "",
      password: "",
      descriptionEn: "",
      descriptionAr: "",
      fee: "",
      image: null,
      percentage: 0,
      maxReferralAmount: 0,
      // croppa: "",
      myCroppa: null,
      availableDays: [],
      designation: "",
      certification: "",
      // referredBy: "",
      btnloading: false,
      snackbar: false,
      snackbarColor: "",
      text: "",
      valid: true,
      isActive: true,
      errors: [],
      listOfDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ]
    };
  },
  props: {
    dietitian: {
      required: true
    }
  },
  watch:{
    firstnameEn(newValue) {
      this.firstnameEn = newValue.replace(/\W/g, "");
    },
  },
  methods: {

    isSpecialCharacter(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    isFloatNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createDietitian() {
      this.myCroppa.generateBlob(blob => {
        this.image = blob;
        this.btnloading = true;

        this.$apollo
          .mutate({
            mutation: require("../api/createDietitian.graphql"),
            variables: {
              firstnameEn: this.firstnameEn,
              lastnameEn: this.lastnameEn,
              phone: this.phone,
              password: this.password,
              isActive: this.isActive,
              descriptionEn: this.descriptionEn,
              descriptionAr: this.descriptionAr,
              fee: parseFloat(this.fee),
              availableDays: this.availableDays,
              designation: this.designation,
              certification: this.certification,
              image: this.image,

              percentage: parseFloat(this.percentage),
              maxReferralAmount: parseFloat(this.maxReferralAmount)
            },
            update: () => {
              try {
                this.$root.$emit("updateDietitian");
                this.text = "Dietitian Added";
                this.snackbarColor = "success";
                this.snackbar = true;
              } catch (e) {
                console.log(e);
                console.log("Something bad happend");
              }
              this.$refs.form.reset();
              this.btnloading = false;
            }
          })
          .then(() => {
            this.dialog = false;
          })
          .catch(error => {
            if (error.graphQLErrors != null) {
              if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
                this.doesPhoneExist = true;
              }
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
              this.btnloading = false;
            }
            this.btnloading = false;
            console.log(error);
          });
      });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: black !important;
}
</style>
