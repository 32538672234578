<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title>
          <span class="text-h6 primary--text">Update Appointment Time</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="updateDietitianAppointment()"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 md2>
                  <h5 class="black--text text-body-2">Customer Name</h5>
                </v-flex>
                <v-flex xs8 md3 class="mt-5 mt-md-0 text-body-2 ms-5">
                  <span v-if="dietitianappointment.customer != null">
                    {{ dietitianappointment.customer.user.firstnameEn }}
                    {{ dietitianappointment.customer.user.lastnameEn }}</span
                  >
                </v-flex>
                <v-flex xs12 md2 offset-md-1>
                  <h5 class="black--text text-body-2">Phone</h5>
                </v-flex>
                <v-flex xs8 md3 class="mt-5 mt-md-0 text-body-2">
                  <span v-if="dietitianappointment.customer != null">
                    {{ dietitianappointment.customer.user.phone }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap class="mt-5">
                <v-flex xs12 md2>
                  <h5 class="black--text text-body-2">Date</h5>
                </v-flex>
                <v-flex xs8 md3 class="mt-5 mt-md-0 ms-5">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate"
                        readonly
                        label="Date"
                        :rules="[requiredValidator('date')]"
                        solo
                        append-icon="mdi-calendar-month-outline black--text"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                        dense
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      color="secondary"
                      v-model="date"
                      @change="menu = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md2 offset-md-1>
                  <h5 class="black--text text-body-2">Time</h5>
                </v-flex>
                <v-flex xs8 md3 class="mt-5 mt-md-0">
                  <v-menu
                    ref="menu"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        append-icon="mdi-clock-time-four-outline black--text"
                        readonly
                        label="Time"
                        :rules="[requiredValidator('time')]"
                        dense
                        solo
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      color="secondary"
                      v-if="menu1"
                      v-model="time"
                      full-width
                      @click:minute="$refs.menu.save(time)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-n5">
            <v-spacer></v-spacer>
            <v-btn
              color="solid"
              class="text_white--text text-capitalize"
              width="120"
              :loading="btnloading"
              @click="updateDietitianAppointment"
              >Update</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-snackbar
          v-model="snackbar"
          timeout="3000"
          right
          top
          color="success"
          >{{ text }}</v-snackbar
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requiredValidator } from "@/core/methods/validations";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  data() {
    return {
      date: "",
      requiredValidator: requiredValidator,
      dialog: false,
      time: "",
      btnloading: false,
      menu: "",
      menu1: "",
      snackbar: false,
      text: ""
    };
  },
  created() {
    this.time = this.dietitianappointment.appointmentTime;
    if (this.dietitianappointment.appointmentDate) {
      this.date = moment(
        new Date(parseInt(this.dietitianappointment.appointmentDate))
      ).format("YYYY-MM-DD");
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  },
  props: {
    availableDays:{ type:Array },
    dietitianappointment: { required: true }
  },
  methods: {
    allowedDates(val) {
      //allow only days which dietitian is available
      let date = moment(val,'YYYY-MM-DD')
      let weekDayAvailable = this.availableDays.map(day=> moment(day,'dddd').weekday())
      if(weekDayAvailable.includes(date.day())){
        return true
      }
    },
    updateDietitianAppointment() {
      this.btnloading = true;

      this.$apollo
        .mutate({
          mutation: require("../../api/updateDietitianAppointment.graphql"),
          variables: {
            id: this.dietitianappointment._id,
            appointmentTime: this.time,
            appointmentDate: this.date
          }
        })
        .then(() => {
          this.$root.$emit("updateAppointment");
          this.$root.$emit("updateDietitianAppointment");
          this.close();
          this.btnloading = false;
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
