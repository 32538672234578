<template>
  <v-row class="mt-4">
    <v-col cols="12" md="10" class="mx-auto">
      <v-row justify="center">
        <vc-date-picker
          :min-date="minDate"
          :max-date="maxDate"
          v-model="range"
          :disabled-dates="disabledDates"
          is-range
        />
      </v-row>
      <v-row justify="end" class="mt-6 mb-6">
        <v-btn
          :width="$vuetify.breakpoint.smAndUp ? 120 : ''"
          class="black--text me-3 text-capitalize"
          color="secondary"
          @click="saveDate()"
          :loading="btnloading"
          :disabled="this.range.end === null"
        >
          <v-icon left>mdi-plus</v-icon>
          Freeze
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import VCalendar from "v-calendar";
import moment from "moment";
import { Utils } from "../../../utils/utils";
import { requiredValidator } from "@/core/methods/validations";
Vue.use(VCalendar, { componentPrefix: "vc" }); // Use <vc-calendar /> instead of <v-calendar />
export default Vue.extend({
  props: {
    pausedDates: {
      require: true
    },
    subscribedPackage: {
      require: true
    },
    closeDialog: {
      type: Function,
      require: true
    }
  },
  components: {},
  data() {
    return {
      requiredValidator,
      valid: false,
      btnloading: false,

      //form data
      range: {
        start: null,
        end: null
      },
      //for v-calender
      disabledDates: [],
      minDate: this.convertToSystemTz(this.subscribedPackage.start_date),
      maxDate: this.convertToSystemTz(this.subscribedPackage.end_date)
    };
  },
  created() {
    this.setUnavailableDates();
    this.setDisabledDates(this.pausedDates);
  },

  watch: {
    pausedDates: {
      handler(newVal) {
        this.setUnavailableDates();
        this.setDisabledDates(newVal);
      },
      deep: true
    },
    subscribedPackage: {
      handler() {
        this.maxDate = this.convertToSystemTz(this.subscribedPackage.end_date);
        this.minDate = this.convertToSystemTz(
          this.subscribedPackage.start_date
        );
      },
      deep: true
    }
  },
  methods: {
    resetForm() {
      this.range = { start: null, end: null };
      this.btnloading = false;
      this.closeDialog();
    },

    convertToSystemTz(date) {
      return new Date(
        moment(new Date(parseInt(date)))
          .add(3, "h")
          .toDate()
      );
    },
    setDisabledDates(pausedDates) {
      pausedDates.forEach(element => {
        let paused = {
          start: new Date(
            moment(element.date)
              .add(3, "h")
              .toDate()
          ),
          end: new Date(
            moment(element.date)
              .add(3, "h")
              .toDate()
          )
        };
        this.disabledDates.push(paused);
      });
    },
    isPackageNotAvailableOnThisDay(date, availabelWeekDaysInNumbers) {
      let day = date.day();
      let available = availabelWeekDaysInNumbers.filter(x => x == day);
      return available.length > 0 ? false : true;
    },

    getDaysBetweenDates(startDate, endDate) {
      // generating array of dates between selected range
      let arrayOfPausedDates = [];
      for (
        var m = moment(startDate);
        m.isSameOrBefore(endDate);
        m.add(1, "days")
      ) {
        arrayOfPausedDates.push(m.format("YYYY-MM-DD"));
      }
      return arrayOfPausedDates;
    },
    setUnavailableDates() {
      let fromDate = new Date(parseInt(this.subscribedPackage.start_date));
      let toDate = new Date(parseInt(this.subscribedPackage.end_date));
      let momentFromDate = moment(fromDate)
        .add(3, "h")
        .startOf("day");
      let momentToDate = moment(toDate)
        .add(3, "h")
        .endOf("day");
      var dateArray = new Array();
      let availableWeekDays = this.subscribedPackage.package.availableChoices[0]
        .availableDays;
      let availabelWeekDays = Utils.getAvailabelWeekDaysInNumbers(
        availableWeekDays
      );

      while (momentFromDate <= momentToDate) {
        if (
          this.isPackageNotAvailableOnThisDay(momentFromDate, availabelWeekDays)
        ) {
          dateArray.push(momentFromDate.toDate());
        }
        momentFromDate.add(1, "days");
      }

      this.disabledDates = [];

      dateArray.forEach(element => {
        let paused = {
          start: new Date(
            moment(element)
              .add(3, "h")
              .toDate()
          ),
          end: new Date(
            moment(element)
              .add(3, "h")
              .toDate()
          )
        };
        this.disabledDates.push(paused);
      });
    },
    saveDate() {
      this.btnloading = true;
      var dateList = this.getDaysBetweenDates(this.range.start, this.range.end);
      this.$apollo
        .mutate({
          mutation: require("../../api/freezeCustomerMenu.graphql"),
          variables: {
            dates: dateList,
            customerId: this.$route.query.customerId,
            subscribed_package_id: this.subscribedPackage._id
          }
        })
        .then(data => {
          this.$emit(
            "updatePausedDates",
            data.data.freezeCustomerMenu.subscribed_packages
          );
          this.resetForm();
          this.$root.$emit("updateGetCustomerById");
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.resetForm();
        });
    }
  }
});
</script>
